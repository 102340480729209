import * as Styled from './PunchupQuantityCard.styles';

import Counter from 'features/common/components/Counter';
import { QuantityCardProps } from './types';
import { Table } from 'features/common/components/Table';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const PunchupQuantityCard = ({ recipientId, ticketTypes }: QuantityCardProps) => {
	const { t } = useTranslation();
	const { setValue, watch } = useFormContext();

	useEffect(() => {
		ticketTypes.forEach(({ type, ticketTypeId, initialPrice }, i) => {
			const name = `recipients[${recipientId}].ticketTypes[${i}]`;
			setValue(name, { type, quantity: 0, initialPrice, ticket_type_id: ticketTypeId });
		});
	}, []);

	return (
		<Styled.Card>
			<Table>
				<thead>
					<Styled.HeaderRow gridColumns="3fr 1fr">
						<Table.Cell key="ticketType" width="100%" minWidth={100}>
							<Styled.TicketTypeTitle>{t('events-ticket-type')}</Styled.TicketTypeTitle>
						</Table.Cell>
						<Table.Cell key="quantity" minWidth={100} textAlign="center">
							<Styled.QuantityText>{t('ticketDetails-quantity')}</Styled.QuantityText>
						</Table.Cell>
					</Styled.HeaderRow>
				</thead>

				<tbody>
					{ticketTypes.map(({ type, sold, amount }, i) => {
						const name = `recipients[${recipientId}].ticketTypes[${i}]`;
						const quantity = watch(`${name}.quantity`) || 0;

						const setQuantity = (newValue: number) => {
							if (newValue + sold > amount) return;
							newValue >= 0 && setValue(`${name}.quantity`, newValue);
						};

						return (
							<Styled.DataRow gridColumns="3fr 1fr" key={recipientId + i}>
								<Table.Cell key="ticketType" width="100%" minWidth={100}>
									<Styled.TicketTypeText>{type}</Styled.TicketTypeText>
								</Table.Cell>
								<Table.Cell key="quantity" minWidth={100} textAlign="center" padding="0">
									<Counter
										handleChange={setQuantity}
										handleMinus={() => setQuantity(quantity - 1)}
										handlePlus={() => setQuantity(quantity + 1)}
										max={amount}
										min={0}
										quantity={quantity || 0}
									/>
								</Table.Cell>
							</Styled.DataRow>
						);
					})}
				</tbody>
			</Table>
		</Styled.Card>
	);
};
