import api from 'services/api';
import { decamelizeKeys } from 'humps';
import { keepUpperCase } from 'utils/utils';

export const createTicket = (body: any) =>
	api.post('/ticket_types', [
		decamelizeKeys(body, {
			process: keepUpperCase,
		}),
	]);
