import { RootState } from 'store';
import { getData } from 'services/api';
import { handleDownload } from 'utils/handleLink';
import { initialState } from 'store/slices/OrdersPageSlice';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export const useOrdersPage = () => {
	const { selectedPeriod, selectedEvents, eventCount } = useSelector((state: RootState) => state.ordersPage);

	const [searchText, setSearchText] = useState('');

	const url = new URL(window.location.href);
	const searchParams = new URLSearchParams(url.search);
	const eventId = searchParams.get('event_id');

	const eventFilterApplied = selectedEvents.applied['-1'] ? eventId : Object.keys(selectedEvents.applied).join(',');

	const isNotFiltered =
		selectedPeriod.applied === initialState.selectedPeriod.applied &&
		selectedEvents.applied === initialState.selectedEvents.applied;

	const downloadOrdersQuery = useMutation(async () => {
		try {
			const params = new URLSearchParams();
			params.set('per_page', '-1');
			params.set('date', selectedPeriod.applied);
			params.set('search', searchText);
			if (eventFilterApplied) params.set('event_ids', eventFilterApplied);
			const res = await getData('/orders/csv?' + params.toString());
			handleDownload(res, 'orders.csv');
		} catch {
			toastError('ordersPage-csv-error');
		}
	});

	return {
		downloadOrdersQuery,
		selectedPeriod,
		selectedEvents,
		eventCount,
		searchText,
		setSearchText,
		eventFilterApplied,
		isNotFiltered,
	};
};
