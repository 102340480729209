import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Title = styled(Text)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
	}
`;

export const CustomFlexContainer = styled.div`
	padding: 40px 3% 40px 6%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px 3%;
		flex-direction: column;
		gap: 20px;
	}
`;

export const Buttons = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const CsvButton = styled.button`
	border: ${({ theme }) => `1px solid ${theme.colors.green}`};
	color: ${({ theme }) => theme.colors.green};
	font-family: ${({ theme }) => theme.fonts.primary};
	text-decoration: auto;
	background: transparent;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	height: 38px;
	width: 150px;
	display: flex;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	align-items: center;
	justify-content: center;
`;
