import { ContainerProps } from './types';
import { Icon } from 'features/common/components/Icon';
import { StdText } from 'features/common/components/Text/Text.styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
	width: 100%;
	padding-bottom: 20px;

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
	}
`;

export const Padding = styled.div`
	padding-left: 85px;
	padding-right: 25px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 15px;
	}
`;

export const Container = styled.div<ContainerProps>`
	background-color: ${({ theme }) => theme.colors.grey};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	padding: 32px;
	width: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
		flex-direction: ${({ columnOnMobile }) => (columnOnMobile ? 'column' : 'row')};
	}
`;

export const Text = styled(StdText)`
	max-width: 320px;
`;

export const BoldText = styled(Text)`
	font-size: 20px;
	font-weight: 700;
	text-align: start;
`;

export const RotatedIcon = styled(Icon)`
	transform: rotate(270deg);
	height: 20px;
	width: 20px;
`;

export const ViewButton = styled.button`
	background-color: ${({ theme }) => theme.colors.grey};
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
`;
