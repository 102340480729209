import { Icon } from '../../Icon/Icon';
import styled from 'styled-components';

export const Label = styled.label`
	font-size: 14px;
	font-weight: 500;
	color: #333333;
	margin-bottom: 8px;
	display: block;
`;

export const InputContainer = styled.div<{ hasError?: boolean }>`
	position: relative;
	width: 100%;

	.punchup-datepicker {
		box-sizing: border-box;
		width: 100%;
		padding: 16px 16px;
		height: 56px;
		border: 1px solid ${({ hasError }) => (hasError ? '#FF0000' : '#E1E1E1')};
		border-radius: 8px;
		font-size: 14px;
		color: #333333;
		background: #ffffff;

		&:focus {
			outline: none;
			border: 2px solid #000000;
		}

		&::placeholder {
			color: #757575;
		}
	}

	/* Estilos para el calendario */
	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker {
		border: 1px solid #e1e1e1;
		border-radius: 8px;
		font-family: inherit;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	}

	.react-datepicker__header {
		background: #ffffff;
		border-bottom: 1px solid #e1e1e1;
	}

	.react-datepicker__day--selected {
		background-color: #e86339;
	}

	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item--selected {
		background-color: #e86339;
	}
`;

export const CalendarIcon = styled(Icon)`
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
	color: #757575;
	pointer-events: none;
	font-size: 20px;
`;

export const Error = styled.span`
	color: #ff0000;
	font-size: 12px;
	margin-top: 4px;
`;

export const OptionalSpan = styled.span`
	color: #757575;
	font-weight: 400;
	font-size: 12px;
	margin-left: 4px;
`;
