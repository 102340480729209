import { EVENT_MODE_NORMAL, EVENT_MODE_PUNCHUP, EVENT_MODE_REGISTRATION } from 'utils/constants';
import { EventModeProp, ImageBackType, NewImageType } from './types';
import { defaultValues, getFormDetails } from './utils';
import { generatePath, useHistory } from 'react-router-dom';

import { EventSummary } from './EventSummary';
import { Form } from 'features/common/components/Form';
import { ROUTES } from 'features/routes';
import { createEvent } from './services';
import { sendGAEvent } from 'utils/utils';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { uploadImage } from 'services/UploadImage/uploadImage';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const CreateEventForm = ({ eventMode }: EventModeProp) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { CREATE_TICKET_PATH } = ROUTES;

	const formMethods = useForm({
		defaultValues,
		resolver: getFormDetails(eventMode),
		mode: 'onChange',
		values: {
			...defaultValues,
			mode: eventMode === EVENT_MODE_PUNCHUP ? EVENT_MODE_NORMAL : eventMode,
		},
	});

	useEffect(() => {
		formMethods.clearErrors();
	}, [eventMode]);

	const { mutate: mutateCreateEvent } = useMutation(createEvent, {
		onSuccess: ({ data: { ID } }) => {
			window.parent.postMessage({ action: 'event-created', eventId: ID }, '*');
			sendGAEvent({ category: 'Event Creation', action: 'Create event success' });

			switch (eventMode) {
				case EVENT_MODE_NORMAL:
					push(generatePath(CREATE_TICKET_PATH, { eventId: ID }) + '#1');
					break;
				case EVENT_MODE_REGISTRATION:
					push(generatePath(ROUTES.CREATE_TICKET_PATH, { eventId: ID }) + '#19');
					break;
			}
		},
		onError: () => {}, // TODO: do something
	});

	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onSuccess: ({ data }) => {
			const newValues: NewImageType[] = [
				...formMethods.getValues('new_images'),
				{
					image: data.image_url,
					position: data.index + 1,
					main_image: !data.index,
				},
			];
			formMethods.setValue('new_images', newValues);
		},
		onError: () => toastError(t('error-uploading-image')),
	});

	const submitForm = (currentValues: { images: ImageBackType[]; name: string }) => {
		Promise.all(
			currentValues.images.map((image, index) =>
				mutateUploadImage({
					image,
					prefix: currentValues.name,
					index,
					type: 'event',
				})
			)
		).then(() => {
			const currentValuesAfter = formMethods.getValues();
			mutateCreateEvent({ values: currentValuesAfter });
		});
	};

	return (
		<Form formMethods={formMethods} onSubmit={submitForm} width="100%">
			<EventSummary eventMode={eventMode} />
		</Form>
	);
};
