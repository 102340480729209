import * as S from './Table.styles';

import { LoadMore } from './LoadMore';
import { TableProps } from './types';

const Table = ({ children }: TableProps) => <S.HTMLTable>{children}</S.HTMLTable>;
Table.TableContainer = S.TableContainer;
Table.HeaderRow = S.HeaderRow;
Table.DataRow = S.DataRow;
Table.Cell = S.Cell;
Table.ColumnHeader = S.ColumnHeader;
Table.Button = S.CustomButton;
Table.LoadMore = LoadMore;

export { Table };
