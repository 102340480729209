import {
	CardContainer,
	Column,
	CreatePromoCode,
	Discount,
	DiscountContainer,
	DownCircle,
	UpCircle,
} from './PromoCodeCard.styles';
import { NEW_KEY, PromoCodeAddCardProps } from './types';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Icon } from '../Icon';
import { IdParamsType } from 'utils/types';
import { L } from '../Icon/utils';
import { ROUTES } from 'features/routes';
import { useAppSelector } from 'store/hooks';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

const PromoCodeAddCard = ({ fromDashboard }: PromoCodeAddCardProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const isLargeScreen = useResponsiveScreen();
	const { id: eventId } = useParams<IdParamsType>();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const onClickAddNew = () => {
		if (isPunchup) {
			push(generatePath(ROUTES.EVENT_PROMO_CODES_NEW_FORM_PATH, { eventId }));
		} else {
			push(ROUTES.PROMO_CODES_NEW_PATH);
		}
	};

	return (
		<CardContainer className="new" onClick={onClickAddNew} isPunchup={isPunchup}>
			<UpCircle className="new" isPunchup={isPunchup} />
			<DownCircle className="new" isPunchup={isPunchup} />
			<DiscountContainer className="new hovered" type={NEW_KEY} isPunchup={isPunchup}>
				<Discount type={NEW_KEY} isPunchup={isPunchup}>
					<Icon
						iconName="plus"
						size={isLargeScreen ? L : undefined}
						color={isPunchup ? 'brightness(0)' : ''}
						cursor="pointer"
					/>
				</Discount>
			</DiscountContainer>
			<Column className="new hovered">
				<CreatePromoCode isPunchup={isPunchup}>
					{t(fromDashboard ? 'promoCode-addPromoCode' : 'promoCode-createPromoCode')}
				</CreatePromoCode>
			</Column>
		</CardContainer>
	);
};

export { PromoCodeAddCard };
