import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const MainWrapper = styled.div`
	height: 156px;
	width: 100%;
	display: flex;
	padding: 0px 20px 0px 6%;
	box-sizing: border-box;
	justify-content: space-between;
	flex-shrink: 0;
	background-color: ${({ theme }) => theme.colors.lightGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 15px 3%;
		height: 100px;
	}
`;

export const InfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 20px;
	z-index: 0;
	height: 100%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		left: 100px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: 26%;
	}
`;

export const Title = styled(UnstyledText)`
	font-weight: 700;
	font-size: 40px;
	line-height: 60px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		line-height: 20px;
	}
`;

export const Subtitle = styled(UnstyledText)`
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	margin: 0;
	margin-top: 8px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 24px;
	}
`;
