import { StyledMainLayoutProps } from './types';
import styled from 'styled-components';

export const Wrapper = styled.div`
	margin: 0;
	display: flex;
	align-items: stretch;
	min-height: -webkit-fill-available;
`;

export const MainPanel = styled.main<StyledMainLayoutProps>`
	position: relative;
	overflow: ${({ containsStickyEl }) => (containsStickyEl ? 'revert' : 'hidden')};
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: ${({ theme }) => theme.widths.hiddenSidebar};
	background-color: ${({ theme, color }) => color || theme.colors.blackGrey};
	color: ${({ theme }) => theme.colors.white};
	${({ isFullVh }) => isFullVh && 'height:100vh;'}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		position: absolute;
		top: 125px;
		min-height: calc(100% - 125px);
		margin-left: 0;
	}
`;

export const PunchupMainPanel = styled.main<StyledMainLayoutProps>`
	position: relative;
	overflow: ${({ containsStickyEl }) => (containsStickyEl ? 'revert' : 'hidden')};
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.whitePearl};
	color: ${({ theme }) => theme.colors.white};
	right: 0;
	left: 0;
	${({ isFullVh }) => isFullVh && 'height:100vh;'}
`;
