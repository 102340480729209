import { Form } from 'features/common/components/Form';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
	padding: 0 6% 6% 6%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
`;

export const StyledForm = styled(Form)`
	width: 100%;
	padding: 15px;
`;

export const TicketTitleContainer = styled.div`
	padding: 0 6% 15px;
`;

export const TicketTitle = styled(Text)`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 24px;
	color: ${({ theme }) => theme.colors.black};
`;

export const PunchupButtonContainer = styled.div`
	gap: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 636px;
	margin: 0 auto;
`;
