import { BoxProps, HighlightsCollectionProps } from './types';
import { FC, memo } from 'react';

import { HighlightsContainer } from './PunchupHighlightsCollection.styles';
import { PunchupHighlightCard } from './PunchupHighlightCard';

const PunchupHighlightsCollection = ({ boxCollection }: HighlightsCollectionProps) => (
	<HighlightsContainer>
		{boxCollection.map(({ ...props }: BoxProps) => (
			<PunchupHighlightCard key={props.property} {...props} />
		))}
	</HighlightsContainer>
);

const memorizedHighlightsCollection: FC<HighlightsCollectionProps> = memo(PunchupHighlightsCollection);

export { memorizedHighlightsCollection as PunchupHighlightsCollection };
