import * as Styled from '../AnalyticByEvent.styles';

import { useEffect, useState } from 'react';

import { IdParamsType } from 'utils/types';
import { InventoryTicketTypes } from 'hooks/useHighlights/types';
import { TicketTypesInventoryAcc } from '../../types';
import TicketsAnalyticsCard from 'features/common/components/TicketsAnalyticsCard/TicketsAnalyticsCard';
import { common } from 'theme';
import { getColor } from 'features/Dashboard/components/utils';
import { useHighlights } from 'hooks/useHighlights';
import { useParams } from 'react-router-dom';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

const TicketInventory = () => {
	const { t } = useTranslation();
	const isLg = useResponsiveScreen();
	const { id } = useParams<IdParamsType>();
	const { eventHighlights } = useHighlights(id);

	const [sold, setSold] = useState(0);
	const [available, setAvailable] = useState(0);

	useEffect(() => {
		if (eventHighlights) {
			const acc = eventHighlights.ticket_types_inventory.reduce(
				(acc, ticketTypeInventory) => ({
					sold: (acc.sold += ticketTypeInventory.sold),
					available: (acc.available += ticketTypeInventory.total_amount - ticketTypeInventory.sold),
				}),
				{ sold: 0, available: 0 } as TicketTypesInventoryAcc
			);
			setSold(acc.sold);
			setAvailable(acc.available);
		}
	}, [eventHighlights]);

	return (
		<Styled.Root data-cy="ticket-inv-graph">
			<Styled.Title>{t('tickets-inventory-title')}</Styled.Title>
			<Styled.ContainerTabsPieCharts>
				<TicketsAnalyticsCard sold={sold} available={available} color={common.colors.orange2} />
				{/* TODO fix responsive on tablet */}
				<Styled.SmallTicketsContainer>
					{!isLg && <Styled.Divider />}
					{eventHighlights?.ticket_types_inventory?.map((ticketType: InventoryTicketTypes, i: number) => (
						<TicketsAnalyticsCard
							sm
							key={ticketType.ticket_type_id}
							value={ticketType.name}
							sold={ticketType.sold}
							available={ticketType.total_amount - ticketType.sold}
							color={getColor(i)}
						/>
					))}
				</Styled.SmallTicketsContainer>
			</Styled.ContainerTabsPieCharts>
		</Styled.Root>
	);
};

export default TicketInventory;
