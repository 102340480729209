import { BackIcon, CloseIcon } from 'features/CreateEvent/CreateEvent.styles';
import { CenteredContainer, ScreenTitle } from '../Steps.styles';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';

import { DropzoneInput } from 'features/common/components/ImageInputs';
import { IdParamsType } from 'utils/types';
import { Page } from '../..';
import { ROUTES } from 'features/routes';
import { Text } from 'features/common/components/Text';
import { handleLink } from 'utils/handleLink';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TicketTemplateImage = () => {
	const { t } = useTranslation();
	const { hash } = useLocation();
	const { watch } = useFormContext();
	const { goBack, push } = useHistory();
	const { id } = useParams<IdParamsType>();

	const images = watch('images');

	useEffect(() => {
		if (images.length > 0 && hash === '#4') handleLink('#5');
	}, [images.length]);

	const handleClose = () => {
		push(generatePath(ROUTES.DASHBOARD_PATH, { id }));
	};

	return (
		<Page id="4">
			<CenteredContainer>
				<ScreenTitle>
					<BackIcon iconName="arrowLeft" onClick={goBack} />
					<Text data-cy="title">{t('ticketDetails-ticket-addImage')}</Text>
					<CloseIcon iconName="close" onClick={handleClose} />
				</ScreenTitle>

				<DropzoneInput maxPicturesAllowed={1} inputId="images" />
			</CenteredContainer>
		</Page>
	);
};
