import * as Styled from './ViewEmailCampaigns.styles';

import { CampaignInfo } from './components/CampaignInfo';
import { ROUTES } from 'features/routes';
import { UploadCohort } from './components/UploadCohort';
import { getEmailCampaigns } from '../services';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

export const ViewEmailCampaigns = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const numOfPages = useRef(0);

	const { data } = useInfiniteQuery({
		queryKey: ['email-campaigns'],
		queryFn: (pageParam) => getEmailCampaigns(pageParam, numOfPages),
		getNextPageParam: (_, pages) => pages.length < numOfPages.current && pages.length + 1,
	});
	return (
		<Styled.MainContainer>
			{isLargeScreen ? (
				<Styled.CenterContent>
					<Styled.ContentTitle>{t('marketing-viewEmailCampaigns-title')}</Styled.ContentTitle>
					<Styled.ButtonContainer>
						<Styled.CustomButton
							type="button"
							text={t('marketing-viewEmailCampaingns-create')}
							onClick={() => push(ROUTES.CREATE_CAMPAIGN_PATH)}
						/>
						<UploadCohort />
					</Styled.ButtonContainer>
					{data?.pages.map((page) =>
						page.map((campaignData) => <CampaignInfo key={campaignData?.id} campaignData={campaignData} />)
					)}
					{/* {hasNextPage && (
						<FlexContainer justifyContent="center">
							<Styled.LoadMoreButton handleClick={() => fetchNextPage()} isDisabled={!hasNextPage} />
						</FlexContainer>
					)} */}
				</Styled.CenterContent>
			) : (
				<Styled.ContentTitle>{t('marketing-viewEmailCampaigns-mobileOnly')}</Styled.ContentTitle>
			)}
		</Styled.MainContainer>
	);
};
