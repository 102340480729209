import { LoggedUser } from 'services/auth/types';
import { createSlice } from '@reduxjs/toolkit';

export type AuthState = {
	isAuthenticated: boolean;
	loggedUser: LoggedUser | undefined;
	isPunchup: boolean;
	loading: boolean;
};

const initialState = {
	isAuthenticated: false,
	loggedUser: undefined,
	isPunchup: false,
	loading: false,
} as AuthState;

const authSlice = createSlice({
	name: 'authState',
	initialState,
	reducers: {
		changeIsAuthenticated(state, action) {
			state.isAuthenticated = action.payload;
		},
		setIsPunchup(state, action) {
			state.isPunchup = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setLoggedUser(state, action) {
			state.loggedUser = action.payload;
		},
		setPartners(state, action) {
			if (state.loggedUser) state.loggedUser.Partners = action.payload;
		},
		setCurrentPartner(state, action) {
			if (state.loggedUser) state.loggedUser.currentPartner = action.payload;
		},
		logoutUser(state) {
			state.isAuthenticated = initialState.isAuthenticated;
			state.loggedUser = initialState.loggedUser;
		},
	},
});

export const {
	changeIsAuthenticated,
	setIsPunchup,
	setLoading,
	setLoggedUser,
	setPartners,
	logoutUser,
	setCurrentPartner,
} = authSlice.actions;
export default authSlice.reducer;
