import { DeleteEventType, EditEventProps } from './types';

import { ZERO_TIME } from 'utils/constants';
import api from 'services/api';
import { convertDateWithTimezoneToUTC } from 'utils/utils';

export const updateEvent = ({ values, id }: EditEventProps) => {
	const newDates = values.dates.map(({ start_date, end_date }) => ({
		start_date: convertDateWithTimezoneToUTC(start_date, values.timezone),
		end_date: convertDateWithTimezoneToUTC(end_date, values.timezone),
	}));

	const selling_time =
		values.selling_time === ZERO_TIME
			? null
			: convertDateWithTimezoneToUTC(new Date(values.selling_time ?? ''), values.timezone);

	const door_time = values.door_time ? convertDateWithTimezoneToUTC(new Date(values.door_time), values.timezone) : null;

	const custom_fees = values.custom_fees?.map(({ taxable, ...rest }) => ({ ...rest, taxable: taxable === '1' }));

	return api.put(`/events/${id}`, {
		name: values.name,
		event_days: newDates,
		street_address: values.location,
		description: values.description,
		gate_time: values.gate_time === '' ? null : Number(values.gate_time),
		selling_time,
		postal_code: values.postal_code,
		ticketing_mode: values.ticketing_mode,
		custom_fees,
		custom_email_message: values.custom_email_message,
		support_contact: [{ contact: values.support }],
		time_zone: values.timezone,
		venue: values.venue,
		quantity: parseInt(values.quantity),
		approval_required: values.approval_required,
		door_time,
	});
};

export const deleteEvent = ({ id }: DeleteEventType) => api.delete(`/events/${id}`);
