import { DropDownProps, OptionProps } from './types';
import styled, { css } from 'styled-components';

import { Text } from 'features/common/components/Text';

export const DropDownList = styled.ul<DropDownProps>`
	background-color: ${({ theme }) => theme.colors.blackGrey};
	border: ${({ theme }) => `0.5px solid ${theme.colors.white}`};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	left: ${({ secondaryStyle }) => (secondaryStyle ? '-20px' : '-16px')};
	margin: 0;
	padding: 0;
	position: relative;
	top: ${({ secondaryStyle }) => (secondaryStyle ? '-32px' : '12px')};
	width: ${({ secondaryStyle }) => (secondaryStyle ? '140px' : 'calc(100% + 30px)')};
	z-index: 2;

	${({ isPunchup }) =>
		isPunchup &&
		css`
			position: absolute;
			right: 0;
			top: 40px;
			width: fit-content;
			left: unset;
			background-color: ${({ theme }) => theme.colors.punchupBackground};
			border: ${({ theme }) => `0.5px solid ${theme.colors.black}`};
		`}
`;

export const ListItem = styled.li<{ isPunchup?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey3};
	color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.black : theme.colors.white)};
	gap: 10px;
	list-style: none;
	padding: 12px;
	cursor: pointer;

	&.last {
		border: none;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.lightGrey3};

		&:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:last-child {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
`;

export const Span = styled(Text)<OptionProps>`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-left: ${({ isChecked }) => (isChecked ? '' : '35px')};
`;
