import { Text } from 'features/common/components/Text';
import { InfoCard as UnstyledInfoCard } from '../InfoCard';
import styled from 'styled-components';

export const Container = styled.div`
	overflow-x: scroll;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const Title = styled(Text)`
	line-height: 27px;
	margin-left: 3%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
	}
`;

export const InfoCard = styled(UnstyledInfoCard)`
	height: 137px;
`;

export const Wrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 10px;
	}
`;
