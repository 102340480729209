import { Check } from 'lucide-react';
import styled from 'styled-components';

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`;

export const ModalContainer = styled.div`
	background: white;
	border-radius: 16px;
	padding: 32px;
	width: 90%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
`;

export const CheckIcon = styled.div`
	width: 48px;
	height: 48px;
	background: #e86339;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
`;

export const StyledCheck = styled(Check)`
	width: 24px;
	height: 24px;
	stroke-width: 3;
`;

export const Title = styled.h2`
	font-size: 20px;
	color: #333333;
	text-align: center;
	margin: 0;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	margin-top: 8px;

	button {
		padding: 12px;
		white-space: nowrap;
	}
`;
