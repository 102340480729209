import * as Styled from './PunchupTicketDetails.styles';

import { CreateTicketForm, ReqFieldsProps, TicketDetailsParams } from './types';
import {
	NEW_TICKET,
	createBody,
	defaultValues,
	fillForm,
	getTicketTypeCanGoLive,
	showReqFieldsModal,
	validationSchema,
} from './utils';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { LeftForm } from './components/LeftForm';
import { MainLayout } from 'layouts/MainLayout';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { RequiredFieldsModal } from 'features/Embed/components/RequiredFields/RequieredFieldsModal';
import { useForm } from 'react-hook-form';
import useGetEvent from 'features/common/hooks/useGetEvent';
import { useTicketTypeApi } from './hooks';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

const PunchupTicketDetails: React.FC = () => {
	const { goBack } = useHistory();
	const { t } = useTranslation();
	const { id: eventId } = useParams<TicketDetailsParams>();
	const location = useLocation();
	const { event } = useGetEvent();
	const { ticket, canGoLive, mutateUpdateTicket, mutateCreateTicket } = useTicketTypeApi();
	const [showReqFields, setShowReqFields] = useState(false);
	const [reqFieldsProps, setReqFieldsProps] = useState<ReqFieldsProps>();

	const isNewTicket = location.pathname.includes(NEW_TICKET);

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
	});

	useEffect(() => {
		if (event && ticket) {
			formMethods.reset(fillForm(ticket, event.timeZone));
		}
	}, [ticket, event, canGoLive]);

	const onSubmitTicketForm = async (currentValues: CreateTicketForm) => {
		if (event && canGoLive && showReqFieldsModal(event, canGoLive, currentValues.initialPrice || 0)) {
			setReqFieldsProps({
				eventGoLive: getTicketTypeCanGoLive(canGoLive, ticket?.ID),
				eventId: Number(eventId),
				type: 'ticket',
				closeModal: () => setShowReqFields(false),
			});

			setShowReqFields(true);
			return;
		}

		try {
			const newTicketBody = createBody(formMethods.getValues(), Number(eventId), event?.timeZone || '', ticket?.ID);
			if (isNewTicket) {
				mutateCreateTicket(newTicketBody);
			} else {
				mutateUpdateTicket(newTicketBody);
			}
		} catch (error) {
			throw new Error(`Error ${error}`);
		}
	};

	return (
		<MainLayout>
			<Styled.StyledForm formMethods={formMethods} onSubmit={onSubmitTicketForm}>
				<Styled.TicketTitleContainer>
					<Styled.TicketTitle>{isNewTicket ? 'Create New' : 'Edit'} Ticket Type</Styled.TicketTitle>
				</Styled.TicketTitleContainer>

				<Styled.ContentWrapper>
					<LeftForm />
				</Styled.ContentWrapper>

				<Styled.PunchupButtonContainer>
					<PunchupButton type="button" variant="secondary" onClick={goBack} text="Cancel" />
					<PunchupButton type="submit" variant="primary" text={isNewTicket ? 'Create' : 'Update'} />
				</Styled.PunchupButtonContainer>

				{showReqFields && reqFieldsProps && <RequiredFieldsModal {...reqFieldsProps} />}
			</Styled.StyledForm>
		</MainLayout>
	);
};

export { PunchupTicketDetails };
