import * as Styled from './Button.styles';

import { FC, useMemo } from 'react';

import { ButtonProps } from './types';
import { PRIMARY } from './constants';

const Button: FC<ButtonProps> = ({ text, category, type = 'button', refProp, ...rest }: ButtonProps) => {
	const ButtonComponent = useMemo(() => {
		const buttons = {
			primary: Styled.DefaultButton,
			secondary: Styled.SecondaryButton,
			tertiary: Styled.TertiaryButton,
			quaternary: Styled.QuaternaryButton,
			punchup_secondary: Styled.PunchupSecondaryButton,
			punchup_tertiary: Styled.PunchupTertiaryButton,
			punchup_quaternary: Styled.PunchupQuaternaryButton,
			outlined: Styled.OutlinedButton,
			underlined: Styled.UnderlinedButton,
		};

		return category ? buttons[category] : buttons[PRIMARY];
	}, [category]);

	return (
		<ButtonComponent type={type} ref={refProp} {...rest}>
			{text}
		</ButtonComponent>
	);
};

export { Button };
