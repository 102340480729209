import { Redirect, Route, Switch } from 'react-router-dom';

import AffiliateCreatePage from 'pages/AffiliateCreatePage';
import AffiliateViewPage from 'pages/AffiliatesPage';
import { AnalyticByEvent } from 'features/Analytics/components/AnalyticByEvent';
import AnalyticsPage from 'pages/AnalyticsPage';
import AttendeesListPage from 'pages/AttendeesListPage';
import BoxOfficePage from 'pages/BoxOfficePage';
import CompedTicketsPage from 'pages/CompedTickets';
import CreateEventPage from 'pages/CreateEventPage';
import CreateTicketPage from 'pages/CreateTicketPage';
import CustomFieldsPage from 'pages/CustomFieldsPage';
import CustomFieldsReorderPage from 'pages/CustomFieldsReorderPage';
import CustomFieldsResponsesPage from 'pages/CustomFieldsResponsesPage';
import DashboardPage from 'pages/DashboardPage';
import EditEventPage from 'pages/EditEventPage';
import EditImgContent from 'pages/EditImgContentPage';
import EditWebStyles from 'pages/EditWebsiteStyles';
import EmbedPage from 'pages/EmbedPage';
import EventPromoCodeFormPage from 'pages/EventPromoCodeFormPage';
import FanDatabasePage from 'pages/FanDatabasePage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import HomePage from 'pages/HomePage';
import LotteryPage from 'pages/LotteryPage';
import ManageEmailCampaignPage from 'pages/ManageEmailCampaignPage';
import MarketingPage from 'pages/MarketingPage';
import Orchestator from 'pages/Orchestator';
import OrdersPage from 'pages/OrdersPage';
import PromoCodesFormPage from 'pages/PromoCodesFormPage';
import PromoCodesPage from 'pages/PromoCodesPage';
import PromoCodesSectionPage from 'pages/PromoCodesSectionPage';
import { ROUTES } from 'features/routes';
import RefundTicketsSummaryPage from 'pages/RefundTicketsSummaryPage';
import RefundableTicketsPage from 'pages/RefundableTicketsPage';
import ScannerAccountPage from 'pages/ScannerAccountPage';
import SendTicketsPage from 'pages/SendFreeTicketsPage';
import SettingsPage from 'pages/SettingsPage';
import { SwitchTeam } from 'features/SwitchTeam';
import TermsOfServicePage from 'pages/TermsOfServicePage';
import TicketDetailsPage from 'pages/TicketDetailsPage';

export const UserRoutes = (): JSX.Element => (
	<Switch>
		<Redirect exact from={ROUTES.ROOT_PATH} to={ROUTES.HOME_PATH} />
		<Route exact path={ROUTES.HOME_PATH}>
			<HomePage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_PATH}>
			<DashboardPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_NEW_AFFILIATE}>
			<AffiliateCreatePage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_VIEW_AFFILIATES}>
			<AffiliateViewPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_PROMO_CODES_NEW_PATH}>
			<PromoCodesPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_PROMO_CODES_NEW_FORM_PATH}>
			<PromoCodesFormPage />
		</Route>
		<Route exact path={ROUTES.EDIT_EVENT_PATH}>
			<EditEventPage />
		</Route>
		<Route exact path={ROUTES.TICKET_DETAILS_PATH}>
			<TicketDetailsPage />
		</Route>
		<Route exact path={ROUTES.TICKET_NEW_PATH}>
			<TicketDetailsPage />
		</Route>
		<Route exact path={ROUTES.PROMO_CODES_DETAILS_PATH}>
			<PromoCodesPage />
		</Route>
		<Route exact path={ROUTES.PROMO_CODES_NEW_PATH}>
			<PromoCodesPage />
		</Route>
		<Route exact path={ROUTES.PROMO_CODES_PATH}>
			<PromoCodesSectionPage />
		</Route>
		<Route exact path={ROUTES.PROMO_CODES_NEW_FORM_PATH}>
			<PromoCodesFormPage />
		</Route>
		<Route exact path={ROUTES.EVENT_PROMO_CODES_NEW_FORM_PATH}>
			<EventPromoCodeFormPage />
		</Route>
		<Route exact path={ROUTES.EVENT_PROMO_CODES_EDIT_FORM_PATH}>
			<EventPromoCodeFormPage />
		</Route>
		<Route exact path={ROUTES.PROMO_CODES_EDIT_PATH}>
			<PromoCodesFormPage />
		</Route>
		<Route exact path={ROUTES.CREATE_EVENT_PATH}>
			<CreateEventPage />
		</Route>
		<Route exact path={ROUTES.ANALYTICS_PATH}>
			<AnalyticsPage />
		</Route>
		<Route exact path={ROUTES.ANALYTICS_BY_EVENT_PATH}>
			<AnalyticByEvent />
		</Route>
		<Route exact path={ROUTES.EMBED_PATH}>
			<EmbedPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_EDIT_WEBSITE_STYLES}>
			<EditWebStyles />
		</Route>
		<Route exact path={ROUTES.CREATE_TICKET_PATH}>
			<CreateTicketPage />
		</Route>
		<Route exact path={ROUTES.FAN_DATABASE_PATH}>
			<FanDatabasePage />
		</Route>
		<Route exact path={ROUTES.SETTINGS_PATH}>
			<SettingsPage />
		</Route>
		<Route exact path={ROUTES.SWITCH_TEAM_PATH}>
			<SwitchTeam />
		</Route>
		<Route exact path={ROUTES.REFUNDABLE_TICKETS_PATH}>
			<RefundableTicketsPage />
		</Route>
		<Route exact path={ROUTES.REFUND_TICKETS_SUMMARY_PATH}>
			<RefundTicketsSummaryPage />
		</Route>
		<Route exact path={ROUTES.ORDERS_PATH}>
			<OrdersPage />
		</Route>
		<Route exact path={ROUTES.ORDERS_BY_EVENT_PATH}>
			<OrdersPage />
		</Route>
		<Route path={ROUTES.USER_MGMT}>
			<Orchestator />
		</Route>
		<Route exact path={ROUTES.FORGOT_PASSWORD}>
			<ForgotPasswordPage />
		</Route>
		<Route exact path={ROUTES.COMPED_TICKETS}>
			<CompedTicketsPage />
		</Route>
		<Route exact path={ROUTES.SEND_TICKETS}>
			<SendTicketsPage />
		</Route>
		<Route exact path={ROUTES.TERMS_OF_SERVICE}>
			<TermsOfServicePage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_ADD_CUSTOM_FIELDS}>
			<CustomFieldsPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_EDIT_CONTENT}>
			<EditImgContent />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_CUSTOM_FIELDS_RESPONSES}>
			<CustomFieldsResponsesPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_EDIT_CUSTOM_FIELDS}>
			<CustomFieldsPage editMode />
		</Route>
		<Route exact path={ROUTES.MARKETING_PATH}>
			<MarketingPage />
		</Route>
		<Route exact path={ROUTES.CREATE_CAMPAIGN_PATH}>
			<ManageEmailCampaignPage />
		</Route>
		<Route exact path={ROUTES.EDIT_CAMPAIGN_PATH}>
			<ManageEmailCampaignPage />
		</Route>
		<Route exact path={ROUTES.BOX_OFFICE}>
			<BoxOfficePage />
		</Route>
		<Route exact path={ROUTES.SCANNER_ACCOUNT}>
			<ScannerAccountPage />
		</Route>
		<Route exact path={ROUTES.ATTENDEES}>
			<AttendeesListPage />
		</Route>
		<Route exact path={ROUTES.DASHBOARD_REORDER_CUSTOM_FIELDS}>
			<CustomFieldsReorderPage />
		</Route>
		<Route exact path={ROUTES.LOTTERY}>
			<LotteryPage />
		</Route>
		<Redirect to="/" />
	</Switch>
);
