import { InputContainerStyleProps } from '../types';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Error = styled(Text)`
	line-height: 16px;
	display: block;
	font-size: 12px;
	font-weight: 400;
	text-transform: none;
	max-width: fit-content;

	&.icon {
		padding-left: 48px;
		margin-bottom: 20px;
	}
`;

export const PunchupTextArea = styled.textarea`
	width: 100%;
	min-height: 180px;
	padding: 12px 16px;
	box-sizing: border-box;
	// background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	font-size: 14px;
	color: #666666;
	font-family: inherit;
	resize: vertical;

	&::placeholder {
		color: #757575;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		background-color: #f5f5f5;
		color: #757575;
	}
`;

export const PunchupInputContainer = styled.div<InputContainerStyleProps>`
	margin-bottom: 24px;
	width: 100%;

	input,
	textarea,
	select {
		width: 100%;
		font-family: inherit;
		font-size: 14px;
		border-radius: 8px;
		border: 1px solid #e0e0e0;
		background-color: #ffffff;

		&:focus {
			outline: none;
			border-color: #000000;
			border-width: 2px;
			background-color: #ffffff;
		}

		&:disabled {
			background: #f5f5f5;
			color: #757575;
		}
	}

	${({ hasError }) =>
		hasError &&
		`
		input, textarea, select {
			border-color: #d32f2f;
		}
	`}
`;
