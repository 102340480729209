import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Title = styled(Text)`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
	}
`;

export const Container = styled.div`
	width: 100%;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	box-sizing: border-box;
	align-items: center;
	padding: 15px;
`;

export const CustomFlexContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
`;

export const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const Buttons = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const CsvButton = styled.button`
	border: '1px solid #e86339';
	color: ${({ theme }) => theme.colors.grey20};
	font-family: ${({ theme }) => theme.fonts.primary};
	text-decoration: auto;
	background: transparent;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	height: 38px;
	width: 150px;
	display: flex;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	align-items: center;
	justify-content: center;

	&:hover {
		background: rgba(232, 99, 57, 0.05);
	}
`;
