import { ButtonContainer, Container, InputContainer, NextButton, SkipButton, StepTitle } from '../Steps.styles';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { EventIdParamsType } from 'utils/types';
import { Image } from './AddTickets.styles';
import { Page } from 'features/common/components/Carousel';
import { ROUTES } from 'features/routes';
import createEventTickets from 'assets/images/createEventTickets.svg';
import { handleLink } from 'utils/handleLink';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';

export const AddTickets = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { eventId }: EventIdParamsType = useParams();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const onSkip = () => {
		if (isPunchup) {
			push(generatePath(ROUTES.CREATE_EVENT_PATH, { id: eventId }));
		} else {
			handleLink('#9');
		}
	};

	return (
		<Page id="1">
			<Container>
				<InputContainer>
					<StepTitle element="h3">{t('createEvent-addTicketsStep')}</StepTitle>
					<Image src={createEventTickets} />
				</InputContainer>
				<ButtonContainer>
					<SkipButton type="button" onClick={onSkip}>
						{t('createEvent-addTicketsStep-skip')}
					</SkipButton>
					<NextButton type="button" onClick={() => handleLink('#2')}>
						{t('createEvent-addTicketsStep-add')}
					</NextButton>
				</ButtonContainer>
			</Container>
		</Page>
	);
};
