import { Button } from 'features/common/components/Button';
import { LocalFilter as UnstyledFilter } from 'features/common/components/Filters/LocalFilter';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
	padding: 40px 3% 40px 6%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0 12px;
		gap: 20px;
	}
`;

export const SendTicketsButton = styled(Button)`
	width: fit-content;
	padding: 8px 21px;
	height: 100%;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const Filter = styled(UnstyledFilter)``;
