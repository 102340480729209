import { Container, LessIcon, PlusIcon, StyledInput } from './Counter.styles';

import { CounterProps } from './types';
import { useState } from 'react';

const Counter = ({
	className,
	handleChange,
	handleMinus,
	handlePlus,
	isThemed,
	max,
	min = 0,
	quantity,
	incrementBy = 1,
}: CounterProps) => {
	const [counterQuantity, setCounterQuantity] = useState(quantity);

	const onIncrement = () => {
		if (counterQuantity < max) {
			const newQuantity = counterQuantity + incrementBy;
			setCounterQuantity(newQuantity);
			handlePlus(newQuantity);
		}
	};

	const onDecrement = () => {
		if (counterQuantity > min) {
			const newQuantity = counterQuantity - incrementBy;
			setCounterQuantity(newQuantity);
			handleMinus(newQuantity);
		}
	};

	const onChange = (value: number) => {
		if (value <= max && value >= min) {
			setCounterQuantity(value);
			handleChange(value);
		}
	};

	if (max === 0) {
		return <div>Sold out</div>;
	}

	return (
		<Container isThemed={isThemed}>
			<LessIcon onClick={onDecrement} />
			<StyledInput
				value={counterQuantity}
				onChange={(e) => onChange(Number(e.target.value))}
				type="number"
				min={min}
				max={max}
				className={className}
				isThemed={isThemed}
			/>
			<PlusIcon onClick={onIncrement} />
		</Container>
	);
};

export default Counter;
