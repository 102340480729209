import * as CONST from 'features/common/components/HighlightsCollection/constants';
import * as Styled from './AnalyticsSummary.styles';

import { Title, Wrapper } from 'features/Analytics/Analytics.styles';
import { useEffect, useState } from 'react';

import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { useHighlights } from 'hooks/useHighlights';
import { useTranslation } from 'react-i18next';

export const AnalyticsSummary = () => {
	const { t } = useTranslation();
	const { highlights } = useHighlights();
	const [boxCollection, setBoxCollection] = useState(CONST.ALL_EVENT_ANALYTICS);

	useEffect(() => {
		if (highlights) {
			const boxCollections: BoxProps[] = [];
			for (let i = 0; i < boxCollection.length; i++) {
				const box = boxCollection[i];
				delete box.onClick;
				if (box.property === CONST.TOTAL_ATTENDEES) {
					box.stat = highlights.total_attendees;
				} else if (box.property === CONST.SHOW_RATE) {
					box.stat = highlights.nums_of_scans / highlights.total_attendees || 0;
				} else if (box.property === CONST.TICKETS_SOLD) {
					box.stat = highlights.tickets_sold;
				}
				boxCollections.push(box);
			}
			setBoxCollection(boxCollections);
		}
	}, [highlights]);

	return (
		<Wrapper>
			<Title element="h2">{t('generalAnalytics-overall-summary')}</Title>
			<Styled.Container>
				<HighlightsCollection boxCollection={boxCollection} />
			</Styled.Container>
		</Wrapper>
	);
};
