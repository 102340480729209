import { FC, useEffect } from 'react';
import { PunchupInputContainer, PunchupLabel, PunchupInput as StyledPunchupInput } from '../Form.styles';

import { FormInputProps } from '../types';
import { useFormContext } from 'react-hook-form';

const PunchupInput: FC<FormInputProps> = ({
	id,
	name,
	label,
	type = 'text',
	promoCodeForm,
	disabled,
	defaultValue,
	style,
	isFromRequiredFields,
	errorProp,
	...rest
}: FormInputProps) => {
	const {
		register,
		setValue,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	useEffect(() => {
		const timer = setTimeout(() => {
			if (defaultValue) {
				setValue(name, defaultValue, {
					shouldValidate: true,
					shouldDirty: true,
				});
			}
		}, 0);

		return () => clearTimeout(timer);
	}, [defaultValue, name, setValue]);

	const inputStyles = {
		backgroundColor: `${disabled ? '#f5f5f5' : '#F8F8F8'} !important`,
		borderRadius: '8px',
		padding: '16px',
		width: '100%',
		fontSize: '16px',
		color: disabled ? '#999999' : '#666666',
		cursor: disabled ? 'not-allowed' : 'text',
		...style,
	};

	return (
		<PunchupInputContainer hasError={Boolean(error) || Boolean(errorProp)} promoCodeForm={promoCodeForm} type={type}>
			{label && <PunchupLabel>{label}</PunchupLabel>}
			<StyledPunchupInput
				id={id ?? name}
				type={type}
				disabled={disabled}
				style={inputStyles}
				{...register(name)}
				{...rest}
				onWheel={(e) => e.target.addEventListener('wheel', (e) => e.preventDefault())}
				className={isFromRequiredFields ? 'requiredFields' : ''}
			/>
		</PunchupInputContainer>
	);
};

export { PunchupInput };
