import { Error, PunchupInputContainer, PunchupTextArea as TextArea } from './PunchupTextArea.styles';

import { FlexContainer } from '../../FlexContainer';
import { FormInputTextProps } from '../types';
import { InputLabel } from '../InputLabel';
import { StdText } from '../../Text/Text.styles';
import { useFormContext } from 'react-hook-form';

const PunchupTextArea = ({ id, name, label, subtitle, value, isCharCt, required, ...rest }: FormInputTextProps) => {
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<PunchupInputContainer hasError={Boolean(error)}>
			<InputLabel
				htmlFor={id ?? name}
				label={label}
				subtitle={subtitle}
				className="SM"
				required={required}
				color="black"
			/>
			<TextArea id={id ?? name} value={value} {...register(name)} {...rest} />
			{isCharCt && watch(name) && (
				<FlexContainer justifyContent="flex-end">
					<StdText>{`${watch(name).length}`}</StdText>
				</FlexContainer>
			)}
			<Error>{error?.message}</Error>
		</PunchupInputContainer>
	);
};

export { PunchupTextArea };
