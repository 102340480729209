import { CheckboxContainer, CheckboxText, ChoiceInputRow, Container, Label, Wrapper } from './RightForm.styles';
import { Controller, useFormContext } from 'react-hook-form';

import { CheckboxButton } from 'features/common/components/CheckboxButton/CheckBoxButton.styles';
import { EVENT_MODE_REGISTRATION } from 'utils/constants';
import { Form } from 'features/common/components/Form';
import { Icon } from 'features/common/components/Icon';
import { Option } from 'features/common/components/Form/types';
import { RightFormProps } from 'features/CustomFields/types';
import { RootState } from 'store';
import { customFieldTypes } from 'utils/types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const RightForm = ({ event }: RightFormProps) => {
	const { t } = useTranslation();
	const { watch, setValue, setError, control } = useFormContext();
	const { isPunchup } = useSelector((state: RootState) => state.auth);

	const selectedTickets = watch('ticket_types_ids');
	const selectedType = watch('custom_field.type');
	const choices: { name: string }[] = watch('custom_field.choices');

	const customFieldTypeOptions: Option[] = [
		{ value: 'STRING', label: t('customField-types-text') },
		{ value: 'NUMBER', label: t('customField-types-number') },
		// Uncomment after implementing date input/validations
		// { value: 'DATE', label: t('customField-types-date') },
		{ value: 'DROPDOWN', label: t('customField-types-dropdown') },
		{ value: 'MULTIPLE_CHOICE', label: t('customField-types-multipleChoice') },
	];

	const isChecked = (id: number) => selectedTickets.includes(id);

	const onChange = (id: number) =>
		setValue(
			'ticket_types_ids',
			isChecked(id) ? selectedTickets.filter((ticket: number) => ticket !== id) : [...selectedTickets, id]
		);

	const onAddOption = () => {
		if (choices.some((choice) => choice.name === '')) {
			setError('custom_field.choices.0.name', { message: 'Please fill the previous option', type: 'required' });
			return;
		}

		setValue('custom_field.choices', [...choices, { name: '' }]);
	};

	const onDeleteOption = (index: number) => {
		setValue(
			'custom_field.choices',
			choices.filter((_, i) => i !== index)
		);
	};

	return (
		<Wrapper>
			{event.mode === EVENT_MODE_REGISTRATION && (
				<Container>
					<Form.Select
						id="custom_field.type"
						name="custom_field.type"
						label="Type"
						options={customFieldTypeOptions}
						withDefaultValue={false}
					/>
				</Container>
			)}

			{[customFieldTypes.MULTIPLE_CHOICE, customFieldTypes.DROPDOWN].includes(selectedType) && (
				<Container>
					<Label isPunchup={isPunchup}>{t('customFields-optionsLabel')}</Label>

					{choices.map((choice, index) => (
						<ChoiceInputRow key={index}>
							<Controller
								name={`custom_field.choices.${index}.name`}
								control={control}
								defaultValue={choice.name}
								render={({ field, fieldState: { error } }) => (
									<Form.Input id={field.name} errorProp={error?.message} name={field.name} />
								)}
							/>

							{choices.every((c) => c.name) && index + 1 === choices.length && (
								<Icon iconName="plus" size="M" cursor="pointer" onClick={onAddOption} />
							)}

							{index + 1 !== choices.length && (
								<Icon iconName="trash" size="M" cursor="pointer" onClick={() => onDeleteOption(index)} />
							)}
						</ChoiceInputRow>
					))}
				</Container>
			)}

			<Container marginTop="32px">
				<Label isPunchup={isPunchup}>{t('customFields-pickTickets')}</Label>

				{event?.ticket_types?.map((ticket) => (
					<CheckboxContainer key={ticket.ID}>
						<CheckboxButton type="checkbox" checked={isChecked(ticket.ID)} onChange={() => onChange(ticket.ID)} />
						<CheckboxText isPunchup={isPunchup}>{ticket.name}</CheckboxText>
					</CheckboxContainer>
				))}
			</Container>
		</Wrapper>
	);
};
