import { Query, useIsFetching, useIsMutating } from '@tanstack/react-query';

import { QUERY_KEYS_NO_FETCHING } from 'utils/constants';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { useMemo } from 'react';

const useLoading = (): boolean => {
	const isMutating = useIsMutating();
	const isFetching = useIsFetching({
		predicate: (query: Query) => {
			return !Object.values(QUERY_KEYS_NO_FETCHING).some((key) => query.options.queryKey?.toString().includes(key));
		},
	});

	const { loading } = useAppSelector((state: RootState) => state.auth);

	const isLoading = useMemo(() => loading || !!isFetching || !!isMutating, [loading, isFetching, isMutating]);

	return isLoading;
};

export default useLoading;
