import { EntriesData, TableBodyProps } from 'features/LotteryEntries/types';

import { COLUMN_MIN_WIDTH } from './constants';
import { DataRow } from './EntriesTable.styles';
import { FC } from 'react';
import { Table } from 'features/common/components/Table';

export const TableBody: FC<TableBodyProps> = ({ entriesQueryObject, columnsN = 0 }) => {
	const { data: entries } = entriesQueryObject;

	const renderCell = (value: string | number, minWidth: number, maxWidth?: number) => (
		<Table.Cell minWidth={minWidth} maxWidth={maxWidth}>
			{value}
		</Table.Cell>
	);

	if (!entries || entries?.pages?.length === 0) {
		return null;
	}

	return (
		<tbody>
			{entries.pages.map((page: EntriesData[]) =>
				page.map((data: any) => (
					<DataRow gridColumns={`repeat(${columnsN}, 1fr)`} key={[data.ID].join('_')} textAlign="center">
						{renderCell(data.Email || '', COLUMN_MIN_WIDTH[2])}
						{renderCell(data.FirstName || '', COLUMN_MIN_WIDTH[0])}
						{renderCell(data.LastName || '', COLUMN_MIN_WIDTH[1])}
						{renderCell(data.ZipCode || '', COLUMN_MIN_WIDTH[3])}
						{renderCell(data.NumberOfTickets || 0, COLUMN_MIN_WIDTH[4])}
						{renderCell(getStatus(data.Status), COLUMN_MIN_WIDTH[5])}
						{renderCell(data.WheelchairAccess ? 'Yes' : 'No', COLUMN_MIN_WIDTH[7])}
						{renderCell(data.OptOutMailing ? 'Yes' : 'No', COLUMN_MIN_WIDTH[8])}
						{renderCell(data.Gender || '', COLUMN_MIN_WIDTH[9])}
						{renderCell(data.Age || '', COLUMN_MIN_WIDTH[10])}
						{renderCell(data.Ethnicity || '', COLUMN_MIN_WIDTH[11])}
						{renderCell(data.HouseholdIncome || '', COLUMN_MIN_WIDTH[12])}
						{renderCell(data.IdentifyGroups || '', COLUMN_MIN_WIDTH[13])}
					</DataRow>
				))
			)}
		</tbody>
	);
};

const getStatus = (status: string) => {
	switch (status) {
		case 'winner':
			return 'Winner';
		case 'not_winner':
			return 'Not Winner';
		case 'pending':
			return 'Pending';
		default:
			return status;
	}
};
