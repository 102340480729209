import { ContentWrapper, Padding } from './Dashboard.styles';
import { EVENT_MODE_NORMAL, ZERO_TIME } from 'utils/constants';
import { useEffect, useState } from 'react';

import { AffiliatesSection } from './components/AffiliatesSection';
import { CustomFieldsSection } from './components/CustomFieldsSection';
import { EventInfo } from './components/EventInfo';
import { EventWebpageSection } from './components/EventWebpageSection';
import { Highlights } from './components/Highlights';
import { ImgContentSection } from './components/ImgContentSection';
import { MainLayout } from 'layouts/MainLayout';
import { PromoCodesSection } from './components/PromoCodesSection';
import { PunchupEventInfo } from './components/PunchupEventInfo';
import { PunchupHighlights } from './components/PunchupHighlights';
import { PunchupTicketsSection } from './components/PunchupTicketsSection';
import { TicketsSection } from './components/TicketsSection';
import { convertUTCToDate } from 'utils/utils';
import moment from 'moment';
import { useAppSelector } from 'store/hooks';
import useGetEvent from 'features/common/hooks/useGetEvent';

const Dashboard = () => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	const [onSale, setOnsale] = useState<string>('');
	const [firstDateStart, setFirstDateStart] = useState<string>('');
	const [firstDateEnd, setFirstDateEnd] = useState<string>('');

	const { event } = useGetEvent();

	const zeroTime = event?.sellingTime === ZERO_TIME;

	useEffect(() => {
		if (event) {
			setFirstDateStart(convertUTCToDate(event.days[0]?.startDate, event.timeZone));
			setFirstDateEnd(convertUTCToDate(event.days[0]?.endDate, event.timeZone));
			setOnsale(event.sellingTime);
		}
	}, [event]);

	if (isPunchup) {
		return (
			<MainLayout>
				<ContentWrapper>
					{event && (
						<PunchupEventInfo
							eventTitle={event.name}
							date={moment(firstDateStart).format('L')}
							startTime={moment(firstDateStart).format('LT')}
							endTime={moment(firstDateEnd).format('LT')}
							address={event.streetAddress}
							onSaleDate={zeroTime ? event.sellingTime : `${moment(onSale).format('L')} ${moment(onSale).format('LT')}`}
						/>
					)}
					<Padding>
						{event && <PunchupHighlights event={event} />}
						{event && <PunchupTicketsSection />}
						{event && <PromoCodesSection />}
						{event && <CustomFieldsSection eventMode={event.mode} />}
					</Padding>
				</ContentWrapper>
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<ContentWrapper>
				{event && (
					<EventInfo
						eventTitle={event.name}
						date={moment(firstDateStart).format('L')}
						startTime={moment(firstDateStart).format('LT')}
						endTime={moment(firstDateEnd).format('LT')}
						address={event.streetAddress}
						onSaleDate={zeroTime ? event.sellingTime : `${moment(onSale).format('L')} ${moment(onSale).format('LT')}`}
					/>
				)}
				<Padding>
					{event && <Highlights event={event} />}
					{event && <EventWebpageSection id={event.id} isLive={event.liveOnWebpage} />}
					{event && <ImgContentSection eventImgCt={Math.min(1, event.images?.length)} media={event.media} />}
					{event?.mode === EVENT_MODE_NORMAL && <TicketsSection />}
					{event?.mode === EVENT_MODE_NORMAL && <PromoCodesSection />}
					{event && <AffiliatesSection />}
					{/* 
					TODO: remove comment when backend is ready
					<AddOnsSection /> 
					*/}
					{event && <CustomFieldsSection eventMode={event.mode} />}
				</Padding>
			</ContentWrapper>
		</MainLayout>
	);
};

export { Dashboard };
