import * as Styled from './CompedTickets.styles';

import { useHistory, useParams } from 'react-router-dom';

import { CompedTicketsProps } from '../types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Header } from 'features/common/components/Header';
import { Icon } from 'features/common/components/Icon';
import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

export const CompedTicketsHeader = ({ subtitle }: CompedTicketsProps) => {
	const isLargeScreen = useResponsiveScreen();
	const { t } = useTranslation();
	const { id } = useParams<IdParamsType>();
	const { push } = useHistory();
	const onBackRedirect = () => push(ROUTES.DASHBOARD_PATH.replace(':id', id));
	return (
		<Header>
			<FlexContainer
				alignItems="center"
				flexDirection="row"
				gap={isLargeScreen ? '42px' : '20px'}
				justifyContent="flex-start"
			>
				<Styled.IconContainer onClick={onBackRedirect}>
					<Icon iconName="arrowLeft" />
				</Styled.IconContainer>
				<FlexContainer flexDirection="column" gap={isLargeScreen ? '9px' : '0px'}>
					<Styled.Title element="h2">{t('comped-tickets')}</Styled.Title>
					{subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
				</FlexContainer>
			</FlexContainer>
		</Header>
	);
};
