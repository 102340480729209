import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';
import { StdText } from 'features/common/components/Text/Text.styles';
import styled from 'styled-components';

export const Container = styled.div<{ noGap?: boolean }>`
	background-color: ${({ theme }) => theme.colors.grey2};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: ${({ noGap }) => (noGap ? 'unset' : '12px')};
	height: 278px;
	width: 278px;
	padding: 24px;
	flex-shrink: 0;
`;

export const CustomIcon = styled(Icon)`
	height: 60px;
	width: 60px;
`;

export const Stat = styled(StdText)`
	font-weight: 600;
	font-size: 36px;
	line-height: 36px;

	.animate-spin {
		animation: spin 1s linear infinite;
	}
`;

export const Label = styled(StdText)`
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
`;

export const CardButton = styled(Button)`
	border-radius: 16px;
	color: ${({ theme }) => theme.colors.white};
	font-size: 14px;
	mix-blend-mode: screen;
	margin: 0;
	padding: 4px;
	width: 175px;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
