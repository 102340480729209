import { IdParamsType, PromoCode } from 'utils/types';
import { PromoCodeAddCard, PromoCodeCard } from 'features/common/components/PromoCodeCard';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Divider } from 'features/common/components/Divider';
import { FC } from 'react';
import { PromoCodesContainer } from './PromoCodesSection.styles';
import { ROUTES } from 'features/routes';
import { SectionTitle } from 'features/common/components/SectionTitle';
import { getData } from 'services/api';
import { handleType } from 'features/PromoCodesSection/utils';
import { useAppSelector } from 'store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

const PromoCodesSection: FC = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id }: IdParamsType = useParams();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const isLargeScreen = useResponsiveScreen();

	const { data: promocodes } = useQuery({
		queryKey: ['eventPromocodes', id],
		queryFn: () => getData(`/promocodes?event_ids=${id}`),
		initialData: [],
	});

	const onClickAddNew = () => {
		//TODO: uncomment when Assign Existing feature is ready to go
		// push({
		// 	pathname: ROUTES.DASHBOARD_PROMO_CODES_NEW_PATH.replace(':id', '1'),
		// 	state: { fromDashboard: true },
		// });

		if (isPunchup) {
			push(generatePath(ROUTES.EVENT_PROMO_CODES_NEW_FORM_PATH, { eventId: id }));
		} else {
			push(ROUTES.PROMO_CODES_NEW_PATH);
		}
	};

	return (
		<>
			<SectionTitle title={t('dashboard-promoCodes')} buttonLabel={t('dashboard-add')} onClick={onClickAddNew} />
			<PromoCodesContainer>
				{promocodes.length ? (
					promocodes.map(
						({
							id,
							code,
							promocode_type,
							total_uses,
							discount,
							references,
							active,
							percentage,
							coupon_type,
						}: PromoCode) => (
							<PromoCodeCard
								key={id}
								id={id}
								name={code}
								type={handleType(promocode_type)}
								used={total_uses}
								amount={discount || percentage}
								references={references || []}
								isActive={active}
								isPercentage={coupon_type === 'percentage'}
							/>
						)
					)
				) : (
					<PromoCodeAddCard fromDashboard />
				)}
			</PromoCodesContainer>
			{!isLargeScreen && <Divider />}
		</>
	);
};

export { PromoCodesSection };
