import * as Styled from '../../MyProfile.styles';

import { FC } from 'react';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { GroupWrap } from '../GroupWrap';
import { Icon } from 'features/common/components/Icon';
// import { DefaultRoyalty } from './components/DefaultRoyalty';
// import { TermsOfService } from './components/TermsOfService';
import { Logo } from './components/Logo';
import { OrgPanelProps } from './types';
import { ROUTES } from 'features/routes';
import { useHistory } from 'react-router-dom';
import { useLinkStripeAccount } from 'hooks/useLinkStripeAccount';
import { useTranslation } from 'react-i18next';

// TODO: uncomment default royalty or ToS when partners want to add royalties to secondary market. royalty comes from orgData

export const OrgPanel: FC<OrgPanelProps> = ({ orgData, hasMultiplePartners }: OrgPanelProps) => {
	const { push } = useHistory();
	const { bank_account: bankStatus, name: orgName } = orgData || {};
	const { t } = useTranslation();
	const connected = (bankStatus && bankStatus.toLowerCase()) === 'connected';

	const handleSwitchTeam = () => hasMultiplePartners && push(ROUTES.SWITCH_TEAM_PATH);
	const { linkStripeAccount } = useLinkStripeAccount();

	return (
		<Styled.Panel>
			<Styled.LeftContainer>
				<Styled.LogoContainer>
					<Logo />
				</Styled.LogoContainer>
				<Styled.NameContainer>
					<Styled.OrgNameButton>
						<Styled.NameHeader id="org-name">{orgName}</Styled.NameHeader>
					</Styled.OrgNameButton>

					<Styled.LogoutContainer onClick={handleSwitchTeam}>
						<Styled.LogoutIcon iconName="logout" />
						<Styled.LogoutText>{t('settings-switch-team')}</Styled.LogoutText>
					</Styled.LogoutContainer>
				</Styled.NameContainer>
			</Styled.LeftContainer>
			<Styled.RightContainer>
				<Styled.CenteredWrap>
					<GroupWrap htmlFor="bank-account" label={'settings-bank-account'}>
						<FlexContainer gap="4px" alignItems="center">
							{connected && (
								<FlexContainer>
									<Styled.TextMinContent id="bank-account">{bankStatus}</Styled.TextMinContent>
									<Icon iconName={'checkGreen'} />
								</FlexContainer>
							)}
							{!connected && (
								<Styled.ConnectAccountButton
									text={t('settings-connect-bank-account')}
									type="button"
									onClick={() => linkStripeAccount()}
									category="outlined"
								/>
							)}
						</FlexContainer>
					</GroupWrap>
					{/* <DefaultRoyalty royalty={royalty} /> */}
					{/* <TermsOfService /> */}
				</Styled.CenteredWrap>
			</Styled.RightContainer>
		</Styled.Panel>
	);
};
