import { DesktopFilters } from './components/DesktopFilters';
import { FilterCommonProps } from './types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { MobileFilter } from './components/MobileFilter';
import { getData } from 'services/api';
import { useQuery } from '@tanstack/react-query';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useState } from 'react';

export const RTKFilter = ({ selectedPeriod, selectedEvents, eventCount, page, filterOption }: FilterCommonProps) => {
	const isLargeScreen = useResponsiveScreen();
	const [perPage, setPerPage] = useState('5');
	const [searchText, setSearchText] = useState('');
	// TODO: use for filter transitions
	// const isMounted = useDelayedUnmount(isLargeScreen, 200);

	const eventQueryObj = useQuery({
		queryKey: ['events', perPage, searchText],
		queryFn: () => getData(`/events?date=all_time&per_page=${perPage}&search=${searchText}`),
	});

	return isLargeScreen ? (
		<FlexContainer gap="32px">
			<DesktopFilters
				eventQueryObj={eventQueryObj}
				perPageState={{ perPage, setPerPage }}
				selectedPeriod={selectedPeriod}
				selectedEvents={selectedEvents}
				eventCount={eventCount}
				page={page}
				filterOption={filterOption}
			/>
		</FlexContainer>
	) : (
		<MobileFilter
			eventQueryObj={eventQueryObj}
			perPageState={{ perPage, setPerPage }}
			setSearchText={setSearchText}
			selectedPeriod={selectedPeriod}
			selectedEvents={selectedEvents}
			eventCount={eventCount}
			page={page}
			filterOption={filterOption}
		/>
	);
};
