import styled from 'styled-components';

export const FormContainer = styled.div`
	gap: 16px;
	width: 100%;
	box-sizing: border-box;
	padding: 15px;
	max-width: 800px;
	margin: 0 auto;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 16px;
`;
