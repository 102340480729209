import { RowProps } from './types';
import { Table } from 'features/common/components/Table';
import styled from 'styled-components';

export const Row = styled.tr<RowProps>`
	display: grid;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	padding: ${({ padding }) => padding || '0'};
	height: ${({ height }) => height || '48px'};
	text-align: ${({ textAlign }) => textAlign || 'left'};
	align-items: center;
`;

export const ColumnHeader = styled(Table.ColumnHeader)`
	color: ${({ theme }) => theme.colors.black};
`;

export const DataRow = styled(Row)`
	background-color: ${({ theme }) => theme.colors.whitePearl};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

	td {
		padding: 12px 16px;
		font-size: 14px;
		border: none;
		color: ${({ theme }) => theme.colors.grey8};
	}

	.statusCell {
		border-radius: 4px;
		padding: 4px 8px;
		font-weight: 500;
		color: ${({ theme }) => theme.colors.whitePearl};
	}
`;
