import { FlexContainer } from 'features/common/components/FlexContainer';
import { InputLabel } from '../InputLabel';
import { InputToggleProps } from '../types';
import { PunchupToggle } from '../../PunchupToggle';
import { useFormContext } from 'react-hook-form';

export const PunchupInputToggle = ({ name, ...rest }: InputToggleProps) => {
	const { watch, setValue } = useFormContext();
	const toggleValue = watch(name);

	return (
		<FlexContainer justifyContent="space-between" alignItems="center" margin="8px 0 0 0" width="100%">
			<InputLabel {...rest} />
			<PunchupToggle isChecked={toggleValue} handleClick={() => setValue(name, !toggleValue)} name={name} />
		</FlexContainer>
	);
};
