import * as Styled from './OrganizationCard.styles';

import DefaultPartnerImage from 'assets/images/defaultPartnerImage.svg';
import { Partner } from 'services/auth/types';
import { ROUTES } from 'features/routes';
import { historyLocationStateProps } from './types';
import { setCurrentPartner } from 'store/slices/AuthSlice';
import { setPartner } from 'services/auth';
import { useAppDispatch } from 'store/hooks';
import { useHistory } from 'react-router-dom';

export const OrganizationCard = (partner: Partner) => {
	const { location, push } = useHistory();
	const dispatch = useAppDispatch();

	const setTeam = () => {
		const { state } = location as historyLocationStateProps;
		dispatch(setPartner(partner, () => push(state?.fromLogin ? ROUTES.HOME_PATH : ROUTES.SETTINGS_PATH), push));
		dispatch(setCurrentPartner(partner));
	};

	const hasValidImage =
		partner.image !== undefined && partner.image !== '' && partner.image !== '-' && partner.image !== 'empty';
	return (
		<Styled.OrganizationCard>
			<Styled.ImageBg onClick={setTeam}>
				<Styled.Img data-cy={partner.name} src={hasValidImage ? partner.image : DefaultPartnerImage} />
			</Styled.ImageBg>
			<Styled.OrgName>{partner.name}</Styled.OrgName>
		</Styled.OrganizationCard>
	);
};
