import { Carousel, Page } from './Carousel.styles';

import { HTMLAttributes } from 'react';

export interface OnlyChildrenProps extends HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode;
}

export const CarouselContainer = ({ children, ...others }: OnlyChildrenProps) => (
	<Carousel {...others}>{children}</Carousel>
);

export const PageContainer = ({ children, ...others }: OnlyChildrenProps) => <Page {...others}>{children}</Page>;
