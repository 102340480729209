import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 42px;
	width: 100%;
`;

export const Label = styled.label`
	font-size: 14px;
	font-weight: 500;
	color: #333333;
`;

export const Toggle = styled.label`
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;

	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + span {
			background-color: #e86339;
		}

		&:checked + span:before {
			transform: translateX(22px);
		}
	}
`;

export const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #e1e1e1;
	transition: 0.4s;
	border-radius: 34px;

	&:before {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		transition: 0.4s;
		border-radius: 50%;
	}
`;
