import { MyFinancesProps } from './types';
import { Payouts } from './Payouts';

const MyFinances = ({ orgData, userData, membersData, hasMultiplePartners }: MyFinancesProps) => {
	return (
		<Payouts
			orgData={orgData}
			userData={userData}
			membersData={membersData}
			hasMultiplePartners={hasMultiplePartners}
		/>
	);
};

export { MyFinances };
