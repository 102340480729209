import * as Styled from './TicketTypeSelector.styles';

import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { Page } from '../..';
import { TicketOptionsProps } from './types';
import { TicketTypes } from './TicketTypes';
import { Title } from './Title';
import { useTranslation } from 'react-i18next';

export const TicketTypeSelector = () => {
	const { t } = useTranslation();
	//TO DO: when they ask for ticket library
	const ticketOptions: TicketOptionsProps[] = [
		{
			icon: 'templates',
			info: t('generic-imgRatio-template'),
			link: '#4',
			text: t('generic-templates'),
			type: 'template',
		},
		// {
		// 	text: 'Ticket designer',
		// 	type: 'designer',
		// 	icon: 'tixologistInitialWhite',
		// 	link: '#4',
		// },
		// { text: 'Ticket library', type: 'library', icon: 'library', link: '#4' },
		{
			icon: 'upload',
			info: t('generic-imgRatio-upload'),
			link: '#6',
			text: t('generic-upload'),
			type: 'upload',
		},
	];
	return (
		<Page id="3">
			<Styled.Container>
				<Title />
				<FlexContainer gap="16px" flexWrap="wrap" width="100%" justifyContent="center">
					{ticketOptions.map(TicketTypes)}
				</FlexContainer>
			</Styled.Container>
		</Page>
	);
};
