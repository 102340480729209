import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

interface ButtonContainerProps {
	isMobile: boolean;
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
	position: ${({ isMobile }) => isMobile && 'fixed'};
	height: 100px;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 636px;
`;

export const PunchupButtonContainer = styled.div`
	gap: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 auto;
`;

export const DropdownContainer = styled.button`
	all: unset;
	width: 100%;
	display: flex;
	padding: 16px;
	cursor: pointer;
	border-radius: 8px;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
	border: ${({ theme }) => `1px solid ${theme.colors.grey8}`};
`;

export const DropdownText = styled(Text)`
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-transform: none;
`;

export const Arrow = styled(Icon)`
	width: 16px;
	height: 16px;

	&.inactive {
		transition: 0.6s ease;
		transform: rotate(0deg);
	}

	&.active {
		transition: 0.6s ease;
		transform: rotate(180deg);
	}
`;

export const SubmitButton = styled(Button)`
	width: 240px;
	height: 42px;
	display: flex;
	margin: 10px 0;
	cursor: pointer;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const PunchupSubmitButton = styled(Button)`
	width: 240px;
	height: 48px;
	display: flex;
	margin: 0 auto;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	border-radius: 12px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: #fc6847 !important;
	background-image: none !important;
	border: none;
	color: white !important;

	&:hover {
		background: #e85e40 !important;
		background-image: none !important;
		border: none;
	}

	&:active,
	&:focus {
		background: #fc6847 !important;
		background-image: none !important;
		border: none;
	}
`;
