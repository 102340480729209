import { Button as UnstyledButton } from 'features/common/components/Button';
import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const Container = styled.div`
	gap: 24px;
	width: 60%;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	flex-direction: column;
	justify-content: flex-end;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;

export const InputContainer = styled.div`
	gap: 10px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

export const StepTitle = styled(UnstyledText)`
	max-width: 60%;
	line-height: 48px;
	text-align: center;
	text-transform: none;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 90%;
		line-height: 30px;
	}
`;

export const NextButton = styled.button`
	all: unset;
	width: 240px;
	height: 42px;
	cursor: pointer;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme }) => theme.colors.greenGradient3};

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

export const SkipButton = styled(NextButton)`
	color: ${({ theme }) => theme.colors.green};
	background: ${({ theme }) => theme.colors.grey3};
	border: ${({ theme }) => `1px solid ${theme.colors.green}`};
`;

export const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		// gap: 8px;
		// flex-direction: column;
	}
`;

export const ScreenTitle = styled.div`
	position: fixed;
	height: 100px;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 4;
	background: ${({ theme }) => theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		top: 125px;
		background: ${({ theme }) => theme.colors.imageOverlay};
	}
`;

export const SubmitButton = styled(UnstyledButton)`
	width: 240px;
	height: 42px;
	display: flex;
	margin: 10px 0;
	cursor: pointer;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.fonts.primary};

	&:disabled {
		opacity: 0.5;
	}
`;

export const CenteredContainer = styled.div`
	gap: 8px;
	width: 60%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 90%;
	}
`;
