import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export interface RowProps {
	padding?: string;
	height?: string;
	gridColumns: string;
	textAlign?: string;
}

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 16px;
	margin-top: 10px;
	border-radius: 8px;
	padding: 20px;
	background-color: ${({ theme }) => theme.colors.whitePearl};
`;

export const Row = styled.tr<RowProps>`
	display: grid;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	padding: ${({ padding }) => padding || '0'};
	height: ${({ height }) => height || '48px'};
	text-align: ${({ textAlign }) => textAlign || 'left'};
	align-items: center;
`;

export const HeaderRow = styled(Row)`
	td {
		padding: 12px 16px;
		color: ${({ theme }) => theme.colors.white};
		font-weight: 500;
		font-size: 14px;
		border: none;
	}
`;

export const DataRow = styled(Row)`
	td {
		font-size: 14px;
		border: none;
	}
`;

export const TicketTypeTitle = styled(Text)`
	font-size: 16px;
	font-weight: 600;
	line-height: 150%;
	color: ${({ theme }) => theme.colors.black};
`;

export const TicketTypeText = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.black};
`;

export const QuantityText = styled(Text)`
	font-size: 16px;
	font-weight: 600;
	line-height: 150%;
`;

export const SoldOut = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	display: flex;
	justify-content: center;
	gap: 10px;
	width: fit-content;
	padding: 8px 4px;
	border-radius: 2px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.grey};
	width: 100px;
`;

export const QuantityIcon = styled.div`
	font-size: 18px;
`;
