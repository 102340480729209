import * as Styled from './PunchupTicketTypesList.styles';

import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import { useHistory, useParams } from 'react-router-dom';

import { DragHandle } from 'assets/customizedIcons';
import { FC } from 'react';
import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { TicketTypesListProps } from './types';
import { formatMoney } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const PunchupTicketTypesList: FC<TicketTypesListProps> = ({ ticketTypes, setTicketTypes, showSaveButtons }) => {
	const { id: eventId } = useParams<IdParamsType>();
	const { t } = useTranslation();
	const { push } = useHistory();

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const newTicketTypes = Array.from(ticketTypes.sort((tt1, tt2) => tt1.orderIndex - tt2.orderIndex));
		const [reorderedItem] = newTicketTypes.splice(result.source.index, 1);
		newTicketTypes.splice(result.destination.index, 0, reorderedItem);
		newTicketTypes.forEach((tt, index) => (tt.orderIndex = index));

		setTicketTypes(newTicketTypes);
	};

	const onClickDetails = (ticketId: number) => {
		push(ROUTES.TICKET_DETAILS_PATH.replace(':id', eventId).replace(':ticketId', ticketId.toString()));
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable" direction="horizontal">
				{(provided) => (
					<Styled.DraggableContainer ref={provided.innerRef} {...provided.droppableProps}>
						{ticketTypes.map(({ iD, initialPrice, amount, sold, holdsAmount, name }, index) => (
							<Draggable key={iD} draggableId={iD.toString()} index={index}>
								{(provided) => (
									<Styled.TicketCardContainer ref={provided.innerRef} {...provided.draggableProps}>
										{showSaveButtons && (
											<Styled.DragHandleContainer {...provided.dragHandleProps} data-cy="drag-handle">
												<DragHandle />
											</Styled.DragHandleContainer>
										)}
										<Styled.Price>${formatMoney(initialPrice)}</Styled.Price>
										<Styled.TicketName>{name}</Styled.TicketName>
										<Styled.InfoItem>
											<Styled.CustomIcon iconName="roundDollar" />
											{`${sold}/${amount} Sold`}
										</Styled.InfoItem>
										<Styled.InfoItem>
											<Styled.CustomIcon iconName="roundHold" />
											{`${holdsAmount} Comped`}
										</Styled.InfoItem>
										<Styled.DetailsButton type="button" onClick={() => onClickDetails(iD)}>
											{t('dashboard-tickets-details')}
										</Styled.DetailsButton>
									</Styled.TicketCardContainer>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Styled.DraggableContainer>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export { PunchupTicketTypesList };
