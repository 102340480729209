import { Input, Text, TicketTagContainer } from './SpecificPromocodes.styles';
import { useEffect, useState } from 'react';

import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { TicketTagProps } from './types';
import { Toggle } from 'features/common/components/Toggle';
import { handleQuantity } from './utils';
import { selectedType } from 'features/PromoCodesForm/types';
import { useAppSelector } from 'store/hooks';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TicketTag: React.FC<TicketTagProps> = ({
	title,
	isLastElement,
	id,
	unlimitedAll,
	fromEventList,
}: TicketTagProps) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { getValues, setValue, watch } = useFormContext();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const [unlimited, setUnlimited] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<string>('');
	const [shouldExecuteHandleQuantity, setShouldExecuteHandleQuantity] = useState<boolean>(false);

	const isEdit = pathname.includes('edit');

	const handleToggle = () => setUnlimited(!unlimited);

	useEffect(() => {
		if (unlimitedAll) {
			getValues('selected').forEach((item: selectedType) => {
				if (item.id === id) setUnlimited(true);
			});
		} else setUnlimited(false);
	}, [unlimitedAll]);

	useEffect(() => {
		if (isEdit && getValues('isEdited')) {
			getValues('selected').map((item: selectedType) => {
				if (item.id === id) {
					if (item.quantity === -1) setUnlimited(true);
					else setQuantity(String(item.quantity));
				}
			});
			setShouldExecuteHandleQuantity(true);
		}
	}, [watch('isEdited')]);

	useEffect(() => {
		if (!isEdit || shouldExecuteHandleQuantity) {
			handleQuantity({
				selected: getValues('selected'),
				setValue,
				quantity,
				unlimited,
				id,
			});
		}
	}, [unlimited, quantity]);

	return (
		<TicketTagContainer isLastElement={!isLastElement} fromEventList={fromEventList}>
			<Text isPunchup={isPunchup} className="span">
				{title}
			</Text>
			<FlexContainer alignItems="center" gap="10px">
				<Input value={quantity} onChange={(e) => setQuantity(e.target.value)} type="number" placeholder="0" min={0} />
				<FlexContainer alignItems="center" gap="12px" margin="16px 0px">
					<Text isPunchup={isPunchup} className="span">
						{t('unlimited')}
					</Text>
					<Toggle
						isChecked={unlimited}
						name="unlimited"
						id={`${id}`}
						handleClick={handleToggle}
						isPunchup={isPunchup}
					/>
				</FlexContainer>
			</FlexContainer>
		</TicketTagContainer>
	);
};
