import * as Styled from './PunchupOrdersTable.styles';

import { COLUMN_HEADINGS, COLUMN_MIN_WIDTH } from './constants';

import { FC } from 'react';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { OrderProps } from 'features/Orders/types';
import { PunchupTableBody } from './PunchupTableBody';
import { Table } from 'features/common/components/Table';
import { useTranslation } from 'react-i18next';

export const PunchupOrdersTable: FC<OrderProps> = ({ ordersQueryObject }) => {
	const { t } = useTranslation();
	const { fetchNextPage, hasNextPage } = ordersQueryObject;

	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Styled.Row gridColumns="repeat(8, 1fr)" textAlign="center">
							{COLUMN_HEADINGS.map((heading, i) => {
								return (
									<Table.Cell key={heading} minWidth={COLUMN_MIN_WIDTH[i]}>
										<Styled.ColumnHeader>{t(heading)}</Styled.ColumnHeader>
									</Table.Cell>
								);
							})}
						</Styled.Row>
					</thead>

					<PunchupTableBody ordersQueryObject={ordersQueryObject} />
				</Table>
			</Table.TableContainer>

			{hasNextPage && (
				<FlexContainer justifyContent="center" margin="32px 0">
					<Table.Button
						category={OUTLINED}
						text={t('button-load-more')}
						onClick={() => fetchNextPage()}
						type="button"
					/>
				</FlexContainer>
			)}
		</>
	);
};
