import { CustomQuestionContainer, Label, SelectsContainer, Wrapper } from './QuestionForm.styles';

import { CustomFieldFormProps } from 'features/CustomFields/types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Form } from 'features/common/components/Form';
import { PunchupInput } from 'features/common/components/Form/PunchupInput';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const QuestionForm = () => {
	const { t } = useTranslation();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext<CustomFieldFormProps>();

	const nameType = watch('custom_field.name_type');
	const onlyBuyer = watch('custom_field.only_buyer');

	useEffect(() => {
		if (onlyBuyer === 'buyerOnly') {
			setValue('custom_field.name_type', 'CUSTOM_QUESTION');
		}
	}, [onlyBuyer]);

	const questionOptions = [
		{ value: 'buyerOnly', optionLabel: t('customFields-buyerOnly') },
		{ value: 'eachAtendee', optionLabel: t('customFields-eachAttendee') },
	];

	const requiredQuestionOptions = [
		{ value: 'required', optionLabel: t('customFields-required') },
		{ value: 'notRequired', optionLabel: t('customFields-notRequired') },
	];

	const nameTypeOptions = [
		{ value: 'CUSTOM_QUESTION', optionLabel: t('customFields-nameType-custom') },
		{ value: 'ATTENDEE_NAME', optionLabel: t('customFields-nameType-name') },
		{ value: 'ATTENDEE_EMAIL', optionLabel: t('customFields-nameType-email') },
	];

	return (
		<Wrapper isPunchup={isPunchup}>
			<SelectsContainer>
				<Form.RadioButtonList
					id="custom_field.only_buyer"
					name="custom_field.only_buyer"
					options={questionOptions}
					label={t('customFields-answerer')}
					className="font-bold"
				/>
			</SelectsContainer>

			{onlyBuyer !== 'buyerOnly' && (
				<SelectsContainer>
					<Form.RadioButtonList
						id="custom_field.name_type"
						name="custom_field.name_type"
						options={nameTypeOptions}
						label={t('customFields-nameType-label')}
						className="hidden"
					/>
				</SelectsContainer>
			)}

			{nameType === 'CUSTOM_QUESTION' && (
				<CustomQuestionContainer>
					<FlexContainer flexDirection="column" gap="12px">
						<Label isPunchup={isPunchup}>{t('customFields-question')}</Label>

						{isPunchup ? (
							<PunchupInput
								id="custom_field.name"
								name="custom_field.name"
								placeholder="(E.g “Occupation”)"
								errorProp={errors.custom_field?.name?.message}
							/>
						) : (
							<Form.Input
								id="custom_field.name"
								name="custom_field.name"
								placeholder="(E.g “Occupation”)"
								errorProp={errors.custom_field?.name?.message}
							/>
						)}
					</FlexContainer>

					<SelectsContainer>
						<Form.RadioButtonList
							id="custom_field.is_required"
							name="custom_field.is_required"
							options={requiredQuestionOptions}
							label={t('customFields-requiredQuestion')}
							className="font-bold"
						/>
					</SelectsContainer>
				</CustomQuestionContainer>
			)}

			{['ATTENDEE_NAME', 'ATTENDEE_EMAIL'].includes(nameType) && (
				<Label isPunchup={isPunchup}>
					{t('customFields-name-email', { type: nameType === 'ATTENDEE_NAME' ? 'name' : 'email' })}
				</Label>
			)}
		</Wrapper>
	);
};
