import { ContentWrapperProps } from '../../PromoCodesForm/types';
import { Form } from 'features/common/components/Form';
import { Label as UnstyledLabel } from 'features/common/components/Form/Form.styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div<ContentWrapperProps>`
	${({ type, theme }) =>
		type === 'existing'
			? `
		padding: 6%;

		@media screen and (max-width: ${theme.breakpoints.sm}) {
			padding: 0;
			width: 100vw;
		}
	`
			: `
		width: 100vw;
		display: flex;
		flex-direction: ${type === 'eventSpecific' ? 'row' : 'column'};
		align-items: center;
		max-height: calc(100vh - 270px);
		overflow-y: scroll;
		@media screen and (max-width: ${theme.breakpoints.xxs}) {
			max-height: calc(100vh - 330px);
			align-items: ${type === 'eventSpecific' ? 'flex-start' : 'center'};
		}
	`};
`;

export const StyledForm = styled(Form)`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	gap: 10px;
	padding: 6px 12px;
	box-sizing: border-box;
	height: 100%;
	width: 100%;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const RightContainer = styled.div`
	height: calc(100vh - 256px);
	max-height: calc(100vh - 256px);
	background: ${({ theme }) => theme.colors.grey4};
	padding: 55px 85px;
	box-sizing: border-box;
	overflow-y: scroll;
	width: 50vw;
	max-width: 50vw;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: 30px 40px 120px 40px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100vw;
		max-width: 100vw;
		overflow-y: unset;
		height: fit-content;
		max-height: fit-content;
		padding: 30px 20px 120px 20px;
	}

	::-webkit-scrollbar {
		display: none;
	}
`;

export const LeftContainer = styled(RightContainer)`
	background: transparent;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 30px 20px 30px 20px;
	}
`;

export const Label = styled(UnstyledLabel)`
	color: ${({ theme }) => theme.colors.black};
`;
