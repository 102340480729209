import { Icon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const DraggableContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
	padding: 16px 0;
`;

export const TicketCardContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	background: #f5f0e8;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	width: 240px;
	padding: 20px;
	gap: 4px;
`;

export const DragHandleContainer = styled.div`
	position: absolute;
	top: 8px;
	left: 8px;
	padding: 4px;
	border-radius: 4px;
	cursor: grab;
	z-index: 1;

	&:active {
		cursor: grabbing;
	}
`;

export const Price = styled.div`
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 4px;
	text-align: right;
	color: ${({ theme }) => theme.colors.black};
`;

export const TicketName = styled.div`
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 16px;
	color: #333;
`;

export const InfoItem = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	color: #000;
	font-size: 14px;
	margin-bottom: 4px;

	svg {
		width: 16px;
		height: 16px;
		filter: brightness(0);
		opacity: 0.8;
	}
`;

export const CustomIcon = styled(Icon)`
	&& {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		filter: brightness(0);

		svg {
			width: 100%;
			height: 100%;

			opacity: 0.8;
		}
	}
`;

export const DetailsButton = styled.button`
	width: 100%;
	padding: 12px;
	background: #333;
	color: white;
	border: none;
	border-radius: 12px;
	cursor: pointer;
	margin-top: 16px;
	font-size: 16px;

	&:hover {
		background: #444;
	}
`;
