import { NumberProps, PieChartProps } from './types';

import styled from 'styled-components';

export const Root = styled.div`
	margin: 0;
`;

export const PieChart = styled.div`
	--p: ${({ percentage }: PieChartProps) => percentage};
	--b: ${({ sm }: PieChartProps) => (sm ? '8px' : '10px')};
	--c: ${({ color }: PieChartProps) => color};
	--w: ${({ iconWidth }: PieChartProps) => iconWidth}px;
	--color: transparent;

	width: var(--w);
	aspect-ratio: 1;
	position: relative;
	display: inline-grid;
	margin: 5px;
	place-content: center;

	:before,
	:after {
		content: '';
		position: absolute;
		border-radius: 50%;
	}
	:before {
		inset: 0;
		background: radial-gradient(farthest-side, var(--c) 98%, transparent) top/var(--b) var(--b) no-repeat,
			conic-gradient(var(--c) 0, calc(var(--p) * 1%), var(--color) 0);
		-webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
		mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
	}
	:after {
		inset: calc(50% - var(--b) / 2);
		background: var(--c);
		transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
	}
`;

export const ColorPieChart = styled(PieChart)`
	position: absolute;
	transform: rotate3d(0.1, 1, 0, 180deg);
	z-index: 1;
`;

export const Number = styled.div<NumberProps & { isPunchup?: boolean }>`
	color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.black : theme.colors.white)};
	font-size: ${({ fontSize }: NumberProps) => fontSize}px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;
