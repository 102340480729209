import { InputStylesProp, StyledLabelStylesProp, StylesProp } from './types';
import styled, { css } from 'styled-components';

const checkedSwitch = ({ theme, disabled }: any) => css`
	+ span {
		background: ${disabled ? theme.colors.grey6 : theme.colors.punchupOrange};
		opacity: ${disabled && 0.75};

		> span {
			transform: translateX(32px);
		}
	}
`;

const notCheckedSwitch = ({ theme, disabled }: any) => css`
	+ span {
		background: ${disabled ? theme.colors.grey6 : theme.colors.grey6};

		> span {
			transform: translateX(0);
		}
	}
`;

export const StyledLabel = styled.label<StyledLabelStylesProp>`
	position: relative;
	width: 60px;
	height: 28px;
	cursor: ${({ disabled }) => !disabled && 'pointer'};
`;

export const Input = styled.input<InputStylesProp>`
	display: none;
	transform: scale(0);
	+ span {
		width: 60px;
		height: 28px;

		> span {
			opacity: ${({ disabled }) => disabled && 0.75};
		}
	}

	${({ theme, isChecked, disabled }) =>
		isChecked ? checkedSwitch({ theme, disabled }) : notCheckedSwitch({ theme, disabled })};
`;

export const Background = styled.span<StylesProp>`
	border-radius: 100px;
	height: 100%;
	left: 0;
	transition: background 0.4s;
	position: absolute;
	width: 100%;
	opacity: ${({ disabled, isChecked }) => disabled && (isChecked ? '0.75' : '0.75')};
`;

export const Circle = styled.span<StylesProp>`
	position: absolute;
	width: 24px;
	height: 24px;
	left: 2px;
	top: 2px;
	background: ${({ theme }) => theme.colors.whitePearl};

	border-radius: 100px;
	transition: transform 0.45s;
	display: flex;
	align-items: center;
	justify-content: center;

	&:disabled {
		opacity: 0.75;
	}
`;
