import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const CardContainer = styled.div`
	background-color: #1c1c1c;
	border-radius: 8px;
	padding: 16px;
	width: 280px;
`;

export const Price = styled.div`
	color: white;
	font-size: 18px;
	font-weight: 500;
	text-align: right;
	margin-bottom: 12px;
`;

export const Information = styled.div`
	display: flex;
	gap: 16px;
`;

export const Image = styled.img<{ imageType?: string }>`
	width: 120px;
	height: 80px;
	object-fit: cover;
	border-radius: 4px;
`;

export const InfoColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1;
`;

export const TicketName = styled.div`
	color: white;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 4px;
`;

export const InfoRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
`;

export const DetailsButton = styled(Button)`
	&& {
		background-color: transparent;
		color: #666666;
		font-size: 14px;
		padding: 6px 16px;
		border-radius: 4px;
		font-weight: normal;
		border: 1px solid #666666;

		&:hover {
			background-color: rgba(102, 102, 102, 0.1);
		}
	}
`;

export const TicketInfoItemContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const TicketInfoItemText = styled.span`
	color: white;
	font-size: 14px;
`;

export const AddCardContainer = styled.div`
	background-color: #f8f8f8;
	border-radius: 8px;
	padding: 16px;
	width: 280px;
	height: 160px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: ${({ theme }) => theme.colors.punchupBackgroundHover};
	}
`;

export const AddCardContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`;

export const AddIcon = styled(Icon)`
	font-size: 24px;
`;

export const AddText = styled.div`
	color: #2c2c2c;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
`;
