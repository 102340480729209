import './locals/i18n';

import ErrorBoundary from 'ErrorBoundary';
import GlobalStyle from 'styles/globalStyles';
import { Routes } from 'features/common/components/Routes';

if (process.env.REACT_APP_ENV === 'production') {
	import('react-ga4').then((ReactGA) => ReactGA.default.initialize(`${process.env.REACT_APP_MEASUREMENT_ID}`));
	import('@hotjar/browser').then((Hotjar) => Hotjar.default.init(5019452, 6));
}

function App(): JSX.Element {
	return (
		<ErrorBoundary>
			<>
				<GlobalStyle />
				<Routes />
			</>
		</ErrorBoundary>
	);
}

export default App;

const MIN_HEIGHT = 500;
let currentDocumentHeight = 0;

const handleDocumentMutation = () => {
	const documentHeight = document.body.scrollHeight;

	let postMessage = false;
	if (documentHeight && documentHeight !== currentDocumentHeight) {
		postMessage = true;
		currentDocumentHeight = documentHeight >= MIN_HEIGHT ? documentHeight : MIN_HEIGHT;
	} else if (currentDocumentHeight < MIN_HEIGHT) {
		postMessage = true;
		currentDocumentHeight = MIN_HEIGHT;
	}

	if (postMessage) {
		window.parent.postMessage({ height: currentDocumentHeight + 10 }, '*');
	}
};

const observer = new MutationObserver(handleDocumentMutation);

observer.observe(document.body, {
	subtree: true,
	attributes: true,
	childList: true,
	characterData: true,
});
