import { Arrow, Column, Container, Group, Image, PaletteContainer, RowContainer, Text, Wrapper } from './Colors.styles';

import { Icon } from 'features/common/components/Icon';
import { WebisteColorProps } from './types';
import { useState } from 'react';

export const Colors = ({ color = '', handleOpenModal, image, isExample = true, title, text }: WebisteColorProps) => {
	const [isOpenColors, setIsOpenColors] = useState<boolean>(false);

	const handleClick = () => {
		setIsOpenColors(!isOpenColors);
	};

	return (
		<Container id={text} onClick={handleClick}>
			<RowContainer key={title}>
				<Column className="fit">
					<PaletteContainer
						color={color}
						className={color ? 'data large' : 'none large'}
						onClick={handleOpenModal}
						data-cy={title}
					>
						{!color ? <Icon iconName="plus" /> : <Icon iconName="pencilColor" />}
					</PaletteContainer>
				</Column>
				<Group>
					<Text>{title}</Text>
					{isExample && <Arrow className={isOpenColors ? 'active' : 'inactive'} iconName="arrowDown" />}
				</Group>
			</RowContainer>
			{isOpenColors && (
				<Wrapper>
					<Text>{text}</Text>
					<Image src={image}></Image>
				</Wrapper>
			)}
		</Container>
	);
};
