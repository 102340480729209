import { ArrowLeft } from 'assets/customizedIcons';
import { ArrowProps } from '../types';
import styled from 'styled-components';

export const GrayContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 8px;
	background-color: #e5e5e5;
	border: 1px solid #dddddd;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: #d9d9d9;
	}
`;

export const ArrowIcon = styled(ArrowLeft)<ArrowProps>`
	height: 20px;
	width: 20px;
	stroke: ${({ disabled }) => (disabled ? '#CCCCCC' : '#333333')};
	transform: ${({ isRight }) => (isRight ? 'rotate(180deg)' : 'none')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
