import { HeaderContainer, Text } from './Modal.styles';

import { HeaderProps } from './types';
import { useAppSelector } from 'store/hooks';

function Header({ children, className }: HeaderProps) {
	const { isPunchup } = useAppSelector((state) => state.auth);
	return (
		<HeaderContainer className={className} isPunchup={isPunchup}>
			<Text>{children}</Text>
		</HeaderContainer>
	);
}

export { Header };
