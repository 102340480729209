import { ActionsContainer } from './Modal.styles';
import { CommonProps } from './types';
import { useAppSelector } from 'store/hooks';

function ActionButtons({ children, className }: CommonProps) {
	const { isPunchup } = useAppSelector((state) => state.auth);
	return (
		<ActionsContainer className={className} isPunchup={isPunchup}>
			{children}
		</ActionsContainer>
	);
}

export { ActionButtons };
