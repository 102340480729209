import { CustomFieldsResponses } from 'features/CustomFieldsResponses';
import { PunchupCustomFieldsResponses } from 'features/CustomFieldsResponses/PunchupCustomFieldsResponse';
import { useAppSelector } from 'store/hooks';

const AttendeesListPage = (): JSX.Element => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	if (isPunchup) {
		return <PunchupCustomFieldsResponses isAttendeesList={true} />;
	}
	return <CustomFieldsResponses isAttendeesList={true} />;
};
export default AttendeesListPage;
