import { BackIcon, FieldSet, ScreenTitle } from './CreateEvent.styles';
import { EVENT_MODE_NORMAL, EVENT_MODE_PUNCHUP } from 'utils/constants';

import { CreateEventForm } from './components/CreateEventForm';
import { EventMode } from 'utils/types';
import { MainLayout } from 'layouts/MainLayout';
import { ModalCancel } from './components/ModalCancel';
import { SelectType } from './components/SelectType';
import { Text } from 'features/common/components/Text';
import { useAppSelector } from 'store/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CreateEvent = () => {
	const { t } = useTranslation();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const [eventMode, setEventMode] = useState<EventMode>(EVENT_MODE_NORMAL);

	if (isPunchup) {
		return (
			<MainLayout>
				<CreateEventForm eventMode={EVENT_MODE_PUNCHUP} />
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<ScreenTitle>
				<BackIcon iconName="arrowLeft" onClick={() => window.history.back()} />
				<Text data-cy="title">{t('create-event')}</Text>
				<ModalCancel />
			</ScreenTitle>
			<FieldSet>
				<SelectType eventModeState={{ eventMode, setEventMode }} />
				{eventMode && <CreateEventForm eventMode={eventMode} />}
			</FieldSet>
		</MainLayout>
	);
};
