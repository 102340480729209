import { Text } from 'features/common/components/Text';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import createEvent from 'assets/images/createEventbackground.svg';
import styled from 'styled-components';

export const ContainerNewEvent = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 420px;
	height: 415px;
	background: ${({ theme }) => theme.colors.grey2};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.white};
	border-width: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 340px;
		height: 60px;
		flex-direction: row;
		gap: 9px;
	}
`;

export const ContainerAddEvent = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 423px;
	height: 415px;
	left: 180px;
	top: 963px;
	background-image: url(${createEvent});
	border-radius: 4px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const Icon = styled(UnstyledIcon)`
	width: 35px;
	height: 35px;
`;

export const IconNewEvent = styled(UnstyledIcon)`
	width: 35px;
	height: 35px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 17px;
		height: 17px;
	}
`;

export const Title = styled(Text)`
	font-weight: 600;
	font-size: 22px;
	line-height: 46px;
`;

export const Subtitle = styled(Text)`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
`;
