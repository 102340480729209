import { DayContainer, Title, ToggleContainer, ToggleText, Wrap } from './EventSchedule.styles';
import { EventScheduleProps, EventType } from './types';

import { AddButton } from 'features/EditEvent/components/RightForm/AddButton';
import { DayForm } from './DayForm';
import { DayType } from 'features/Home/components/Events/types';
import { DeleteButton } from 'features/EditEvent/components/RightForm/DeleteButton';
import { Toggle } from 'features/common/components/Toggle';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export const EventSchedule = ({ isEventCreation = false }: EventScheduleProps) => {
	const { setValue, getValues, watch } = useFormContext();
	const { t } = useTranslation();
	const dates = watch('dates');
	const isMultiDay = watch('isMultiDay');

	const onChangeMultiday = () => {
		if (isMultiDay) {
			setValue('dates', [getValues('dates')[0]]);
		}
		setValue('isMultiDay', !isMultiDay);
	};

	const onClickDeleteDay = (event: React.MouseEvent<HTMLButtonElement>, selectedId: number) => {
		event?.preventDefault();
		const cleanCollection = getValues('dates').filter((event: EventType) => event.id !== selectedId);
		setValue('dates', cleanCollection);
	};
	const onClickAddDay = () => {
		const newEvent = {
			start_date: getValues('dates')[0].start_date,
			end_date: getValues('dates')[0].end_date,
			id: uuid(),
		};
		const newCollection = [...getValues('dates'), newEvent];
		setValue('dates', newCollection);
	};

	return (
		<Wrap>
			<Title>{t('editEvent-event-schedule')}</Title>
			<ToggleContainer>
				<ToggleText>{t(`${isEventCreation ? 'createEvent' : 'editEvent'}-multiday-event`)}</ToggleText>
				<Toggle isChecked={isMultiDay} name="Toggle" handleChange={onChangeMultiday} />
			</ToggleContainer>

			{isMultiDay && <AddButton text={t('editEvent-add-day')} onClick={onClickAddDay} />}

			{dates.map(({ id }: DayType, index: number) => (
				<DayContainer key={id}>
					{dates.length > 1 && <DeleteButton onClick={(event) => onClickDeleteDay(event, id)} />}
					<DayForm index={index} />
				</DayContainer>
			))}
		</Wrap>
	);
};
