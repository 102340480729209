import * as Styled from './TicketCreated.styles';

import { ButtonContainer, NextButton, SkipButton, StepTitle } from '../Steps.styles';
import { generatePath, useHistory, useParams } from 'react-router';

import { EventIdParamsType } from 'utils/types';
import { EventTemplateProps } from '../../../../common/components/Templates/types';
import { Page } from '../..';
import { ROUTES } from 'features/routes';
import { TicketDisplay } from './TicketDisplay';
import { camelizeKeys } from 'humps';
import { convertUTCToDate } from 'utils/utils';
import { handleLink } from 'utils/handleLink';
import moment from 'moment';
import { queryClient } from 'index';
import { useAppSelector } from 'store/hooks';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TicketCreated = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { getValues, reset } = useFormContext();
	const { eventId }: EventIdParamsType = useParams();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const queryKey: string[] = ['event', eventId];
	const event = camelizeKeys(queryClient.getQueryData(queryKey)) as unknown as EventTemplateProps;

	const selectAnother = () => {
		reset();
		handleLink('#2');
	};

	const skip = () => {
		if (isPunchup) {
			push(generatePath(ROUTES.CREATE_EVENT_PATH, { id: eventId }));
		} else {
			handleLink('#9');
		}
	};

	return (
		<Page id="8">
			<Styled.CenteredContainer>
				<Styled.Container>
					<StepTitle element="h3">{t('ticketDetails-ticket-created')}</StepTitle>
					<TicketDisplay />
					<Styled.Name>{getValues('name')}</Styled.Name>
					<Styled.Date>
						{moment(convertUTCToDate(`${event?.startDate}`, event?.timeZone)).format('MM/DD/YYYY')}
					</Styled.Date>
				</Styled.Container>
				<ButtonContainer>
					<SkipButton type="button" onClick={skip}>
						{t('createEvent-skip')}
					</SkipButton>
					<NextButton onClick={selectAnother} type="button">
						{t('ticketDetails-ticket-add')}
					</NextButton>
				</ButtonContainer>
			</Styled.CenteredContainer>
		</Page>
	);
};
