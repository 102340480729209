import { Error, FormContent } from './Form.styles';

import { AddressAutocomplete } from './AddressAutocomplete';
import { FileInputButton } from './FileInputButton';
import { FormButton } from './Button';
import { FormInput } from './Input';
import { FormInputDate } from './InputDate';
import { FormInputDateAndTime } from './InputDateAndTime';
import { FormInputText } from './InputText';
import { FormInputTime } from './InputTime';
import { FormProps } from './types';
import { FormProvider } from 'react-hook-form';
import { FormSelect } from './Select';
import { ImageInput } from './ImageInput';
import { InputToggle } from './InputToggle';
import { InputWithIcon } from './InputWithIcon';
import { PunchupFormInputDateAndTime } from './PunchupInputDateAndTime/PunchupInputDateAndTime';
import { PunchupFormSelect } from './PunchupSelect';
import { PunchupInput } from './PunchupInput';
import { PunchupInputToggle } from './PunchupInputToggle/PunchupInputToggle';
import { PunchupTextArea } from './PunchupTextArea';
import { TimezoneSelect as PunchupTimezone } from './PunchupTimeZone';
import { RadioButtonList } from './RadioButtonList';
import { TimezoneSelect } from './TimezoneSelect/TimezoneSelect';

const Form = ({ children, formMethods, onSubmit, ...props }: FormProps) => {
	const {
		formState: { errors },
		handleSubmit,
	} = formMethods;

	return (
		<FormProvider {...formMethods}>
			<FormContent {...props} onSubmit={handleSubmit(onSubmit)}>
				{children}
			</FormContent>
			{errors?.general && <Error>{errors.general.message}</Error>}
		</FormProvider>
	);
};

Form.displayName = 'CustomForm';
Form.Button = FormButton;
Form.Input = FormInput;
Form.InputDate = FormInputDate;
Form.InputDateAndTime = FormInputDateAndTime;
Form.InputTime = FormInputTime;
Form.Select = FormSelect;
Form.ImageInput = ImageInput;
Form.InputWithIcon = InputWithIcon;
Form.AddressAutocomplete = AddressAutocomplete;
Form.InputText = FormInputText;
Form.RadioButtonList = RadioButtonList;
Form.FileInputButton = FileInputButton;
Form.InputToggle = InputToggle;
Form.TimeZone = TimezoneSelect;
Form.PunchupInput = PunchupInput;
Form.PunchupSelect = PunchupFormSelect;
Form.PunchupTimezone = PunchupTimezone;
Form.PunchupTextArea = PunchupTextArea;
Form.PunchupInputDateAndTime = PunchupFormInputDateAndTime;
Form.PunchupToggle = PunchupInputToggle;

export { Form };
