import * as Styled from './TicketDetails.styles';

import { CreateTicketForm, ReqFieldsProps, TicketDetailsParams } from './types';
import {
	NEW_TICKET,
	createBody,
	defaultValues,
	fillForm,
	getTicketTypeCanGoLive,
	showReqFieldsModal,
	validationSchema,
} from './utils';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Footer } from 'features/common/components/Footer';
import { Form } from 'features/common/components/Form';
import { LeftForm } from './components/LeftForm';
import { MainLayout } from 'layouts/MainLayout';
import { OUTLINED } from 'features/common/components/Button/constants';
import { RequiredFieldsModal } from 'features/Embed/components/RequiredFields/RequieredFieldsModal';
import { RightForm } from './components/RightForm';
import { StateProps } from 'utils/types';
import { TicketTitle } from './components/TicketTitle';
import { getBase64 } from 'utils/utils';
import { useForm } from 'react-hook-form';
import useGetEvent from 'features/common/hooks/useGetEvent';
import { useTicketTypeApi } from './hooks';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

const TicketDetails: React.FC = () => {
	const { goBack } = useHistory();
	const { t } = useTranslation();
	const { id: eventId } = useParams<TicketDetailsParams>();
	const location = useLocation();
	const { event } = useGetEvent();
	const { ticket, canGoLive, mutateUploadImage, mutateUpdateTicket, mutateCreateTicket } = useTicketTypeApi();

	const [showReqFields, setShowReqFields] = useState(false);
	const [reqFieldsProps, setReqFieldsProps] = useState<ReqFieldsProps>();

	const isNewTicket = location.pathname.includes(NEW_TICKET);

	const { state: { isFromRequiredFields } = { isFromRequiredFields: false } }: StateProps = useLocation();

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
	});

	useEffect(() => {
		if (event && ticket) {
			formMethods.reset(fillForm(ticket, event.timeZone));
		}
	}, [ticket, event, canGoLive]);

	const onSubmitTicketForm = async (currentValues: CreateTicketForm) => {
		if (event && canGoLive && showReqFieldsModal(event, canGoLive, currentValues.initialPrice || 0)) {
			setReqFieldsProps({
				eventGoLive: getTicketTypeCanGoLive(canGoLive, ticket?.ID),
				eventId: Number(eventId),
				type: 'ticket',
				closeModal: () => setShowReqFields(false),
			});

			setShowReqFields(true);
			return;
		}

		const ticketFile = currentValues.ticketImage;

		try {
			if (ticketFile instanceof Blob) {
				const base64TicketImage = await getBase64(ticketFile);
				if (base64TicketImage) {
					const ticketImage = {
						url: '',
						base64: base64TicketImage.toString(),
						name: `${eventId}-${currentValues.name}`,
					};

					const {
						data: { image_url },
					} = await mutateUploadImage({
						image: ticketImage,
						prefix: ticketImage.name,
						index: 0,
						type: 'template',
					});

					formMethods.setValue('image', image_url);
				}
			}

			const newTicketBody = createBody(formMethods.getValues(), Number(eventId), event?.timeZone || '', ticket?.ID);
			if (isNewTicket) {
				mutateCreateTicket(newTicketBody);
			} else {
				mutateUpdateTicket(newTicketBody);
			}
		} catch (error) {
			throw new Error(`Error ${error}`);
		}
	};

	return (
		<Form formMethods={formMethods} onSubmit={onSubmitTicketForm}>
			<MainLayout>
				<TicketTitle isNewTicket={isNewTicket} />
				<Styled.ContentWrapper>
					<Styled.LeftContainer>
						<LeftForm />
					</Styled.LeftContainer>
					<Styled.RightContainer>
						<RightForm isFromRequiredFields={isFromRequiredFields} ticket={ticket} eventId={eventId} />
					</Styled.RightContainer>
				</Styled.ContentWrapper>

				<Footer>
					<Footer.Button type="button" onClick={goBack} category={OUTLINED} text={t('ticketDetails-cancel')} />
					<Footer.Button
						text={t(`ticketDetails-${isNewTicket ? 'create' : 'save'}`)}
						type="submit"
						data-cy="save-ticket"
					/>
				</Footer>
			</MainLayout>

			{showReqFields && reqFieldsProps && <RequiredFieldsModal {...reqFieldsProps} />}
		</Form>
	);
};

export { TicketDetails };
