import * as Styled from '../AnalyticByEvent.styles';

import { PunchupNetSales } from 'features/Dashboard/components/PunchupNetSales';
import { useTranslation } from 'react-i18next';

const PunchupNetSalesSection: React.FC = () => {
	const { t } = useTranslation();
	return (
		<Styled.AnalyticsContainer isPunchup={true}>
			<PunchupNetSales description={`${t('netSales-sellout-potential-revenue')} $15,000`} />
		</Styled.AnalyticsContainer>
	);
};

export default PunchupNetSalesSection;
