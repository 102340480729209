import { LocalFilter as UnstyledFilter } from 'features/common/components/Filters/LocalFilter';
import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	padding: 15px;
`;

export const FiltersContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 12px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 20px;
	}
`;

export const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const Buttons = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const SendTicketsButton = styled.button`
	display: flex;
	gap: 20px;
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.grey20};
	color: ${({ theme }) => theme.colors.grey20};
	font-family: ${({ theme }) => theme.fonts.primary};
	text-decoration: auto;
	background: transparent;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	height: 38px;
	width: 150px;
	display: flex;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	align-items: center;
	justify-content: center;

	&:hover {
		background: rgba(232, 99, 57, 0.05);
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const Filter = styled(UnstyledFilter)``;
