import { PunchupEditEventForm } from './PunchupEditEventForm';
import { useEditEventApi } from './hooks';

const PunchupEditEvent = () => {
	const { event, editEvent } = useEditEventApi();

	if (!event) {
		return null;
	}

	return <PunchupEditEventForm event={event} editEvent={editEvent} />;
};

export { PunchupEditEvent };
