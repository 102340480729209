import { Button, Container, DynamicFlex } from './AffiliateView.styles';
import { EVENT_MODE_NORMAL, EVENT_MODE_REGISTRATION } from 'utils/constants';
import { EventType, IdParamsType } from 'utils/types';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Affiliate } from './Affiliate';
import { CustomIcon } from 'features/AffiliateCreate/styles';
import { Header } from 'features/common/components/Header';
import { MainLayout } from 'layouts/MainLayout';
import { OUTLINED } from 'features/common/components/Button/constants';
import { ROUTES } from 'features/routes';
import { Search } from 'features/common/components/Search';
import { common } from 'theme';
import { debounce } from 'utils/utils';
import { getData } from 'services/api';
import { linkGreyBackground } from 'assets/images';
import { useGetAffiliates } from 'hooks/useGetAffiliates/useGetAffiliates';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AffiliateView = () => {
	const { t } = useTranslation();
	const { id: eventId }: IdParamsType = useParams();
	const { push } = useHistory();

	const [search, setSearch] = useState('');
	const searchDebounce = debounce(setSearch);
	const queryKey = ['affiliates', eventId, search];

	const { data: event } = useQuery<EventType>({
		queryKey: ['event', eventId],
		queryFn: () => getData(`/public/ticketing/events/${eventId}`),
		enabled: !!eventId,
	});
	const { affiliates } = useGetAffiliates({ queryKey, eventId, perPage: 10, search });

	const mode = event?.mode !== EVENT_MODE_REGISTRATION ? EVENT_MODE_NORMAL : EVENT_MODE_REGISTRATION;

	return (
		<MainLayout color={common.colors.grey4}>
			<Header.TitledHeader
				title={event?.name || ''}
				subtitle={t('dashboard-affiliateCreate-subtitle')}
				CustomIcon={() => <CustomIcon src={linkGreyBackground} />}
			/>
			<Container>
				<DynamicFlex>
					<Search onChange={(e) => searchDebounce(e.target.value)} placeholder={t('dashboard-affiliateView-search')} />
					<Button
						text={t('button-new-link')}
						category={OUTLINED}
						onClick={() => push(generatePath(ROUTES.DASHBOARD_NEW_AFFILIATE, { id: eventId }))}
					/>
				</DynamicFlex>
				{affiliates.data?.pages.map((page) =>
					page.data.map((affiliate) => {
						return <Affiliate key={affiliate.id} eventId={eventId} data={affiliate} queryKey={queryKey} mode={mode} />;
					})
				)}
			</Container>

			{/* {affiliates.hasNextPage && (
				<LoadMore handleClick={() => affiliates.fetchNextPage()} isDisabled={!affiliates.hasNextPage} />
			)} */}
		</MainLayout>
	);
};
