import * as Styled from './PunchupSendTicketsForm.styles';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { PunchupRecipientInfoForm } from './components/PunchupRecipientInfoForm';
import { PunchupSendTicketsTitleSection } from './components/PunchupSendTicketsTitleSection';
import { RecipientProps } from 'features/SendTickets/types';
import { SendTicketsFormProps } from './types';
import { initialRecipient } from 'features/SendTickets/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const PunchupSendTicketsForm = ({ event }: SendTicketsFormProps) => {
	const { t } = useTranslation();

	const { getValues, setValue, watch } = useFormContext();
	const recipientsArray: RecipientProps[] = watch('recipients');

	const handleAddRecipient = () => {
		const currentValues = getValues('recipients');
		setValue('recipients', [...currentValues, initialRecipient]);
	};

	const handleRemoveRecipient = (id: number) => {
		const currentValues = getValues('recipients');
		currentValues.splice(id, 1);
		setValue('recipients', [...currentValues]);
	};

	if (!event) return null;

	return (
		<Styled.Root>
			<PunchupSendTicketsTitleSection title={event.name} days={event.days} streetAddress={event.streetAddress} />

			<Styled.Subtitle>{t('send-tickets-recipient-info')}</Styled.Subtitle>

			{recipientsArray?.map((recipient, i) => (
				<PunchupRecipientInfoForm
					key={i}
					id={i}
					{...recipient}
					ticketTypes={event.ticketTypes}
					onRemove={handleRemoveRecipient}
				/>
			))}

			<FlexContainer justifyContent="center">
				<PunchupButton onClick={handleAddRecipient} text={t('send-tickets-add-recipient')} />
			</FlexContainer>
		</Styled.Root>
	);
};
