import styled from 'styled-components';

export const CardsWrapper = styled.div`
	display: inline-flex;
	gap: 20px;

	::-webkit-scrollbar {
		display: none;
	}
`;
