import { Container, CustomPlus, Text } from './EmptySection.styles';

import { EmptySectionProps } from './types';
import { useAppSelector } from 'store/hooks';

export const EmptySection = ({ onClick, text }: EmptySectionProps) => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	return (
		<Container onClick={onClick} isPunchup={isPunchup}>
			<CustomPlus iconName="plus" color={isPunchup ? 'brightness(0)' : ''} />
			<Text isPunchup={isPunchup}>{text}</Text>
		</Container>
	);
};
