import { Label, StyledForm } from './PromoCodesForm.styles';
import { defaultValues, handleInitialValues, validationSchema } from '../../PromoCodesForm/utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Column } from 'features/PromoCodesForm/components/SpecificPromocodes/SpecificPromocodes.styles';
import { DefaultValuesTypes } from '../../PromoCodesForm/types';
import { DiscountToggle } from 'features/common/components/Punchup/DiscountToggle';
import { EventPromoCodeFormParams } from './types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { MainLayout } from 'layouts/MainLayout';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { PunchupDateTimePicker } from 'features/common/components/Form/PunchupDateTimePicker/PunchupDateTimePicker';
import { PunchupInput } from 'features/common/components/Form/PunchupInput';
import { PunchupToggle } from 'features/common/components/Form/PunchupToggle/PunchupToggle';
import { TicketTag } from 'features/PromoCodesForm/components/SpecificPromocodes/TicketTag';
import { getEvent } from 'features/PunchupSendTickets/services';
import { useEditPromoCodeApi } from 'hooks/useEditPromoCode';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

const PromoCodesForm: React.FC = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { pathname } = useLocation();
	const { eventId } = useParams<EventPromoCodeFormParams>();
	const { promocode, mutateCreatePromoCode, mutateEditPromoCode } = useEditPromoCodeApi();

	const { data: event } = useQuery({
		queryKey: ['event', eventId],
		queryFn: () => getEvent(eventId),
		enabled: !!eventId,
	});

	const isEdit = pathname.includes('edit');

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema()),
	});

	const { watch, setValue } = formMethods;

	const isEndDate = watch('isEndDate');

	useMemo(() => {
		if (promocode) {
			const allValues = handleInitialValues(promocode);
			formMethods.reset(allValues);
		}
	}, [promocode]);

	const onSubmit = (values: DefaultValuesTypes) => {
		if (isEdit) {
			mutateEditPromoCode({ values, type: 'ticket_type', id: promocode.id });
		} else {
			mutateCreatePromoCode({ values, type: 'ticket_type' });
		}
	};

	const onChangeDiscount = () => {
		setValue('cuponType', watch('cuponType') === 'percentage' ? 'flat' : 'percentage');
	};

	return (
		<MainLayout>
			<StyledForm formMethods={formMethods} onSubmit={onSubmit}>
				<PunchupInput
					id="promocodeName"
					name="promocodeName"
					label={t('promoCode-Form-input-name')}
					title={t('promoCode-Form-input-name')}
					placeholder={t('promoCode-Form-input-name')}
				/>

				<FlexContainer gap="10px">
					<FlexContainer flex="1">
						<PunchupInput
							id="discountPercentage"
							name="discountPercentage"
							label={t('promoCode-Form-input-discount')}
							title={t('promoCode-Form-input-discount')}
							placeholder={t('promoCode-Form-input-discount')}
						/>
					</FlexContainer>

					<DiscountToggle
						label="Type"
						isPercentage={watch('cuponType') === 'percentage'}
						handleChange={onChangeDiscount}
						name="toggle"
					/>
				</FlexContainer>

				<PunchupDateTimePicker
					id="startDate"
					name="startDate[0].start_date"
					label={t('ticketDetails-ticket-active-date')}
				/>

				<FlexContainer flexDirection="column" alignItems="stretch" gap="10px">
					<FlexContainer gap="10px" alignItems="center">
						<Label variant="primary">{t('ticketDetails-ticket-stop-date')}</Label>
						<FlexContainer>
							<PunchupToggle htmlFor="isEndDate" name="isEndDate" />
						</FlexContainer>
					</FlexContainer>

					{isEndDate && <PunchupDateTimePicker id="endDate" name="endDate[0].end_date" />}
				</FlexContainer>

				<FlexContainer flexDirection="column" gap="10px" marginTop="16px">
					<Label variant="primary">{t('promoCode-Form-unlimited-ticketsQuantity-title')}</Label>

					{event?.ticketTypes?.map((ticketType, index, tickets) => (
						<Column key={ticketType.ticketTypeId}>
							<TicketTag
								title={ticketType.type}
								isLastElement={index === tickets.length - 1}
								id={ticketType.ticketTypeId}
								unlimitedAll={false}
							/>
						</Column>
					))}
				</FlexContainer>

				<FlexContainer gap="10px" justifyContent="center">
					<PunchupButton text="Cancel" variant="secondary" type="button" onClick={() => goBack()} />
					<PunchupButton text="Save" type="submit" />
				</FlexContainer>
			</StyledForm>
		</MainLayout>
	);
};

export { PromoCodesForm };
