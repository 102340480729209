import { ButtonContainer, PunchupButtonContainer, SubmitButton } from './EventSummary.styles';

import { AdvancedDetails } from './components/AdvancedDetails';
import { EVENT_MODE_PUNCHUP } from 'utils/constants';
import { EventModeProp } from '../types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { KeyDetails } from './components/KeyDetails';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { useFormContext } from 'react-hook-form';
import { useResponsiveScreen } from 'hooks/useWindowSize';

export const EventSummary = ({ eventMode }: EventModeProp) => {
	const isLargeScreen = useResponsiveScreen();
	const methods = useFormContext();

	const handleCancel = () => {
		methods.reset();

		if (eventMode === EVENT_MODE_PUNCHUP) {
			window.parent.postMessage({ action: 'close-iframe' }, '*');
		}
	};

	if (!eventMode) {
		return null;
	}

	return (
		<FlexContainer flexDirection="column" gap="24px" width="100%" maxWidth="800px" margin="0 auto">
			<KeyDetails eventMode={eventMode} />
			{eventMode !== EVENT_MODE_PUNCHUP && <AdvancedDetails eventMode={eventMode} />}
			{eventMode === EVENT_MODE_PUNCHUP ? (
				<PunchupButtonContainer>
					<PunchupButton type="button" variant="secondary" onClick={handleCancel} text="Cancel" />
					<PunchupButton type="submit" variant="primary" text="Create" />
				</PunchupButtonContainer>
			) : (
				<ButtonContainer isMobile={isLargeScreen}>
					<SubmitButton text="Continue" type="submit" />
				</ButtonContainer>
			)}
		</FlexContainer>
	);
};
