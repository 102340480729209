import { Divider, List, ListItem, TotalContainer } from './RefundBreakdown.styles';

import { CustomText } from '../SummaryContainer.styles';
import { EVENT_MODE_REGISTRATION } from 'utils/constants';
import { FC } from 'react';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { RefundBreakdownProps } from './types';
import { formatNum } from 'features/RefundTicketsSummary/utils';
import { useTranslation } from 'react-i18next';

export const RefundBreakdown: FC<RefundBreakdownProps> = ({
	ticketData,
	refundTotal,
	purchaseTotal,
	refundPercent,
}) => {
	const { t } = useTranslation();

	return (
		<FlexContainer gap="24px" flexDirection="column">
			<FlexContainer gap="16px" flexDirection="column" padding="0 0 0 16px">
				<List>
					{ticketData.map(({ ticket_id, ticket_type, price_paid }) => (
						<ListItem key={ticket_id}>
							<CustomText>{ticket_type}</CustomText>
							{price_paid !== '0.00' && price_paid !== EVENT_MODE_REGISTRATION && (
								<CustomText>{`$${price_paid}`}</CustomText>
							)}
						</ListItem>
					))}
				</List>
				<Divider />
				{!purchaseTotal.isZero() && (
					<>
						<FlexContainer justifyContent="space-between">
							<CustomText isBold>{t('refundTicketsSummary-refundBreakdown-subtotal')}</CustomText>
							<CustomText isBold>{`$${formatNum(purchaseTotal)}`}</CustomText>
						</FlexContainer>
						<FlexContainer justifyContent="space-between">
							<CustomText color="red" isBold>
								{t('refundTicketsSummary-refundBreakdown-refund-applied')}
							</CustomText>
							<CustomText color="red" isBold>{`-${refundPercent}`}</CustomText>
						</FlexContainer>
					</>
				)}
			</FlexContainer>
			{!purchaseTotal.isZero() && (
				<>
					<TotalContainer>
						<CustomText isBold> {t('refundTicketsSummary-refundBreakdown-total')}</CustomText>
						<CustomText isBold> {`$${formatNum(refundTotal)}`}</CustomText>
					</TotalContainer>
				</>
			)}
		</FlexContainer>
	);
};
