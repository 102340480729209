import { hideScrollbar } from 'styles';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
	width: 100vw;
	display: grid;
	grid-template-columns: repeat(2, 0fr);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		overflow-y: scroll;
		flex-direction: column;
	}
`;

export const RightContainer = styled.div`
	height: calc(100vh - 266px);
	max-height: calc(100vh - 266px);
	background: ${({ theme }) => theme.colors.grey4};
	padding: 55px 85px 55px 85px;
	box-sizing: border-box;
	overflow-y: scroll;
	width: 50vw;
	max-width: 50vw;

	${hideScrollbar}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: 30px 40px 120px 40px;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100vw;
		max-width: 100vw;
		overflow-y: unset;
		height: fit-content;
		max-height: fit-content;
		padding: 30px 20px 120px 20px;
	}
`;

export const LeftContainer = styled(RightContainer)`
	background: ${({ theme }) => theme.colors.grey2};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 30px 20px 30px 20px;
		background: ${({ theme }) => theme.colors.grey4};
	}
`;
