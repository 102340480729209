import * as Styled from './PunchupTicketCard.styles';

import { AddTicketCardProps } from './types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PunchupAddTicketCard: FC<AddTicketCardProps> = ({ onClick }: AddTicketCardProps) => {
	const { t } = useTranslation();

	return (
		<Styled.AddCardContainer onClick={onClick} data-cy="add-ticket-card">
			<Styled.AddCardContent>
				<Styled.AddIcon iconName="plus" color="brightness(0)" />
				<Styled.AddText>{t('ticketDetails-ticket-add-new')}</Styled.AddText>
			</Styled.AddCardContent>
		</Styled.AddCardContainer>
	);
};
