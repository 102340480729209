import { ButtonInfo, TicketOrder, TicketTypeProps } from './types';
import { FC, useEffect, useState } from 'react';
import { PRIMARY, PUNCHUP_QUATERNARY } from 'features/common/components/Button/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory, useParams } from 'react-router-dom';

import { Divider } from 'features/common/components/Divider';
import { IdParamsType } from 'utils/types';
import { PunchupAddTicketCard } from './PunchupTicketCard/PunchupAddTicketCard';
import { PunchupMultiButtonSectionTitle } from 'features/common/components/PunchupMultiButtonSectionTitle';
import { PunchupTicketTypesList } from './PunchupTicketTypesList/PunchupTicketTypesList';
import { PunchupTicketsContainer } from './PunchupTicketsSection.styles';
import { ROUTES } from 'features/routes';
import { TYPES } from 'hooks/useToastNotification/constants';
import { reorderTicketTypesPut } from './services';
import { setTicketsAvailable } from 'store/slices/BoxOfficeSlice';
import { sortTicketTypes } from './utils';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTicketTypesApi } from './hooks';
import { useToastNotification } from 'hooks/useToastNotification';
import { useTranslation } from 'react-i18next';

const PunchupTicketsSection: FC = () => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const dispatch = useAppDispatch();
	const { id } = useParams<IdParamsType>();
	const isLargeScreen = useResponsiveScreen();
	const { showNotification } = useToastNotification();
	const { isPunchup } = useAppSelector((state) => state.auth);
	const { ticketTypes: ticketTypesData } = useTicketTypesApi();

	const [ticketTypes, setTicketTypes] = useState<TicketTypeProps[]>([]);
	const [ticketsOrder, setTicketsOrder] = useState<TicketOrder[]>([]);
	const [showSaveButtons, setShowSaveButtons] = useState(false);

	useEffect(() => {
		if (ticketTypesData) {
			setTicketTypes(sortTicketTypes(ticketTypesData));
			setTicketsOrder(ticketTypesData.map(({ iD, orderIndex }) => ({ ID: iD, orderIndex })));
			dispatch(setTicketsAvailable(ticketTypesData.length > 0));
		}
	}, [JSON.stringify(ticketTypesData)]);

	const onClickAddNew = () => push(ROUTES.TICKET_NEW_PATH.replace(':id', id));

	const onSave = async () => {
		try {
			await reorderTicketTypesPut(ticketTypes);
			setShowSaveButtons(false);
			setTicketsOrder(ticketTypes.map(({ iD, orderIndex }) => ({ ID: iD, orderIndex })));
			showNotification({ message: t('dashboard-ticketTypes-reorder-success'), type: TYPES.SUCCESS });
		} catch (error) {
			showNotification({ message: t('dashboard-ticketTypes-reorder-error'), type: TYPES.ERROR });
		}
	};

	const onRevert = () => {
		const newTicketTypes = ticketTypes.map((tt) => ({
			...tt,
			orderIndex: ticketsOrder.find(({ ID }) => ID === tt.iD)?.orderIndex ?? tt.orderIndex,
		}));

		setTicketTypes(sortTicketTypes(newTicketTypes));
		setShowSaveButtons(false);
	};

	const buttons: ButtonInfo[] = [
		{
			label: t('dashboard-reorder'),
			onClick: () => setShowSaveButtons(true),
			dataCy: 'tickets-reorder-button',
		},
		{ label: t('dashboard-add'), onClick: onClickAddNew, dataCy: 'add-ticket' },
	];

	const saveButtons: ButtonInfo[] = [
		{ label: t('dashboard-ticketTypes-reorder-revert'), onClick: onRevert },
		{
			label: t('dashboard-ticketTypes-reorder-save'),
			onClick: onSave,
			category: isPunchup ? PUNCHUP_QUATERNARY : PRIMARY,
		},
	];

	return (
		<>
			<PunchupMultiButtonSectionTitle
				title={t('dashboard-tickets')}
				buttons={showSaveButtons ? saveButtons : buttons}
			/>

			<PunchupTicketsContainer data-cy="tickets-section">
				{ticketTypes?.length > 0 ? (
					<PunchupTicketTypesList
						ticketTypes={ticketTypes}
						setTicketTypes={setTicketTypes}
						showSaveButtons={showSaveButtons}
					/>
				) : (
					<PunchupAddTicketCard onClick={onClickAddNew} />
				)}
			</PunchupTicketsContainer>

			{!isLargeScreen && <Divider />}
		</>
	);
};

export { PunchupTicketsSection };
