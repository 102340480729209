import { hideScrollbar } from 'styles';
import styled from 'styled-components';

export const HighlightsContainer = styled.div`
	gap: 20px;
	display: flex;
	display: flex;
	width: fit-content;

	${hideScrollbar}

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: auto;
	}
`;

export const CollectionContainer = styled.div`
	display: flex;
	gap: 24px;
	flex-wrap: wrap;
	justify-content: center;
`;
