import { Table } from 'features/common/components/Table';
import { Button as UnstyledButton } from 'features/common/components/Button';
import styled from 'styled-components';

export interface RowProps {
	padding?: string;
	height?: string;
	gridColumns: string;
	textAlign?: string;
}

export const Row = styled.tr<RowProps>`
	display: grid;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	padding: ${({ padding }) => padding || '0'};
	height: ${({ height }) => height || '48px'};
	text-align: ${({ textAlign }) => textAlign || 'left'};
	align-items: center;
`;

export const ColumnHeader = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-weight: 500;
	font-size: 14px;
	border: none;
`;

export const DataRow = styled(Row)`
	&:nth-child(odd) {
		background-color: ${({ theme }) => theme.colors.whitePearl};
	}
`;

export const Cell = styled(Table.Cell)`
	padding: 16px;
	font-size: 14px;
	border: none;
	color: ${({ theme }) => theme.colors.grey8};
`;

export const Root = styled.div`
	width: 100%;
	border: none;
	overflow-x: auto;

	table {
		border-collapse: separate;
		border-spacing: 0 8px;
		width: 100%;
	}

	tbody {
		tr {
			margin: 8px 0;
		}
	}
`;

export const Button = styled(UnstyledButton)`
	background: transparent;
	border: 1.5px solid ${({ theme }) => theme.colors.grey6};
	color: ${({ theme }) => theme.colors.grey8};
	padding: 8px 32px;
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	transition: all 0.2s ease;
	width: fit-content;

	&:hover {
		background: ${({ theme }) => theme.colors.grey15};
		border-color: ${({ theme }) => theme.colors.grey8};
	}
`;

export const AcceptedDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.grey8};
	font-size: 14px;
`;
