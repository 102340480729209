import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const OrganizationCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ImageBg = styled.div`
	background-color: ${({ theme }) => theme.colors.grey5};
	padding: 32px;
	border-radius: 4px;
	cursor: pointer;
	object-fit: cover;
`;

export const Img = styled.img`
	width: 100px;
	height: 100px;
	object-fit: contain;
`;

export const OrgName = styled(Text)`
	margin-top: 9px;
	font-size: 16px;
	line-height: 21px;
`;
