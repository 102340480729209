import { Form } from '../Form';
import moment from 'moment-timezone';
import { timezones } from './utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TimezoneSelect = () => {
	const { t } = useTranslation();
	const options = timezones(moment(), t);

	const { setValue } = useFormContext();

	useEffect(() => {
		const currentTZ = options.find((option) => option.time === moment().format('hh:mm A'));
		if (currentTZ) {
			setValue('timezone', currentTZ.value);
		}
	}, []);

	return (
		<Form.PunchupSelect
			id="timezone"
			name="timezone"
			label={t('createEvent-eventSummary-timezone')}
			options={options}
			withDefaultValue
		/>
	);
};
