import { FilterButtonEnum, useFilterButtonClick } from 'features/common/hooks/useFilterButtonClick';
import { OUTLINED, PRIMARY } from 'features/common/components/Button/constants';

import { CustomFilterOption } from './DateDropdown.styles';
import { DateDropdownProps } from '../../types';
import { DateFilterEnumPage } from 'features/common/hooks/useFilterButtonClick';
import { FC } from 'react';
import { FilterButton } from 'features/common/components/Filters/RTKFilter.styles';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OptionsContainer } from '../../DesktopFilters.styles';
import { lastIndicator } from 'features/common/components/Filters/utils';
import { useTranslation } from 'react-i18next';

export const DateDropdown: FC<DateDropdownProps> = ({ isOpened, closeFilter, selectedPeriod, filterOption, page }) => {
	const { t } = useTranslation();

	const cancelClick = useFilterButtonClick(closeFilter, FilterButtonEnum['dateCancel'], page);
	const applyClick = useFilterButtonClick(closeFilter, FilterButtonEnum['dateApply'], page);
	return (
		<OptionsContainer isOpened={isOpened}>
			<FlexContainer gap="16px" flexDirection="column" margin="16px">
				<div>
					{filterOption.map((option, i, array) => (
						<CustomFilterOption
							key={option}
							option={option}
							className={lastIndicator<DateFilterEnumPage>(i, array)}
							selectedPeriod={selectedPeriod}
							page={page}
						/>
					))}
				</div>
				<FlexContainer gap="16px" justifyContent="center">
					<FilterButton text={t('fanDatabase-cancel')} category={OUTLINED} onClick={cancelClick} type="button" />
					<FilterButton text={t('fanDatabase-apply')} category={PRIMARY} onClick={applyClick} type="button" />
				</FlexContainer>
			</FlexContainer>
		</OptionsContainer>
	);
};
