import { Amount, Container, ResponsiveContainer, Text } from './TicketsSold.styles';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { FC, memo } from 'react';

import { CustomTooltip } from './CustomTooltip';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { PunchupAdjustTimeline } from '../PunchupAdjustTimeline';
import { TICKETS_SOLD } from 'hooks/useChart/utils';
import { customXTick } from 'utils/utils';
import { getColor } from '../utils';
import { useChart } from 'hooks/useChart/useChart';
import { useTranslation } from 'react-i18next';

const PunchupTicketsSold: FC = () => {
	const { t } = useTranslation();
	const { ticketsSold, dataKeys, ticketTypeDataFrame, setDisplay, displayWindow } = useChart(TICKETS_SOLD);

	return (
		<>
			<Container data-cy="tickets-sold-graph">
				<Text>
					{t('ticketsSold')}
					<Amount>{ticketsSold}</Amount>
				</Text>
				<PunchupAdjustTimeline setDisplay={setDisplay} displayWindow={displayWindow} />
			</Container>
			<FlexContainer width="100%" height="300px">
				<ResponsiveContainer>
					<BarChart
						width={500}
						height={300}
						data={ticketTypeDataFrame}
						margin={{
							top: 5,
							right: 30,
							left: 1,
							bottom: 5,
						}}
					>
						<CartesianGrid vertical={false} horizontal={false} />
						<XAxis dataKey="date" tickFormatter={customXTick} />
						<YAxis minTickGap={1} allowDecimals={false} />
						{ticketTypeDataFrame && (
							// eslint-disable-next-line react/prop-types
							<Tooltip content={(props) => <CustomTooltip key={props.label} {...props} data={ticketTypeDataFrame} />} />
						)}
						<Legend />
						{dataKeys?.map((key: string, i) => (
							<Bar dataKey={key} key={key} fill={getColor(i)} barSize={1000} />
						))}
					</BarChart>
				</ResponsiveContainer>
			</FlexContainer>
		</>
	);
};

const memorizedTicketsSold: FC = memo(PunchupTicketsSold);

export { memorizedTicketsSold as PunchupTicketsSold };
