import styled from 'styled-components';

export const Page = styled.div`
	width: 100%;
	display: flex;
	padding: 1rem;
	flex-shrink: 0;
	overflow: hidden;
	box-sizing: border-box;
	justify-content: center;
	scroll-snap-align: start;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 100%;

		&.no-padding {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
`;

export const Carousel = styled.div`
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
`;
