import { ColumnFlexProps, TextProps } from '../../types';
import { LOGO_CONTAINER_DIAMETER, LOGO_CONTAINER_DIAMETER_MOBILE } from './constants';

import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const PanelWrapper = styled.div`
	box-sizing: border-box;
	display: flex;
	gap: 24px;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		flex-direction: column;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column-reverse;
		gap: 24px;
	}
`;

//styles below shared by child components

export const Panel = styled.div`
	background-color: ${({ theme }) => theme.colors.blackGrey4};
	display: flex;
	padding: 40px 0;
	border-radius: 16px;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	width: 50%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: unset;
	}
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		padding: 16px;
	}
`;

export const LeftContainer = styled.div`
	border-right: 1px solid ${({ theme }) => theme.colors.darkGrey6};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	flex-basis: 45%;
	padding: 10px 12px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		border-right: unset;
		padding: 24px;
		flex-direction: row;
		padding: 16px;
		width: 100%;
	}
`;

export const LogoContainer = styled.div`
	height: ${LOGO_CONTAINER_DIAMETER}px;
	width: ${LOGO_CONTAINER_DIAMETER}px;
	border-radius: 50%;
	flex-shrink: 0;
	background: ${({ theme }) => theme.colors.greenGradient3};
	position: relative;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: ${LOGO_CONTAINER_DIAMETER_MOBILE}px;
		width: ${LOGO_CONTAINER_DIAMETER_MOBILE}px;
	}
`;

export const ImgContainer = styled.img`
	height: 152px;
	width: 152px;
	border-radius: 50%;
	position: absolute;
	top: 4px;
	left: 4px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		height: 75px;
		width: 75px;
		top: 4px;
		left: 4px;
	}
`;

export const InfoLabel = styled.label`
	margin: 0;
	color: ${({ theme }) => theme.colors.lightGrey11};
	font-weight: 600;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		&.left-panel {
			font-size: 14px;
			line-height: 21px;
		}
	}
`;

export const NameHeader = styled(Text)<TextProps>`
	font-weight: 500;
	max-width: 200px;
	text-align: center;
	font-size: 16px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: unset;
		line-height: 22px;
	}
`;

export const OrgNameButton = styled.div`
	padding: 8px 61px;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.grey3};
`;

export const NameContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		align-items: flex-start;
	}
`;

export const RightContainer = styled.div`
	flex-basis: 55%;
	display: flex;
	justify-content: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100%;
	}
`;

export const CenteredWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	width: 80%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: unset;
		gap: 0;
		width: 100%;
	}
`;

export const ColumnFlex = styled.div<ColumnFlexProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	gap: ${({ rowGap }) => rowGap};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		border-top: 1px solid ${({ theme }) => theme.colors.black};
		padding: 12px 0;
	}
`;

export const RowFlex = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 8px;
`;

export const TextMinContent = styled(Text)<TextProps>`
	width: min-content;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	white-space: nowrap;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 16px;
`;

export const CustomButton = styled(Button)`
	margin: 0;
	padding: 0;
	padding: 6px;
	border-radius: 8px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	max-width: 100px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: unset;
		height: 40px;
	}
	&:disabled {
		opacity: 0.5;
	}
`;

export const ConnectAccountButton = styled(Button)`
	margin: 0;
	padding: 0;
	padding: 8px 20px;
	border-radius: 8px;
	max-width: fit-content;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		max-width: unset;
		height: 40px;
	}
	&:disabled {
		opacity: 0.5;
	}
`;

export const LogoutContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;
	margin-top: 12px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 10px;
	}
`;

export const LogoutIcon = styled(Icon)`
	width: 19px;
	height: 19px;
`;

export const LogoutText = styled(Text)`
	font-size: 14px;
	line-height: 21px;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.lightGrey9};
`;
