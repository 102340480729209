import {
	Button,
	CreateAccountButton,
	ErrorText,
	FormContainer,
	GradientContainer,
	Line,
	Link,
	Title,
} from './LoginForm.styles';

import { AUTH } from 'features/common/components/Form/Input/constants';
import FlexContainer from 'features/common/components/FlexContainer/FlexContainer';
import { Form } from 'features/common/components/Form';
import { GoogleButton } from '../GoogleButton';
import { LoginFormProps } from './types';
import { ROUTES } from 'features/routes';
import { SECONDARY } from 'features/common/components/Button/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const LoginForm: React.FC<LoginFormProps> = ({ errors }: LoginFormProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();

	const { wrongEmail, wrongPassword, unknownError } = errors;

	return (
		<FormContainer>
			<Title>{t('login-title')}</Title>

			<Form.Input id="email" name="email" label="Email" category={AUTH} data-cy="email" required />

			<Form.Input
				id="password"
				name="password"
				label="Password"
				category={AUTH}
				type="password"
				data-cy="password"
				required
			/>

			{(wrongPassword || wrongEmail) && (
				<FlexContainer flexDirection="column">
					<ErrorText>{t(wrongPassword ? 'login-invalidPassword' : 'login-invalidEmail')}</ErrorText>
				</FlexContainer>
			)}

			{unknownError && (
				<FlexContainer flexDirection="column">
					<ErrorText>{t('login-unknownError')}</ErrorText>
				</FlexContainer>
			)}
			<Button text={t('login-logIn')} type="submit" data-cy="login" />
			<GradientContainer>
				<CreateAccountButton
					onClick={() => push(ROUTES.CREATE_ACCOUNT_PATH)}
					text={t('login-createAccount')}
					type="submit"
				/>
			</GradientContainer>
			<Line />
			<GoogleButton />
			<Link text={t('login-forgotPassword')} category={SECONDARY} onClick={() => push(ROUTES.FORGOT_PASSWORD)} />
		</FormContainer>
	);
};
