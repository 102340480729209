import * as Styled from './TicketTypeSelector.styles';

import { BackIcon, CloseIcon } from 'features/CreateEvent/CreateEvent.styles';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { ScreenTitle } from '../Steps.styles';
import { Text } from 'features/common/components/Text';
import { useTranslation } from 'react-i18next';

export const Title = () => {
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const { id } = useParams<IdParamsType>();

	const handleClose = () => {
		push(generatePath(ROUTES.DASHBOARD_PATH, { id }));
	};

	return (
		<ScreenTitle>
			<BackIcon iconName="arrowLeft" onClick={goBack} />
			<Text data-cy="title">{t('ticketDetails-ticket-ticketDesigner')}</Text>
			<Styled.Subtitle>{t('ticketDetails-ticket-attachDesign')}</Styled.Subtitle>

			<CloseIcon iconName="close" onClick={handleClose} />
		</ScreenTitle>
	);
};
