import { Minus, Plus } from 'assets/customizedIcons';

import { IconProps } from 'utils/types';
import { StyledInput } from 'features/BoxOffice/Counter/Counter.styles';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const Label = styled.span`
	font-size: 14px;
	color: #333333;
`;

export const SelectLabel = styled.select`
	height: 40px;
	padding: 4px 8px;
	border: 1px solid #dddddd;
	border-radius: 4px;
	background-color: white;
	color: #333333;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		border-color: #bbbbbb;
	}
`;

export const GreenBox = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	background-color: white;
	border: 1px solid #dddddd;
	border-radius: 4px;
	padding: 4px 8px;
	height: 32px;
`;

export const Input = styled(StyledInput)`
	background-color: transparent;
	color: #333333;
	font-size: 14px;
	font-weight: 400;
	width: 40px;
	text-align: center;
	border: none;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

export const PlusIcon = styled(Plus)<IconProps>`
	height: 16px;
	width: 16px;
	stroke: ${({ disabled }) => (disabled ? '#CCCCCC' : '#333333')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	user-select: none;
`;

export const MinusIcon = styled(Minus)<IconProps>`
	height: 16px;
	width: 16px;
	stroke: ${({ disabled }) => (disabled ? '#CCCCCC' : '#333333')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	user-select: none;
`;
