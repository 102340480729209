import * as Styled from './PunchupEventInfo.styles';

import { CopyPlus, Pencil, QrCode, Trash } from 'lucide-react';
import { FC, memo, useState } from 'react';

import { DropDownList } from 'features/Settings/components/MyProfile/components/TableContainer/components/RoleSelect/RoleSelect.styles';
import { DropdownContainer } from 'features/common/components/DropdownContainer';
import { DuplicateEvent } from '../EventInfo/services';
import { EventInfoProps } from './types';
import { IdParamsType } from 'utils/types';
import { ListItem } from 'features/Settings/components/MyProfile/components/TableContainer/components/RoleSelect/RoleSelect.styles';
import { ROUTES } from 'features/routes';
import { RootState } from 'store';
import { ZERO_TIME } from 'utils/constants';
import { deleteEvent } from 'features/PunchupEditEvent/services';
import { getData } from 'services/api';
import { handleDownload } from 'utils/handleLink';
import { useClickAway } from 'features/common/hooks/useClickAway';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ACTIONS = [
	{
		label: 'Duplicate',
		icon: <CopyPlus />,
		action: 'duplicate',
	},
	{
		label: 'Edit',
		icon: <Pencil />,
		action: 'edit',
	},
	{
		label: 'Delete',
		icon: <Trash />,
		action: 'delete',
	},
	{
		label: 'Download QR',
		icon: <QrCode />,
		action: 'download-qr',
	},
];

const PunchupEventInfo: FC<EventInfoProps> = ({
	address,
	date,
	endTime,
	eventTitle,
	onSaleDate,
	startTime,
}: EventInfoProps) => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const { id: eventId }: IdParamsType = useParams();
	const { isPunchup } = useSelector((state: RootState) => state.auth);

	const [isActionsOpen, setIsActionsOpen] = useState(false);

	const container = useRef(null);
	useClickAway(container, () => setIsActionsOpen(false));

	const { mutate: mutateDeleteEvent } = useMutation(deleteEvent, {
		onSuccess: () => window.parent.postMessage({ action: 'event-deleted', eventId }, '*'),
		onError: () => window.parent.postMessage({ action: 'event-delete-error', eventId }, '*'),
	});

	const { mutate: mutateDuplicateEvent } = useMutation(DuplicateEvent, {
		onSuccess: ({ data }) => window.parent.postMessage({ action: 'event-duplicated', eventId: data.ID }, '*'),
		onError: () => window.parent.postMessage({ action: 'event-duplicate-error', eventId }, '*'),
	});

	const zeroTime = onSaleDate === ZERO_TIME;

	const toggleDropdown = () => setIsActionsOpen((prev) => !prev);
	const onClickEdit = () => push(ROUTES.EDIT_EVENT_PATH.replace(':id', eventId));
	const onClickDelete = () => mutateDeleteEvent({ id: eventId });
	const onClickDuplicate = () => mutateDuplicateEvent({ eventId });
	const onClickDownloadQR = async () => {
		const response = await getData(`${process.env.REACT_APP_API_BASE}public/ticketing/events/${eventId}/qr`, {
			responseType: 'blob',
			headers: { 'Content-Type': 'blob' },
		});
		handleDownload(response, `${eventTitle} QR.png`);
	};

	const handleActionClick = (action: string) => {
		switch (action) {
			case 'duplicate':
				onClickDuplicate();
				break;
			case 'edit':
				onClickEdit();
				break;
			case 'delete':
				onClickDelete();
				break;
			case 'download-qr':
				onClickDownloadQR();
				break;
		}
	};

	return (
		<Styled.Container>
			<Styled.InnerContainer>
				<Styled.InfoContainer>
					<Styled.Title>{eventTitle}</Styled.Title>
					<Styled.Text>
						{date} | {startTime} - {endTime}
					</Styled.Text>
					<Styled.Text>{address}</Styled.Text>

					{!zeroTime && <Styled.Text>{`${t('generic-onSale')} ${onSaleDate}`}</Styled.Text>}

					<Styled.ActionButtonsContainer>
						<DropdownContainer
							refProp={container}
							isOpened={isActionsOpen}
							clickHandler={toggleDropdown}
							filterName="Actions"
							width="fit-content"
						>
							{isActionsOpen && (
								<DropDownList isPunchup={isPunchup}>
									{ACTIONS.map((action, i) => (
										<ListItem
											key={action.action}
											className={i === ACTIONS.length - 1 ? 'last' : ''}
											onClick={() => handleActionClick(action.action)}
											isPunchup={isPunchup}
										>
											{action.icon}
											<Styled.Text>{action.label}</Styled.Text>
										</ListItem>
									))}
								</DropDownList>
							)}
						</DropdownContainer>
					</Styled.ActionButtonsContainer>
				</Styled.InfoContainer>
			</Styled.InnerContainer>
		</Styled.Container>
	);
};

const memorizedEventInfo: FC<EventInfoProps> = memo(PunchupEventInfo);

export { memorizedEventInfo as PunchupEventInfo };
