import { CardButton, Container, CustomIcon, Label, Stat } from './PunchupHighlightCard.styles';

import { HighlightCardProps } from './types';
import { PercentPieChart } from '../../PieChart';
import { SHOW_RATE } from '../constants';
import { convertToNum } from '../../HighlightsCollection/HighlightCard/HighlightCard';

export const PunchupHighlightCard = ({
	property,
	icon,
	stat,
	cardLabel,
	buttonLabel,
	onClick,
	dataCy,
}: HighlightCardProps) => {
	const formattedStat = typeof stat === 'number' ? Math.round(stat) : stat;

	if (property === SHOW_RATE) {
		return (
			<Container data-cy={dataCy}>
				<Label>{cardLabel}</Label>
				<PercentPieChart color="#fe5e25" sizes={{ fontSize: 24, iconSize: 120 }} rawPercentage={convertToNum(stat)} />
			</Container>
		);
	}

	return (
		<Container data-cy={dataCy}>
			{icon && <CustomIcon iconName={icon} />}
			<Stat>{formattedStat}</Stat>
			<Label>{cardLabel}</Label>
			{buttonLabel && onClick && (
				<CardButton text={buttonLabel} onClick={onClick} type="button" data-cy={`${dataCy}-btn`} />
			)}
		</Container>
	);
};
