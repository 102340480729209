import styled from 'styled-components';

export const DefaultButton = styled.button`
	background: ${({ theme }) => theme.colors.greenGradient3};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 35px;
	margin-top: 35px;
	padding: 16px 53px 16px 53px;
	width: 100%;
	border-width: 0;
	cursor: pointer;
`;

export const SecondaryButton = styled.button`
	background: transparent;
	color: ${({ theme }) => theme.colors.green};
	font-family: ${({ theme }) => theme.fonts.primary};
	border-width: 0;
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-decoration: underline;
	}
`;

export const PunchupSecondaryButton = styled.button`
	background: transparent;
	color: ${({ theme }) => theme.colors.punchupOrange};
	font-family: ${({ theme }) => theme.fonts.primary};
	border-width: 0;
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		text-decoration: underline;
	}
`;

export const TertiaryButton = styled.button`
	background: transparent;
	background-color: ${({ theme }) => theme.colors.red};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 35px;
	margin-top: 35px;
	padding: 16px 53px 16px 53px;
	width: 100%;
	border-width: 0;
	cursor: pointer;
`;

export const QuaternaryButton = styled.button`
	background: transparent;
	background-color: ${({ theme }) => theme.colors.lightGrey4};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 35px;
	margin-top: 35px;
	padding: 16px 53px 16px 53px;
	width: 100%;
	border-width: 0;
	cursor: pointer;
`;

export const PunchupTertiaryButton = styled.button`
	background: transparent;
	background-color: ${({ theme }) => theme.colors.punchupOrange};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.whitePearl};
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 35px;
	margin-top: 35px;
	padding: 16px 53px 16px 53px;
	width: 100%;
	border-width: 0;
	cursor: pointer;
`;

export const PunchupQuaternaryButton = styled.button`
	background-color: ${({ theme }) => theme.colors.whitePearl};
	border: ${({ theme }) => `1px solid ${theme.colors.punchupOrange}`};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.punchupOrange};
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 35px;
	margin-top: 35px;
	padding: 16px 53px 16px 53px;
	width: 100%;
	cursor: pointer;
`;

export const OutlinedButton = styled.button`
	background: transparent;
	border: ${({ theme }) => `1px solid ${theme.colors.green}`};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.green};
	font-family: ${({ theme }) => theme.fonts.primary};
	margin-bottom: 35px;
	margin-top: 35px;
	padding: 16px 53px 16px 53px;
	width: 100%;
	cursor: pointer;
`;

export const UnderlinedButton = styled.button`
	color: ${({ theme }) => theme.colors.green};
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	text-decoration: underline;
	color: ${({ theme }) => theme.colors.green};
	background: transparent;
	border: none;
	padding: 0;
	width: fit-content;
	cursor: pointer;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: inline-block;
		position: relative;
		z-index: 1;
		padding: 12px 24px;
		margin: -12px -24px;
	}
`;
