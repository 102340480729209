import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const Container = styled.div`
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.lightGrey7};
	display: flex;
	width: fit-content;
	height: fit-content;
	padding: 6px 8px;
`;

export const StyledInput = styled.input`
	all: unset;
	color: ${({ theme }) => theme.colors.lightGrey7};
	text-align: center;
	font-size: 20px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-style: normal;
	font-weight: 600;
	width: 50px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-moz-appearance: textfield;
		-webkit-appearance: none;
		margin: 0;
	}
	&.small {
		font-size: 14px;
	}
`;

export const CounterImage = styled.img`
	cursor: pointer;
`;

export const Text = styled(UnstyledText)`
	font-size: 14px;
	line-height: 16px;
	padding: 2px 14px;
`;
