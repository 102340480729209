import { CheckboxButtonProps } from './types';
import checkMark from 'assets/images/checkMark.svg';
import styled from 'styled-components';

export const CheckboxButton = styled.input<CheckboxButtonProps>`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-align: center;
	height: fit-content;

	&:before {
		box-sizing: border-box;
		content: '';
		display: block;
		width: ${({ btnSize }) => btnSize || '1.5em'};
		height: ${({ btnSize }) => btnSize || '1.5em'};
		border-radius: 4px;

		color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.grey9};
	}

	:checked {
		&:before {
			box-sizing: border-box;
			content: url(${checkMark});
			display: block;
			width: ${({ btnSize }) => btnSize || '1.5em'};
			height: ${({ btnSize }) => btnSize || '1.5em'};
			border-radius: 4px;
			cursor: pointer;
			background-color: ${({ theme }) => theme.colors.punchupOrange};
			color: ${({ theme }) => theme.colors.punchupOrange};
			border: 2px solid ${({ theme }) => theme.colors.punchupOrange};

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}
`;
