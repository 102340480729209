import { MainPanel, PunchupMainPanel, Wrapper } from './MainLayout.styled';

import { MainLayoutProps } from './types';
import { Sidebar } from 'features/common/components/Sidebar';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';

const MainLayout = ({ children, color, containsStickyEl = false, isFullVh = false }: MainLayoutProps) => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	useEffect(() => {
		const body = document.querySelector('body');
		if (body && color) {
			body.style.backgroundColor = color;
		}
	}, [color]);

	return (
		<Wrapper>
			{!isPunchup ? (
				<>
					<Sidebar />
					<MainPanel color={color} containsStickyEl={containsStickyEl} isFullVh={isFullVh}>
						{children}
					</MainPanel>
				</>
			) : (
				<PunchupMainPanel color={color} containsStickyEl={containsStickyEl} isFullVh={isFullVh}>
					{children}
				</PunchupMainPanel>
			)}
		</Wrapper>
	);
};

export { MainLayout };
