import { CalendarWrapperStyleProps } from '../types';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const Label = styled.label`
	font-size: 14px;
	font-weight: 500;
	color: #333333;
	margin-bottom: 8px;
`;

export const InputContainer = styled.div<{ hasError?: boolean }>`
	position: relative;
	width: 100%;

	.punchup-datepicker {
		box-sizing: border-box;
		width: 100%;
		padding: 16px;
		border: 1px solid ${({ hasError }) => (hasError ? '#FF0000' : '#E1E1E1')};
		border-radius: 8px;
		font-size: 16px;
		color: #333333;
		background: #ffffff;

		&:focus {
			outline: none;
			border-color: #e86339;
		}

		&::placeholder {
			color: #757575;
		}
	}

	/* Estilos para el calendario */
	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker {
		border: 1px solid #e1e1e1;
		border-radius: 8px;
		font-family: inherit;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	}

	.react-datepicker__header {
		background: #ffffff;
		border-bottom: 1px solid #e1e1e1;
	}

	.react-datepicker__day--selected {
		background-color: #e86339;
	}

	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item--selected {
		background-color: #e86339;
	}
`;

export const CalendarIcon = styled(UnstyledIcon)`
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
	color: #757575;
	pointer-events: none;
`;

export const Error = styled.span`
	color: #ff0000;
	font-size: 12px;
	margin-top: 4px;
`;

export const IconCalendar = styled(UnstyledIcon)<CalendarWrapperStyleProps>`
	position: absolute;
	width: 20px;
	height: 20px;
	top: 8px;
	right: 8px;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		right: 8px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
		right: 20px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		right: 25px;
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
		right: 40px;
	}
`;

export const CalendarWrapper = styled.div<CalendarWrapperStyleProps>`
	.react-datepicker-wrapper {
		width: 100%;
	}

	> div > div > input {
		background: ${({ theme }) => theme.colors.whitePearl};
		border-radius: 2px;
		color: ${({ theme }) => theme.colors.white};
		width: 100%;
		height: 35px;
		padding-left: 10px;
		padding-right: 0px;
		font-family: ${({ theme }) => theme.fonts.primary};
		font-size: 16px;
		box-sizing: border-box;

		&:focus {
			outline: 0;
		}
	}
`;

export const Calendar = styled.div`
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.whitePearl};
	box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
	border: none;
	overflow: hidden;

	input:focus + div > i,
	.calendar-popover {
		z-index: 2;
	}

	.react-datepicker {
		border: none;
		z-index: 1;
		background-color: ${({ theme }) => theme.colors.whitePearl};
	}

	.react-datepicker-wrapper .react-datepicker-wrapper .react-datepicker-ignore-onclickoutside {
		input:focus-visible {
			outline-offset: none;
		}
	}

	.react-datepicker__input-container {
		border-radius: 2px;
		border-style: none;
	}

	.react-datepicker__month {
		background-color: ${({ theme }) => theme.colors.whitePearl};
	}

	.react-datepicker__triangle {
		border: none;
	}

	.react-datepicker__navigation--previous {
		left: 25px;
		border: solid ${({ theme }) => theme.colors.white};
		border-width: 0 2px 2px 0;
		transform: rotate(135deg);
	}

	.react-datepicker__navigation--next {
		right: 25px;
		border: solid ${({ theme }) => theme.colors.white};
		border-width: 0 2px 2px 0;
		transform: rotate(-45deg);
	}

	.react-datepicker__time-container {
		border: none;
		position: fixed;
		left: 264px;
	}

	.react-datepicker-popper {
		z-index: 1;
	}

	.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
	.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
	.react-datepicker__year-read-view--down-arrow::before,
	.react-datepicker__month-read-view--down-arrow::before,
	.react-datepicker__month-year-read-view--down-arrow::before {
		border: none;
	}

	.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
		border-top: none;
		border-bottom-color: ${({ theme }) => theme.colors.whitePearl};
	}

	.react-datepicker__header {
		background-color: ${({ theme }) => theme.colors.whitePearl};
		border-bottom: none;
	}

	.react-datepicker-time__header {
		font-family: ${({ theme }) => theme.fonts.primary};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 145%;
		background-color: ${({ theme }) => theme.colors.whitePearl};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__time-list-item:hover {
		border-radius: 4px;
		color: ${({ theme }) => theme.colors.grey5};
		background-color: ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__time-container .react-datepicker__time {
		background-color: ${({ theme }) => theme.colors.whitePearl};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.colors.punchupOrange};
	}

	.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item--selected {
		border-radius: 4px;
		border: 2px solid ${({ theme }) => theme.colors.grey5};
		background-color: ${({ theme }) => theme.colors.punchupOrange};
		color: ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__current-month {
		font-family: ${({ theme }) => theme.fonts.primary};
		color: ${({ theme }) => theme.colors.white};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 145%;
	}

	.react-datepicker__day-name {
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day {
		border-radius: 100px;
		color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected {
		background-color: ${({ theme }) => theme.colors.punchupOrange};
		color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.punchupOrange};
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected:hover {
		border: 2px solid ${({ theme }) => theme.colors.grey5};
	}

	.react-datepicker__time-container {
		border: 2px solid ${({ theme }) => theme.colors.grey};
		border-radius: 0 10px 10px 0;
	}

	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item {
		height: 25px;
	}

	.react-datepicker__day--in-range {
		background-color: ${({ theme }) => theme.colors.punchupOrange};
		border: 2px solid ${({ theme }) => theme.colors.grey};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day--range-start {
		border-radius: 100%;
		border: 2px solid ${({ theme }) => theme.colors.grey};
	}

	.react-datepicker__day--in-selecting-range {
		background-color: ${({ theme }) => theme.colors.punchupOrange};
		border: 2px solid ${({ theme }) => theme.colors.grey};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day--keyboard-selected {
		border-radius: 100px;
		background-color: ${({ theme }) => theme.colors.punchupOrange};
		border: 2px solid ${({ theme }) => theme.colors.punchupOrange};
		color: ${({ theme }) => theme.colors.white};
	}

	.react-datepicker__day:hover {
		border-radius: 100px;
		background: ${({ theme }) => theme.colors.punchupOrange};
		border: 2px solid ${({ theme }) => theme.colors.punchupOrange};
		color: ${({ theme }) => theme.colors.white};
	}
`;
