import { Button } from 'features/common/components/Button';
import styled from 'styled-components';

export const TableButton = styled(Button)`
	border: 1px solid ${({ theme }) => theme.colors.lightGrey9};
	color: ${({ theme }) => theme.colors.lightGrey9};
	font-weight: 600;
	font-size: 16px;
	margin: 0;
	padding: 8px 16px;
	min-width: 170px;
`;

export const Span = styled.span`
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	min-width: 192px;
	padding: 8px 32px;
	width: 100%;
`;
