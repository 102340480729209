import styled, { css } from 'styled-components';

import { FormSide } from 'features/CustomFields/CustomFields.styles';
import { Text } from 'features/common/components/Text';

export const Wrapper = styled(FormSide)<{ isPunchup: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 46px 150px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 15px;
		width: 100%;
	}

	${({ isPunchup }) =>
		!isPunchup &&
		css`
			overflow: auto;

			@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
				padding: 18px;
			}
		`}
`;

export const SelectsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 0;
	}
`;

export const Label = styled(Text)<{ isPunchup: boolean }>`
	font-size: 15px;
	color: ${({ isPunchup }) => (isPunchup ? 'black' : 'white')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		line-height: 24px;
	}
`;

export const CustomQuestionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
