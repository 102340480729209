import * as Styles from './PunchupOrdersTable.styles';

import { OrderProps, OrdersData, Page } from 'features/Orders/types';
import { formatDate, getStatusColor } from './utils';

import { COLUMN_MIN_WIDTH } from './constants';
import { FC } from 'react';
import { Table } from 'features/common/components/Table';

export const PunchupTableBody: FC<OrderProps> = ({ ordersQueryObject }: OrderProps) => {
	const { data: orders } = ordersQueryObject;

	const statusStyle = (status: string) => ({
		backgroundColor: getStatusColor(status),
	});

	const realStatus = (order: OrdersData) => {
		if (order.transaction_type === 'registration') {
			if (order.status === 'Completed') {
				return 'Registered';
			}
		}
		if (order.transaction_type === 'void') {
			if (order.status === 'Refunded') {
				return 'Voided';
			}
		}
		return order.status;
	};

	const renderCell = (minWidth: number, value: any) => <Table.Cell minWidth={minWidth}>{value}</Table.Cell>;

	if (!orders || orders.pages[0].data.length === 0) {
		return (
			<tbody>
				<Styles.DataRow gridColumns="repeat(8, 1fr)" key="no-orders" textAlign="center">
					<Table.Cell minWidth={COLUMN_MIN_WIDTH[0]}>No orders found</Table.Cell>
				</Styles.DataRow>
			</tbody>
		);
	}

	return (
		<tbody>
			{orders?.pages.map((page: Page) =>
				page.data.map((order: OrdersData) => (
					<Styles.DataRow gridColumns="repeat(8, 1fr)" key={order.id} textAlign="center">
						{renderCell(COLUMN_MIN_WIDTH[0], order.User?.full_name)}
						{renderCell(COLUMN_MIN_WIDTH[1], order.User?.email)}
						{renderCell(
							COLUMN_MIN_WIDTH[2],
							order.events_name?.length > 1 ? order.events_name.join(', ') : order.events_name[0]
						)}
						{renderCell(
							COLUMN_MIN_WIDTH[3],
							order.tickets_name?.length > 1 ? order.tickets_name.join(', ') : order.tickets_name[0]
						)}
						{renderCell(COLUMN_MIN_WIDTH[4], order.quantity)}
						{renderCell(
							COLUMN_MIN_WIDTH[5],
							order.transaction_type === 'registration' || order.transaction_type === 'void'
								? 'registration'
								: order.price?.toFixed(2)
						)}
						{renderCell(COLUMN_MIN_WIDTH[6], formatDate(order.date_time))}
						<Table.Cell className="statusCell" style={statusStyle(realStatus(order))} minWidth={COLUMN_MIN_WIDTH[7]}>
							{realStatus(order)}
						</Table.Cell>
					</Styles.DataRow>
				))
			)}
		</tbody>
	);
};
