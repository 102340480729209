import { BoldText, Container, RotatedIcon, Text, ViewButton } from 'features/Dashboard/Dashboard.styles';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { CircleWithIcon } from 'features/common/components/CircleWithIcon';
import { Divider } from 'features/common/components/Divider';
import { EmptySection } from 'features/common/components/EmptySection';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { IdParamsType } from 'utils/types';
import { ImgContentSectionProps } from './types';
import { ROUTES } from 'features/routes';
import { SectionTitle } from 'features/common/components/SectionTitle';
import { useEffect } from 'react';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ImgContentSection = ({ eventImgCt, media }: ImgContentSectionProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id }: IdParamsType = useParams();
	const isLargeScreen = useResponsiveScreen();
	const pushToPath = (path: string, id: string) => push(generatePath(path, { id }));

	const [imgCt, setImgCt] = useState(eventImgCt);
	const [videoCt, setVideoCt] = useState(0);

	useEffect(() => {
		const [imgCt, videoCt] = media.reduce(
			([imgCt, videoCt], { type }) => (type === 'image' ? [imgCt + 1, videoCt] : [imgCt, videoCt + 1]),
			[eventImgCt, 0]
		);
		setImgCt(imgCt);
		setVideoCt(videoCt);
	}, [media]);

	return (
		<>
			<SectionTitle title={t('dashboard-imgContent')} />
			<FlexContainer padding="0 0 12px 0">
				{imgCt > 0 || videoCt > 0 ? (
					<Container>
						<FlexContainer alignItems="center" gap="32px">
							<CircleWithIcon height="45px" width="45px" iconName="image" />
							<BoldText>
								{imgCt > 0 && t('dashboard-imgContent-images', { imgCt, s: imgCt > 1 ? 's' : '' })}
								{imgCt > 0 && videoCt > 0 && ' & '}
								{videoCt > 0 && t('dashboard-imgContent-videos', { videoCt })}
							</BoldText>
						</FlexContainer>
						<ViewButton onClick={() => pushToPath(ROUTES.DASHBOARD_EDIT_CONTENT, id)}>
							<Text> {t('button-view-all')}</Text>
							<RotatedIcon iconName="arrowDownGreen" />
						</ViewButton>
					</Container>
				) : (
					<EmptySection
						onClick={() => pushToPath(ROUTES.DASHBOARD_EDIT_CONTENT, id)}
						text={t('dashboard-imgContent-info')}
					/>
				)}
			</FlexContainer>
			{!isLargeScreen && <Divider />}
		</>
	);
};
