import styled, { css } from 'styled-components';

interface ButtonProps {
	variant: 'primary' | 'secondary';
}

export const Button = styled.button<ButtonProps>`
	height: 40px;
	padding: 8px 16px;
	padding-inline: 48px;
	border-radius: 8px;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.2s ease;

	${({ variant }) =>
		variant === 'primary' &&
		css`
			background: #e86339;
			color: white;
			border: none;

			&:hover {
				background: #d95a33;
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		`}

	${({ variant }) =>
		variant === 'secondary' &&
		css`
			background: transparent;
			border: 1px solid rgb(229 229 229);
			font-weight: 500;

			&:hover {
				background: rgba(232, 99, 57, 0.05);
			}
		`}
`;
