import * as Styled from './SwitchTeam.styles';

import { ChangeEvent, useEffect, useState } from 'react';

import { MainLayout } from 'layouts/MainLayout';
import { OrganizationCard } from './OrganizationCard';
import { Partner } from 'services/auth/types';
import { Search } from 'features/common/components/Search';
import { useGetUsersMe } from 'hooks/useGetUsersMe';
import { useTranslation } from 'react-i18next';

export const SwitchTeam = () => {
	const { t } = useTranslation();
	const { data: user } = useGetUsersMe();

	const [partners, setPartners] = useState<Partner[]>([]);
	const [searchedPartner, setSearchedPartner] = useState(partners);

	useEffect(() => {
		if (!user) return;

		setPartners(user.Partners || []);
		setSearchedPartner(user.Partners || []);
	}, [user]);

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const search = e.target.value.toLocaleLowerCase();
		const partFiltered = partners.filter((p) => p.name.toLocaleLowerCase().includes(search));
		setSearchedPartner(partFiltered);
	};

	return (
		<MainLayout>
			<Styled.SwitchTeamRoot>
				<Styled.SearchLabel>{t('settings-switch-team-select-organization')}</Styled.SearchLabel>
				<Search onChange={handleSearch} placeholder={t('settings-switch-team-search-label')} />
				<Styled.OrganizationsContainer>
					{searchedPartner.map((partner) => (
						<OrganizationCard {...partner} key={partner.ID} />
					))}
				</Styled.OrganizationsContainer>
			</Styled.SwitchTeamRoot>
		</MainLayout>
	);
};
