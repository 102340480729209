import { PunchupTicketDetails } from 'features/PunchupTicketDetails';
import { TicketDetails as TicketDetailsComponent } from 'features/TicketDetails';
import { useAppSelector } from 'store/hooks';

const TicketDetails = (): JSX.Element => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	if (isPunchup) {
		return <PunchupTicketDetails />;
	}
	return <TicketDetailsComponent />;
};
export default TicketDetails;
