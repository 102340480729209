import { ChangeEvent, FC, useContext } from 'react';
import { ContentWrapper, Padding } from './Home.styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AppDomainContext } from 'contexts/appDomain';
import { Button } from './components/CompanyLogo/CompanyLogo.styles';
import { EventTitle } from './components/EventTitle';
import { Events } from './components/Events';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Highlights } from './components/Highlights';
import { InfoCard } from './components/InfoCard';
import { InputFile } from './components/CompanyLogo/Logo/Logo.styles';
import { LoggedUser } from 'services/auth/types';
import { MainLayout } from 'layouts/MainLayout';
import { RootState } from 'store';
import { UpcomingEvents } from './components/UpcomingEvents';
import { handlePictureChange } from 'services/OrgImage';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useToastNotification } from 'hooks/useToastNotification';
import { useTranslation } from 'react-i18next';

const Home: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const isLargeScreen = useResponsiveScreen();
	const appDomain = useContext(AppDomainContext);
	const { showNotification, TYPES } = useToastNotification();
	const { loggedUser } = useAppSelector((state: RootState) => state.auth);

	const partnerImage = loggedUser?.currentPartner.image;
	const isLoggedPartnerImage = !!loggedUser && !!partnerImage;
	const hasLogo = isLoggedPartnerImage && partnerImage !== '-';

	const handleInputFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			handlePictureChange(target, (results: LoggedUser | undefined, error: Error) => {
				if (error)
					showNotification({
						message: t('error-uploading-image'),
						type: TYPES.ERROR,
					});
			})
		);
		target.value = '';
	};

	return (
		<MainLayout>
			<EventTitle hasLogo={hasLogo} />
			<ContentWrapper>
				{!hasLogo && (
					<InfoCard
						title={t('companyLogo-title')}
						subtitle={t('companyLogo-subtitle')}
						icon={isLargeScreen ? 'ticket' : 'ticketMobile'}
						isClosable
						highlightBackground
					>
						<Button htmlFor="input-file">
							{t('companyLogo-button')}
							<InputFile
								id="input-file"
								name="image"
								type="file"
								accept="image/jpeg, image/png"
								onChange={handleInputFile}
							/>
						</Button>
					</InfoCard>
				)}
				{appDomain === 'producer' ? (
					<>
						<FlexContainer gap={isLargeScreen ? '41px' : '0px'} flexDirection={hasLogo ? 'row' : 'column'}>
							<Highlights hasLogo={hasLogo} />
						</FlexContainer>
						<UpcomingEvents />
					</>
				) : (
					<Padding />
				)}
				<Events />
			</ContentWrapper>
		</MainLayout>
	);
};

export { Home };
