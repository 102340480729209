import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    height: 100%;
  }

  * {
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  ::-webkit-scrollbar {
      display: none;
  }

  #root {
    height: 100vh;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: auto;
    }
  }
`;

export default GlobalStyle;
