import * as Styled from './Highlights.styles';

import { FC, memo, useEffect, useState } from 'react';
import {
	HOME_HIGHLIGHTS,
	TOTAL_ATTENDEES,
	TOTAL_EVENTS,
} from 'features/common/components/HighlightsCollection/constants';

import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { EmptyHighlights } from './EmptyHighlights';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { HighlightsProps } from './types';
import { useHighlights } from 'hooks/useHighlights';
import { useTranslation } from 'react-i18next';

const Highlights: FC<HighlightsProps> = ({ hasLogo }: HighlightsProps) => {
	const { t } = useTranslation();
	const [boxCollection, setBoxCollection] = useState(HOME_HIGHLIGHTS);
	const { highlights, isLoading } = useHighlights();
	const hasHighlights = highlights !== undefined && !!Object.values(highlights).reduce((t, n) => t + n);

	useEffect(() => {
		const boxCollections: BoxProps[] = [];
		for (let i = 0; i < boxCollection.length; i++) {
			const box = boxCollection[i];
			box.isLoading = isLoading;
			if (box.property === TOTAL_EVENTS) {
				box.stat = highlights?.total_events;
			} else if (box.property === TOTAL_ATTENDEES) {
				box.stat = highlights?.total_attendees;
				delete box.onClick;
			}
			boxCollections.push(box);
		}
		setBoxCollection(boxCollections);
	}, [highlights]);

	return (
		<Styled.MainWrapper hasHighlights={hasHighlights} hasLogo={hasLogo} data-cy="highlights-section">
			<Styled.Title element="h2">{t('highlights-title')}</Styled.Title>

			<FlexContainer gap="24px">
				{!isLoading && !hasHighlights && <EmptyHighlights hasLogo={hasLogo} />}

				<Styled.HighlightsBox>
					<HighlightsCollection boxCollection={boxCollection} />
				</Styled.HighlightsBox>
			</FlexContainer>
		</Styled.MainWrapper>
	);
};

const memorizedHighlights: FC<HighlightsProps> = memo(Highlights);

export { memorizedHighlights as Highlights };
