import { defaultValues, throttle, validationSchema, welcomeDefaultValues, welcomeValidationSchema } from './utils';
import { useEffect, useMemo } from 'react';

import { AuthLayout } from 'layouts/AuthLayout';
import { Canvas } from 'features/common/animations/Canvas';
import { Form } from 'features/common/components/Form';
import { LoginForm } from './components/LoginForm';
import { LoginProps } from './types';
import { RightContainer } from 'styles';
import { WelcomeForm } from './components/WelcomeForm';
import { setLoading } from 'store/slices/AuthSlice';
import { useCreateAccount } from './hooks/useCreateAccount';
import { useForm } from 'react-hook-form';
import { useLogin } from './hooks/useLogin';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

const Login = ({ isWelcome }: LoginProps) => {
	const { t } = useTranslation();
	const { onSubmit, errors } = useLogin();
	const { onCreateAccount, accountSetupErrors } = useCreateAccount();
	const throttledCreateAccount = useMemo(() => throttle(onCreateAccount), []);

	const formMethods = useForm({
		defaultValues: isWelcome ? welcomeDefaultValues : defaultValues,
		resolver: yupResolver(isWelcome ? welcomeValidationSchema(t) : validationSchema(t)),
	});

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<AuthLayout>
			<Form formMethods={formMethods} onSubmit={isWelcome ? throttledCreateAccount : onSubmit}>
				<RightContainer>
					<Canvas />
					{!isWelcome ? <LoginForm errors={errors} /> : <WelcomeForm errors={accountSetupErrors} />}
				</RightContainer>
			</Form>
		</AuthLayout>
	);
};

export { Login };
