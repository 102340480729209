import { Error, ListContainer, OptionContainer, RadioButtonLabel, RadioButtonListContainer } from './Form.styles';

import { InputLabel } from './InputLabel';
import { RadioButton } from '../RadioButton/RadioButton.styles';
import { RadioButtonListProps } from './types';
import { useAppSelector } from 'store/hooks';
import { useFormContext } from 'react-hook-form';

const RadioButtonList = ({
	id,
	name,
	label,
	options,
	Tooltip,
	className,
	variant = 'primary',
	background,
	gap,
	required,
	...rest
}: RadioButtonListProps) => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	const {
		formState: { errors },
		register,
		setValue,
	} = useFormContext();

	const error = errors[name];

	return (
		<RadioButtonListContainer variant={variant}>
			{label && variant !== 'tertiary' && (
				<InputLabel
					htmlFor={id ?? name}
					label={label}
					Tooltip={Tooltip}
					className={className}
					variant={variant}
					color={isPunchup ? 'black' : 'white'}
					required={required}
				/>
			)}
			<ListContainer variant={variant} background={background} gap={gap} isPunchup={isPunchup}>
				{options.map(({ value, optionLabel }) => (
					<OptionContainer key={optionLabel} variant={variant}>
						<RadioButton type="radio" id={value} value={value} {...rest} {...register(id ?? name)} />
						<RadioButtonLabel htmlFor={id ?? name} onClick={() => setValue(id, value)} isPunchup={isPunchup}>
							{optionLabel}
						</RadioButtonLabel>
					</OptionContainer>
				))}
			</ListContainer>
			<Error>{error?.message}</Error>
		</RadioButtonListContainer>
	);
};

export { RadioButtonList };
