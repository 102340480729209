import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const Title = styled(UnstyledText)`
	font-weight: 700;
	font-size: 38px;
	line-height: 30px;
	margin: 0;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 24px;
		line-height: 20px;
	}
`;

export const Subtitle = styled(UnstyledText)`
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	margin: 0;
	margin-top: 8px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		line-height: 24px;
	}
`;

export const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
`;
