import {
	LOTTERY,
	NORMAL_DASHBOARD_HIGHLIGHTS,
	PAGE_VIEWS,
	REG_DASHBOARD_HIGHLIGHTS,
	TICKETS_COMPED,
	TICKETS_SOLD,
	TOTAL_ATTENDEES,
	TOTAL_PAYOUT,
} from 'features/common/components/HighlightsCollection/constants';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { ContentWrapper } from 'features/Home/components/Highlights/Highlights.styles';
import { EventData } from 'features/common/types';
import { HighlightsCollection } from 'features/common/components/HighlightsCollection';
import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { toSigDigits } from 'utils/utils';
import { useHighlights } from 'hooks/useHighlights';

const Highlights = ({ event }: { event: EventData }) => {
	const { push } = useHistory();
	const { id } = useParams<IdParamsType>();
	const { eventHighlights } = useHighlights(id);

	const [boxCollection, setBoxCollection] = useState(
		event.mode === 'normal' ? NORMAL_DASHBOARD_HIGHLIGHTS : REG_DASHBOARD_HIGHLIGHTS
	);

	useEffect(() => {
		const boxCollections: BoxProps[] = [];
		for (let i = 0; i < boxCollection.length; i++) {
			let box: BoxProps | undefined = boxCollection[i];
			box.isLoading = !eventHighlights;
			if (box.property === TOTAL_PAYOUT) {
				box.stat = toSigDigits(eventHighlights?.total_payout || 0);
				box.buttonDisabled = !eventHighlights?.total_payout;
				box.onClick = () => push(ROUTES.ORDERS_PATH);
			} else if (box.property === TICKETS_SOLD) {
				box.stat = eventHighlights?.tickets_sold || 0;
				box.buttonDisabled = !eventHighlights?.tickets_sold;
				box.onClick = () => push(generatePath(ROUTES.ANALYTICS_BY_EVENT_PATH, { id }));
			} else if (box.property === TOTAL_ATTENDEES) {
				box.stat = eventHighlights?.total_attendees || 0;
				box.buttonDisabled = !eventHighlights?.total_attendees;
				box.onClick = () => push(generatePath(ROUTES.ATTENDEES, { id }));
			} else if (box.property === PAGE_VIEWS) {
				box.stat = eventHighlights?.page_views || 0;
				box.buttonDisabled = !eventHighlights?.page_views;
				box.onClick = () => push(generatePath(ROUTES.ANALYTICS_BY_EVENT_PATH, { id }));
			} else if (box.property === TICKETS_COMPED) {
				box.stat = eventHighlights?.tickets_comped || 0;
				box.buttonDisabled = !event.ticketTypes.length;
				box.onClick = () => push(generatePath(ROUTES.COMPED_TICKETS, { id }));
			} else if (box.property === LOTTERY) {
				if (event.ticketTypes.some((ticket) => ticket.isLottery)) {
					box.stat = eventHighlights?.lottery_entries || 0;
					box.buttonDisabled = !eventHighlights?.lottery_entries;
					box.onClick = () => push(generatePath(ROUTES.LOTTERY, { id }));
				} else {
					box = undefined;
				}
			}
			box && boxCollections.push(box);
		}
		setBoxCollection(boxCollections);
	}, [eventHighlights]);

	return (
		<ContentWrapper>
			<HighlightsCollection boxCollection={boxCollection} />
		</ContentWrapper>
	);
};

export { Highlights };
