import { LoggedUser } from 'services/auth/types';
import { RootState } from 'store';
import { getData } from 'services/api';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

export const useGetUsersMe = () => {
	const loggedUser = useSelector((state: RootState) => state.auth.loggedUser);

	const { data, isLoading } = useQuery({
		queryKey: ['users-me', loggedUser?.ID],
		queryFn: (): Promise<LoggedUser> => getData('/users/me'),
		enabled: !!loggedUser?.ID,
	});

	return { data, isLoading };
};
