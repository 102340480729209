import { Moment } from 'moment-timezone';
import { TFunction } from 'i18next';

export const timezones = (moment: Moment, t: TFunction) => [
	{
		label: `${t('timezone-eastern')} (${moment.tz('America/New_York').format('zz')}) ${moment
			.tz('America/New_York')
			.format('hh:mm A')}`,
		value: 'America/New_York',
		time: moment.tz('America/New_York').format('hh:mm A'),
	},
	{
		label: `${t('timezone-central')} (${moment.tz('America/Chicago').format('zz')}) ${moment
			.tz('America/Chicago')
			.format('hh:mm A')} `,
		value: 'America/Chicago',
		time: moment.tz('America/Chicago').format('hh:mm A'),
	},
	{
		label: `${t('timezone-mountain')} (${moment.tz('America/Denver').format('zz')}) ${moment
			.tz('America/Denver')
			.format('hh:mm A')}`,
		value: 'America/Denver',
		time: moment.tz('America/Denver').format('hh:mm A'),
	},
	{
		label: `${t('timezone-phoenix')} (${moment.tz('America/Phoenix').format('zz')}) ${moment
			.tz('America/Phoenix')
			.format('hh:mm A')}`,
		value: 'America/Phoenix',
		time: moment.tz('America/Phoenix').format('hh:mm A'),
	},
	{
		label: `${t('timezone-pacific')} (${moment.tz('America/Los_Angeles').format('zz')}) ${moment
			.tz('America/Los_Angeles')
			.format('hh:mm A')}`,
		value: 'America/Los_Angeles',
		time: moment.tz('America/Los_Angeles').format('hh:mm A'),
	},
	{
		label: `${t('timezone-alaska')} (${moment.tz('US/Alaska').format('zz')}) ${moment
			.tz('US/Alaska')
			.format('hh:mm A')}`,
		value: 'US/Alaska',
		time: moment.tz('US/Alaska').format('hh:mm A'),
	},
	{
		label: `${t('timezone-hawaii')} (${moment.tz('US/Hawaii').format('zz')}) ${moment
			.tz('US/Hawaii')
			.format('hh:mm A')}`,
		value: 'US/Hawaii',
		time: moment.tz('US/Hawaii').format('hh:mm A'),
	},
];
