import { Button, Info, RowColumnFlex, Title } from './SelectType.styles';
import { EVENT_MODE_NORMAL, EVENT_MODE_REGISTRATION } from 'utils/constants';

import { Divider } from 'features/common/components/Divider';
import { EventSummaryProps } from '../../types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { useTranslation } from 'react-i18next';

export const SelectType = ({ eventModeState }: EventSummaryProps) => {
	const { t } = useTranslation();

	return (
		<FlexContainer flexDirection="column" width="100%" gap="24px">
			<FlexContainer flexDirection="column" width="100%" gap="16px">
				<Title textAlign="center">{t('createEvent-select-type')}</Title>
				<RowColumnFlex>
					<Button
						type="button"
						selected={eventModeState.eventMode === EVENT_MODE_NORMAL}
						onClick={() => eventModeState.setEventMode(EVENT_MODE_NORMAL)}
						data-cy="ticketing-btn"
					>
						<FlexContainer flexDirection="column">
							<Title>{t('createEvent-ticketed-title')}</Title>
							<Info>{t('createEvent-ticketed-info')}</Info>
						</FlexContainer>
					</Button>

					<Button
						type="button"
						selected={eventModeState.eventMode === EVENT_MODE_REGISTRATION}
						onClick={() => eventModeState.setEventMode(EVENT_MODE_REGISTRATION)}
						data-cy="registration-btn"
					>
						<FlexContainer flexDirection="column">
							<Title>{t('createEvent-registration-title')}</Title>
							<Info>{t('createEvent-registration-info')}</Info>
						</FlexContainer>
					</Button>
				</RowColumnFlex>
			</FlexContainer>
			<Divider />
		</FlexContainer>
	);
};
