import { Background, Circle, Input, StyledLabel } from './PunchupToggle.styles';

import { FC } from 'react';
import { ToggleProps } from './types';

const PunchupToggle: FC<ToggleProps> = ({
	disabled,
	handleChange,
	handleClick,
	id,
	isChecked,
	name,
	...rest
}: ToggleProps) => (
	<StyledLabel htmlFor={id ?? name} disabled={disabled}>
		<Input
			{...rest}
			aria-label={id ?? name}
			id={id ?? name}
			name={name}
			type="checkbox"
			onChange={handleChange}
			isChecked={isChecked}
			disabled={disabled}
			onClick={handleClick}
			readOnly
			checked={isChecked}
		/>
		<Background disabled={disabled} isChecked={isChecked}>
			<Circle disabled={disabled} />
		</Background>
	</StyledLabel>
);

export { PunchupToggle };
