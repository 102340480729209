import { useLocation, useParams } from 'react-router-dom';

import { IPromoCodesFormParams } from 'features/PromoCodesForm/types';
import { PromoCodesForm } from 'features/Punchup/PromoCodesForm';
import { sendGAPage } from 'utils/utils';
import { useEffect } from 'react';

const EventPromoCodeFormPage: React.FC = () => {
	const { pathname } = useLocation();
	const { type } = useParams<IPromoCodesFormParams>();

	useEffect(() => {
		sendGAPage({ page: pathname, title: `Event Promo Codes ${type}` });
	}, []);

	return <PromoCodesForm />;
};

export default EventPromoCodeFormPage;
