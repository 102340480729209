import { Button } from 'features/common/components/Button';
import styled from 'styled-components';

export const FilterButton = styled(Button)`
	padding: 7px 0 7px 0;
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	flex: 0 50%;

	&:disabled {
		display: none;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;

export const Option = styled.label`
	display: flex;
	align-items: center;
	padding: 16px 0 16px 0;
	justify-content: flex-start;
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGrey8}`};
	&.last {
		border-bottom: unset;
	}
	margin: 0;
	gap: 16px;
	cursor: pointer;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
	}
`;
