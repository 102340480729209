import * as Styled from './CompedTicketsTable.styles';

import {
	COLUMN_HEADINGS,
	COLUMN_HEADINGS_MOBILE,
	COLUMN_MIN_WIDTH,
	COLUMN_MIN_WIDTH_MOBILE,
	GRID_COLUMNS,
	GRID_COLUMNS_MIN,
} from './constants';

import { CompedTicketsTableProps } from '../types';
import { FC } from 'react';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Icon } from 'features/common/components/Icon';
import { OUTLINED } from 'features/common/components/Button/constants';
import { Page } from 'features/Orders/types';
import { Table } from 'features/common/components/Table';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

export const CompedTicketsTable: FC<CompedTicketsTableProps> = ({ compedTicketsQueryObject, openCancelModal }) => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();
	const { fetchNextPage, hasNextPage, data: compedTickets } = compedTicketsQueryObject;

	const COL_HEADINGS = isLargeScreen ? COLUMN_HEADINGS : COLUMN_HEADINGS_MOBILE;
	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Table.HeaderRow gridColumns={isLargeScreen ? GRID_COLUMNS : GRID_COLUMNS_MIN}>
							{COL_HEADINGS.map((heading, i) => (
								<Table.Cell
									key={heading}
									minWidth={isLargeScreen ? COLUMN_MIN_WIDTH[i] : COLUMN_MIN_WIDTH_MOBILE[i]}
									textAlign={i === 5 ? 'center' : 'left'}
								>
									<Table.ColumnHeader>{heading}</Table.ColumnHeader>
								</Table.Cell>
							))}
						</Table.HeaderRow>
					</thead>
					<tbody>
						{compedTickets?.pages.map((page: Page) =>
							page?.data?.map(({ User: { email, full_name }, id, tickets_name, transaction_type, quantity }) => (
								<Table.DataRow gridColumns={isLargeScreen ? GRID_COLUMNS : GRID_COLUMNS_MIN} key={`${id}`}>
									{!isLargeScreen && (
										<Table.Cell minWidth={COLUMN_MIN_WIDTH_MOBILE[0]}>
											<Icon iconName="close" onClick={() => openCancelModal(id)} />
										</Table.Cell>
									)}
									<Table.Cell minWidth={isLargeScreen ? COLUMN_MIN_WIDTH[0] : COLUMN_MIN_WIDTH_MOBILE[1]}>
										{email}
									</Table.Cell>
									<Table.Cell minWidth={isLargeScreen ? COLUMN_MIN_WIDTH[1] : COLUMN_MIN_WIDTH_MOBILE[2]}>
										{full_name.split(' ')[0]}
									</Table.Cell>
									<Table.Cell minWidth={isLargeScreen ? COLUMN_MIN_WIDTH[2] : COLUMN_MIN_WIDTH_MOBILE[3]}>
										{full_name.split(' ')[1]}
									</Table.Cell>
									<Table.Cell minWidth={isLargeScreen ? COLUMN_MIN_WIDTH[3] : COLUMN_MIN_WIDTH_MOBILE[4]}>
										{tickets_name.join(', ')}
									</Table.Cell>
									<Table.Cell minWidth={isLargeScreen ? COLUMN_MIN_WIDTH[4] : COLUMN_MIN_WIDTH_MOBILE[5]}>
										{quantity}
									</Table.Cell>

									{isLargeScreen && transaction_type === 'for_comp' && (
										<Table.Cell minWidth={COLUMN_MIN_WIDTH[5]} textAlign="center">
											<Styled.Button
												text="Cancel"
												type="button"
												category="outlined"
												onClick={() => openCancelModal(id)}
											/>
										</Table.Cell>
									)}

									{isLargeScreen && ['comped', 'comp_canceled'].includes(transaction_type) && (
										<Styled.AcceptedDiv>
											{t(
												transaction_type == 'comped' ? 'compedTickets-orders-accepted' : 'compedTickets-orders-canceled'
											)}
										</Styled.AcceptedDiv>
									)}
								</Table.DataRow>
							))
						)}
					</tbody>
				</Table>
			</Table.TableContainer>

			<FlexContainer justifyContent="center" margin="32px 0">
				<Table.Button
					category={OUTLINED}
					text={t('button-load-more')}
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage}
					type="button"
				/>
			</FlexContainer>
		</>
	);
};
