import { BoxProps } from './types';
import i18n from 'locals/i18n';

export const TOTAL_EVENTS = 'total_events';
export const TOTAL_ATTENDEES = 'total_attendees';
export const TICKETS_SOLD = 'tickets_sold';
export const LOTTERY = 'lottery';
export const TOTAL_PAYOUT = 'total_payout';
export const TICKETS_COMPED = 'tickets_comped';
export const CHECK_INS = 'num_of_scans';
export const SCAN_INS = 'num_of_scans';
export const SHOW_RATE = 'show_rate';
export const PAGE_VIEWS = 'page_views';

const ATTENDEES_CARD: BoxProps = {
	property: 'total_attendees',
	dataCy: 'attendees-card',
	icon: 'user2White',
	stat: '',
	cardLabel: i18n.t('dashboard-highlights-totalAttendees'),
	buttonLabel: i18n.t('highlights-view-attendees'),
	onClick: undefined,
};

const CHECK_INS_CARD: BoxProps = {
	property: CHECK_INS,
	dataCy: 'check-ins-card',
	icon: 'userCheckWhite',
	stat: '',
	cardLabel: i18n.t('highlights-checkins'),
};

const COMPED_CARD: BoxProps = {
	property: TICKETS_COMPED,
	dataCy: 'comped-tickets-card',
	icon: 'mailWhite',
	stat: '',
	cardLabel: i18n.t('dashboard-highlights-comped'),
	buttonLabel: i18n.t('highlights-send-tickets'),
};

const EVENTS_CARD: BoxProps = {
	property: TOTAL_EVENTS,
	dataCy: 'events-card',
	icon: 'calendarWhite',
	stat: '',
	cardLabel: i18n.t('highlights-totalEvents'),
	buttonLabel: '',
};

// const INVITATIONS_CARD: BoxProps = { property: '',dataCy:'', icon: '', stat: '', cardLabel: i18n.t(''), buttonLabel: '' };

const PAGE_VIEWS_CARD: BoxProps = {
	property: PAGE_VIEWS,
	dataCy: '',
	icon: 'planner',
	stat: '',
	cardLabel: i18n.t('highlights-page-views'),
	buttonLabel: i18n.t('highlights-view-analytics'),
};

const PAYOUT_CARD: BoxProps = {
	property: TOTAL_PAYOUT,
	dataCy: 'payout-card',
	icon: 'dollarSignWhite',
	stat: '',
	cardLabel: i18n.t('dashboard-highlights-yourPayout'),
	buttonLabel: i18n.t('highlights-view-transactions'),
};

const SCAN_INS_CARD: BoxProps = {
	property: SCAN_INS,
	dataCy: 'scan-ins-card',
	icon: 'userCheckWhite',
	stat: '',
	cardLabel: i18n.t('highlights-scanins'),
};

const SHOW_RATE_CARD: BoxProps = {
	property: SHOW_RATE,
	dataCy: 'show-rate-card',
	icon: '',
	stat: '',
	cardLabel: i18n.t('highlights-show-rate'),
};

const TICKETS_SOLD_CARD: BoxProps = {
	property: TICKETS_SOLD,
	dataCy: 'tickets-sold-card',
	icon: 'ticketVertical',
	stat: '',
	cardLabel: i18n.t('dashboard-highlights-ticketsSold'),
	buttonLabel: i18n.t('highlights-view-analytics'),
};

const LOTTERY_CARD: BoxProps = {
	property: LOTTERY,
	dataCy: 'lottery-card',
	icon: 'dices',
	stat: '',
	cardLabel: i18n.t('dashboard-highlights-lottery'),
	buttonLabel: i18n.t('highlights-view-lottery'),
};

export const REG_DASHBOARD_HIGHLIGHTS: BoxProps[] = [PAGE_VIEWS_CARD, ATTENDEES_CARD]; // will include [INVITATIONS, PAGE_VIEWS,ATTENDEES, CHECK_INS] in the future
export const NORMAL_DASHBOARD_HIGHLIGHTS: BoxProps[] = [
	PAYOUT_CARD,
	LOTTERY_CARD,
	TICKETS_SOLD_CARD,
	ATTENDEES_CARD,
	COMPED_CARD,
	PAGE_VIEWS_CARD,
];
export const HOME_HIGHLIGHTS: BoxProps[] = [EVENTS_CARD, ATTENDEES_CARD];
export const REG_EVENT_ANALYTICS = [ATTENDEES_CARD, CHECK_INS_CARD, PAGE_VIEWS_CARD, SHOW_RATE_CARD];
export const NORMAL_EVENT_ANALYTICS = [ATTENDEES_CARD, SCAN_INS_CARD, PAGE_VIEWS_CARD, SHOW_RATE_CARD];
export const ALL_EVENT_ANALYTICS = [ATTENDEES_CARD, TICKETS_SOLD_CARD, SHOW_RATE_CARD];
