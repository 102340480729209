import * as Styled from './PunchupToggle.styles';

import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface PunchupToggleProps {
	htmlFor: string;
	name: string;
	label?: string;
}

export const PunchupToggle: FC<PunchupToggleProps> = ({ htmlFor, name, label }) => {
	const { register } = useFormContext();

	return (
		<Styled.Container>
			{label && <Styled.Label htmlFor={htmlFor}>{label}</Styled.Label>}
			<Styled.Toggle>
				<input type="checkbox" id={htmlFor} {...register(name)} />
				<Styled.Slider />
			</Styled.Toggle>
		</Styled.Container>
	);
};
