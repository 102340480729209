import * as Styled from '../AnalyticByEvent.styles';

import { PunchupTicketsSold } from 'features/Dashboard/components/PunchupTicketsSold';

const PunchupTicketsSoldSection: React.FC = () => (
	<Styled.AnalyticsContainer isPunchup={true}>
		<PunchupTicketsSold />
	</Styled.AnalyticsContainer>
);

export default PunchupTicketsSoldSection;
