import * as Styled from './PunchupResponsesTable.styles';

import {
	COLUMN_MAX_WIDTH,
	COLUMN_MIN_WIDTH,
	COLUMN_MIN_WIDTH_HEADERS,
	PAID_COLUMN_HEADINGS,
	REGISTRATION_COLUMN_HEADINGS,
	RESPONSES_INDEX,
} from './constants';
import { FC, useEffect, useState } from 'react';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { PunchupTableBody } from './PunchupTableBody';
import { ResponsesProps } from 'features/CustomFieldsResponses/types';
import { Table } from 'features/common/components/Table';
import { getCellIndex } from './utils';
import { useTranslation } from 'react-i18next';

export const PunchupResponsesTable: FC<ResponsesProps> = ({ responsesQueryObject, isAttendeesList, eventMode }) => {
	const { t, i18n } = useTranslation();
	const { fetchNextPage, hasNextPage, data } = responsesQueryObject;

	const [headings, setHeadings] = useState<string[]>([]);
	const [questions, setQuestions] = useState<string[]>([]);
	const [showDownloadQR, setShowDownloadQR] = useState(false);

	const customHeadings = eventMode === 'normal' ? PAID_COLUMN_HEADINGS : REGISTRATION_COLUMN_HEADINGS;

	useEffect(() => {
		const tempHeadings = [...customHeadings];
		if (!data || data.pages.length === 0) {
			setShowDownloadQR(false);
			setHeadings(tempHeadings);
			return;
		}

		const questions: string[] = [];
		data.pages.forEach((page) => {
			page.forEach((response) => {
				response.responses.forEach((response) => {
					if (!['ATTENDEE_NAME', 'ATTENDEE_EMAIL'].includes(response.custom_field.name))
						questions.push(response.custom_field.name);
				});
			});
		});

		const uniqueQuestions = Array.from(new Set(questions));
		setQuestions(uniqueQuestions);

		if (isAttendeesList) {
			tempHeadings.push(...uniqueQuestions);
		} else {
			tempHeadings.splice(RESPONSES_INDEX, 0, ...uniqueQuestions);
		}
		setHeadings(tempHeadings);

		if (data.pages.some((page) => page.some((response) => response.can_download_qr))) {
			setShowDownloadQR(true);
		}
	}, [data?.pages]);

	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Styled.HeaderRow gridColumns={`repeat(${headings.length}, 1fr)`} textAlign="center">
							{headings.map((heading, i) => {
								const index = getCellIndex(i, headings, RESPONSES_INDEX, customHeadings);
								return (
									<Table.Cell
										key={heading}
										minWidth={COLUMN_MIN_WIDTH_HEADERS[index]}
										maxWidth={COLUMN_MAX_WIDTH[index]}
									>
										<Styled.ColumnHeader>{i18n.exists(heading) ? t(heading) : heading}</Styled.ColumnHeader>
									</Table.Cell>
								);
							})}

							{isAttendeesList && showDownloadQR && (
								<Table.Cell minWidth={COLUMN_MIN_WIDTH[9]} maxWidth={COLUMN_MAX_WIDTH[9]} textAlign="center">
									<Table.ColumnHeader>{t('customFields-responses-ticketQR')}</Table.ColumnHeader>
								</Table.Cell>
							)}
						</Styled.HeaderRow>
					</thead>

					<PunchupTableBody
						responsesQueryObject={responsesQueryObject}
						columnsN={headings.length}
						questions={questions}
						isAttendeesList={isAttendeesList}
						showDownloadQR={showDownloadQR}
						eventMode={eventMode}
					/>
				</Table>
			</Table.TableContainer>
			<FlexContainer justifyContent="center" margin="32px 0">
				<Table.Button
					category={OUTLINED}
					text={t('button-load-more')}
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage}
					type="button"
				/>
			</FlexContainer>
		</>
	);
};
