import { Button } from 'features/common/components/Button';
import styled from 'styled-components';

export { Row, SectionTitleContainer, Title } from 'features/common/components/SectionTitle/SectionTitle.styles';

export const SButton = styled(Button)`
	width: fit-content;
	margin: 0;
	color: #666666;
	border: 1px solid #666666;

	padding: 8px 41px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 5px 11px;
		margin-bottom: 0px;
	}
`;

export const ButtonContainer = styled.div`
	gap: 16px;
	display: flex;
`;
