import * as Styled from './PunchupCustomFieldsResponses.styles';

import { debounce, downloadXLSFile, sendGAEvent } from 'utils/utils';
import { useHistory, useParams } from 'react-router-dom';

import { CustomFieldsResponsesProps } from '../types';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Icon } from 'features/common/components/Icon';
import { IdParamsType } from 'utils/types';
import { MainLayout } from 'layouts/MainLayout';
import { PunchupResponsesTable } from '../components/PunchupResponsesTable/PunchupResponsesTable';
import { PunchupSearch } from 'features/common/components/PunchupSearch';
import { getData } from 'services/api';
import { useEventCustomFields } from '../hooks/useEventCustomFields';
import useGetEvent from 'features/common/hooks/useGetEvent';
import { useTranslation } from 'react-i18next';

export const PunchupCustomFieldsResponses = ({ isAttendeesList = false }: CustomFieldsResponsesProps) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();

	const { event } = useGetEvent();
	const { id } = useParams<IdParamsType>();
	const { setSearchText, responsesQueryObject } = useEventCustomFields(isAttendeesList);

	const searchDebounce = debounce(setSearchText);

	const handleDownload = async () => {
		const response = await getData(`/events/${id}/custom-fields/responses/download`, {
			params: { all_attendees: isAttendeesList },
			responseType: 'blob',
			headers: { 'Content-Type': 'blob' },
		});

		downloadXLSFile(response, 'Responses');
		sendGAEvent({ category: 'Custom Fields', action: 'Custom Fields Responses download' });
	};

	return (
		<MainLayout>
			<Styled.Container>
				<Styled.CustomFlexContainer>
					<FlexContainer alignItems="center" justifyContent="space-between" gap="15px">
						<Styled.IconContainer onClick={goBack}>
							<Icon iconName="arrowLeft" color="brightness(0)" />
						</Styled.IconContainer>

						<PunchupSearch
							width="300px"
							onChange={(e) => searchDebounce(e.target.value)}
							placeholder={t('search-email-or-name')}
						/>
					</FlexContainer>

					<Styled.Buttons>
						<Styled.CsvButton onClick={handleDownload}>{t('customFields-responses-download')}</Styled.CsvButton>
					</Styled.Buttons>
				</Styled.CustomFlexContainer>

				<PunchupResponsesTable
					responsesQueryObject={responsesQueryObject}
					isAttendeesList={isAttendeesList}
					eventMode={event?.mode || 'normal'}
				></PunchupResponsesTable>
			</Styled.Container>
		</MainLayout>
	);
};
