import { FormSide } from 'features/CustomFields/CustomFields.styles';
import { PickTicketsContainerProps } from 'features/CustomFields/types';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Wrapper = styled(FormSide)`
	background-color: ${({ theme }) => theme.colors.grey4};
	padding: 24px 0px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		background-color: transparent;
		padding: 0 18px;
	}
`;

export const Label = styled(Text)<{ isPunchup: boolean }>`
	font-size: 15px;
	color: ${({ isPunchup }) => (isPunchup ? 'black' : 'white')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-left: 0;
		line-height: 24px;
	}
`;

export const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const Container = styled.div<PickTicketsContainerProps>`
	padding: 0 46px;
	gap: 10px;
	display: flex;
	flex-direction: column;
	margin-top: ${({ marginTop }) => (marginTop ? marginTop : '12px')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding-left: 0;
		margin-top: 27px;
	}
`;

export const CheckboxText = styled(Text)<{ isPunchup: boolean }>`
	font-size: 16px;
	font-weight: 500;
	color: ${({ isPunchup }) => (isPunchup ? 'black' : 'white')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 24px;
	}
`;

export const ChoiceInputRow = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
