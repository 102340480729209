import { Orders as OrdersComponent } from 'features/Orders';
import { PunchupOrders } from 'features/PunchupOrders';
import { ROUTES } from 'features/routes';
import { sendGAPage } from 'utils/utils';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';

const Orders = (): JSX.Element => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	useEffect(() => {
		sendGAPage({ page: ROUTES.ORDERS_PATH, title: 'Orders Page' });
	}, []);

	if (isPunchup) {
		return <PunchupOrders />;
	}

	return <OrdersComponent />;
};
export default Orders;
