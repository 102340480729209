import { Container, ModalContent, Overlay } from './Modal.styles';

import { ActionButtons } from './ActionButtons';
import { Body } from './Body';
import { ESCAPE_KEY } from './constants';
import { FocusOn } from 'react-focus-on';
import { Header } from './Header';
import { ModalProps } from './types';
import { useEffect } from 'react';

const Modal = ({ children, className, onClose }: ModalProps) => {
	function handKeyDown(event: { key: string }) {
		if (event.key === ESCAPE_KEY && onClose) {
			onClose();
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', handKeyDown);
		return () => {
			document.removeEventListener('keydown', handKeyDown);
		};
	}, []);

	return (
		<>
			<Overlay />
			<Container>
				<FocusOn>
					<ModalContent role="dialog" aria-modal="true" className={className}>
						{children}
					</ModalContent>
				</FocusOn>
			</Container>
		</>
	);
};
Modal.Header = Header;
Modal.Body = Body;
Modal.ActionButtons = ActionButtons;

export { Modal };
