import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme, color }) => color || theme.colors.blackGrey};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const LeftContainer = styled.div`
	overflow: hidden;
	position: relative;
	border-radius: 18px;
	width: calc(40vw - 32px);
	height: calc(100vh - 64px);

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100vw;
		height: 100px;
	}
`;

export const TicketBackground = styled.img`
	object-fit: cover;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: none;
	}
`;

export const Logo = styled.img`
	top: 0;
	left: 0;
	width: 300px;
	height: 80px;
	object-fit: none;
	position: absolute;
	background: ${({ theme }) => theme.colors.verticalGreyGradient};
	
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 100vw;
		height: 100px;
		position: fixed;
		background: ${({ theme }) => theme.colors.grey3};
	}s
`;
