import * as Styled from './PunchupCompedTicketsTable.styles';

import { COLUMN_MIN_WIDTH, GRID_COLUMNS } from './constants';

import { CompedTicketsTableProps } from '../types';
import { FC } from 'react';
import { Page } from 'features/Orders/types';
import { Table } from 'features/common/components/Table';
import { useTranslation } from 'react-i18next';

export const PunchupCompedTicketsTableBody: FC<CompedTicketsTableProps> = ({
	compedTicketsQueryObject,
	openCancelModal,
}) => {
	const { t } = useTranslation();
	const { data: compedTickets } = compedTicketsQueryObject;

	if (!compedTickets || compedTickets.pages[0].data.length === 0) {
		return (
			<tbody>
				<Styled.DataRow gridColumns="repeat(8, 1fr)" key="no-orders" textAlign="center">
					<Styled.Cell minWidth={COLUMN_MIN_WIDTH[0]}>No comped tickets found</Styled.Cell>
				</Styled.DataRow>
			</tbody>
		);
	}

	return (
		<tbody>
			{compedTickets?.pages.map((page: Page) =>
				page?.data?.map(({ User: { email, full_name }, id, tickets_name, transaction_type, quantity }) => (
					<Styled.DataRow gridColumns={GRID_COLUMNS} key={`${id}`}>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[0]}>{email}</Table.Cell>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[1]}>{full_name.split(' ')[0]}</Table.Cell>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[2]}>{full_name.split(' ')[1]}</Table.Cell>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[3]}>{tickets_name.join(', ')}</Table.Cell>
						<Table.Cell minWidth={COLUMN_MIN_WIDTH[4]}>{quantity}</Table.Cell>

						{transaction_type === 'for_comp' && (
							<Table.Cell minWidth={COLUMN_MIN_WIDTH[5]} textAlign="center">
								<Styled.Button text="Cancel" type="button" category="outlined" onClick={() => openCancelModal(id)} />
							</Table.Cell>
						)}

						{['comped', 'comp_canceled'].includes(transaction_type) && (
							<Styled.AcceptedDiv>
								{t(transaction_type == 'comped' ? 'compedTickets-orders-accepted' : 'compedTickets-orders-canceled')}
							</Styled.AcceptedDiv>
						)}
					</Styled.DataRow>
				))
			)}
		</tbody>
	);
};
