import {
	Background,
	BackgroundContainer,
	Circle,
	Input,
	PunchupInputContainer,
	UncheckedIconContainer,
} from './DiscountToggle.styles';

import { FC } from 'react';
import { Icon } from 'features/common/components/Icon';
import { PunchupLabel } from '../../Form/Form.styles';
import { ToggleProps } from './types';
import dolar from 'assets/images/dolar.svg';
import dolarBlack from 'assets/images/dolarBlack.svg';
import percentage from 'assets/images/percentage.svg';
import percentageBlack from 'assets/images/percentageBlack.svg';

const DiscountToggle: FC<ToggleProps> = ({
	id,
	label,
	isPercentage,
	disabled,
	name,
	handleChange,
	...rest
}: ToggleProps) => (
	<PunchupInputContainer hasError={false}>
		{label && <PunchupLabel>{label}</PunchupLabel>}

		<Input
			{...rest}
			aria-label={id ?? name}
			id={id ?? name}
			name={name}
			type="checkbox"
			onChange={handleChange}
			isPercentage={isPercentage}
			disabled={disabled}
			hidden
		/>

		<BackgroundContainer>
			<Background disabled={disabled} isPercentage={isPercentage}>
				<UncheckedIconContainer isPercentage={!isPercentage} onClick={handleChange}>
					<Icon src={isPercentage ? dolarBlack : percentageBlack} size="M" />
				</UncheckedIconContainer>
				<Circle disabled={disabled} isPercentage={isPercentage}>
					<Icon src={isPercentage ? percentage : dolar} size="M" color="white" />
				</Circle>
			</Background>
		</BackgroundContainer>
	</PunchupInputContainer>
);

export { DiscountToggle };
