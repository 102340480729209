const ROUTES = {
	ROOT_PATH: '/',
	HOME_PATH: '/home',
	DASHBOARD_PATH: '/home/dashboard/:id',
	EDIT_EVENT_PATH: '/home/dashboard/:id/edit',
	TICKET_DETAILS_PATH: '/home/dashboard/:id/ticket-details/:ticketId',
	TICKET_NEW_PATH: '/home/dashboard/:id/new-ticket',
	PROMO_CODES_DETAILS_PATH: '/home/dashboard/:id/promoCodeDetails/:codeId',
	DASHBOARD_PROMO_CODES_NEW_PATH: '/home/dashboard/:id/newPromo',
	DASHBOARD_EDIT_CONTENT: '/home/dashboard/:id/edit-content',
	DASHBOARD_ADD_CUSTOM_FIELDS: '/home/dashboard/:eventId/custom-fields/create',
	DASHBOARD_EDIT_WEBSITE_STYLES: '/website/edit-styles/:id',
	DASHBOARD_EDIT_CUSTOM_FIELDS: '/home/dashboard/:eventId/custom-fields/:id/edit',
	DASHBOARD_CUSTOM_FIELDS_RESPONSES: '/home/dashboard/:id/custom-fields',
	DASHBOARD_NEW_AFFILIATE: '/home/dashboard/:id/new-affiliate',
	DASHBOARD_VIEW_AFFILIATES: '/home/dashboard/:id/view-affiliates',
	DASHBOARD_PROMO_CODES_NEW_FORM_PATH: '/home/dashboard/:id/newPromo/:type',
	DASHBOARD_REORDER_CUSTOM_FIELDS: '/home/dashboard/:id/custom-fields/reorder',
	EVENT_PROMO_CODES_NEW_FORM_PATH: '/events/:eventId/promo-codes/new',
	EVENT_PROMO_CODES_EDIT_FORM_PATH: '/promo-codes/:id/edit',
	LOGIN_PATH: '/login',
	LOTTERY: '/events/:id/lottery',
	MEMBER_LOGIN: '/member-login',
	CREATE_ACCOUNT_PATH: '/create-account',
	PROMO_CODES_PATH: '/promo-codes',
	PROMO_CODES_EDIT_PATH: '/promo-codes/:id/edit/:type',
	PROMO_CODES_NEW_PATH: '/promo-codes/newPromo',
	PROMO_CODES_NEW_FORM_PATH: '/promo-codes/newPromo/:type',
	CREATE_EVENT_PATH: '/create-event',
	CREATE_TICKET_PATH: '/create-event/:eventId/create-ticket',
	ANALYTICS_PATH: '/analytics',
	ANALYTICS_BY_EVENT_PATH: '/analytics/event/:id',
	EMBED_PATH: '/embed',
	FAN_DATABASE_PATH: '/fan-database',
	SETTINGS_PATH: '/settings',
	SWITCH_TEAM_PATH: '/settings/switch-team',
	TERMS_OF_SERVICE: '/terms-of-service/:id',
	ORDERS_PATH: '/orders',
	ORDERS_BY_EVENT_PATH: '/events/:id/orders',
	REFUNDABLE_TICKETS_PATH: '/refund-tickets/:id',
	REFUND_TICKETS_SUMMARY_PATH: '/refund-tickets/:fanId/:ticketIds',
	MARKETING_PATH: '/marketing',
	CREATE_CAMPAIGN_PATH: '/marketing/new-campaign',
	EDIT_CAMPAIGN_PATH: '/marketing/:campaignId',
	FORGOT_PASSWORD: '/reset-password',
	SUPPORT: 'https://help.tixologi.com/',
	USER_MGMT: '/usermgmt',
	COMPED_TICKETS: '/comped-tickets/:id',
	SCANNER_ACCOUNT: '/scanner-account',
	SEND_TICKETS: '/send-tickets/:id',
	BOX_OFFICE: '/box-office/:id',
	ATTENDEES: '/events/:id/attendees',
};

export { ROUTES };
