import { ContainerActionButtons, ContainerBody, Icon, ModalButton, Text } from './PunchupErrorModal.styles';

import { ErrorModalProps } from './types';
import { FC } from 'react';
import { Modal } from 'features/common/components/Modal/Modal';
import { QUATERNARY } from 'features/common/components/Button/constants';
import { useTranslation } from 'react-i18next';

export const PunchupErrorModal: FC<ErrorModalProps> = ({ closeModal }) => {
	const { t } = useTranslation();

	return (
		<Modal onClose={closeModal}>
			<Modal.Header>{t('availability-Modal-Title')}</Modal.Header>
			<Modal.Body>
				<ContainerBody>
					<Icon iconName="alertCircleRed" />
					<Text element="p">{t('availability-Modal-Message')}</Text>
				</ContainerBody>
			</Modal.Body>
			<Modal.ActionButtons>
				<ContainerActionButtons>
					<ModalButton category={QUATERNARY} onClick={closeModal} text={t('button-dismiss')} />
				</ContainerActionButtons>
			</Modal.ActionButtons>
		</Modal>
	);
};
