import { Label, LabelContent, Span, SubtitleSpan } from './Form.styles';

import { FC } from 'react';
import { InputLabelProps } from './types';
import { OptionalSpan } from './PunchupDateTimePicker/PunchupDateTimePicker.styles';

const InputLabel: FC<InputLabelProps> = ({
	htmlFor,
	label,
	subtitle,
	className,
	color,
	Tooltip,
	variant = 'primary',
	required,
	...rest
}: InputLabelProps) => (
	<LabelContent>
		<Label variant={variant} className={className ?? ''} htmlFor={htmlFor} {...rest}>
			<Span className={className ?? ''} color={color}>
				{label}
				{Tooltip && <Tooltip />}
				{required === false && <OptionalSpan>Optional</OptionalSpan>}
			</Span>
			{subtitle && <SubtitleSpan className={className ?? ''}>{subtitle}</SubtitleSpan>}
		</Label>
	</LabelContent>
);

export { InputLabel };
