import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: fixed;
	height: 125px;
	width: 100%;
	left: 0;
	top: 0;
	background-color: ${({ theme }) => theme.colors.grey3};
	box-shadow: ${({ theme }) => `0px 8px 16px ${theme.colors.darkGrey3}`};
	z-index: 4;
`;

export const ContainerHamburger = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 25px;
`;

export const ContainerImage = styled.div`
	display: flex;
	align-items: center;
	margin-left: calc(50% - 75px);
`;

export const Logo = styled.img``;
