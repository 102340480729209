import * as Styled from './PunchupOrders.styles';

import { FC, useEffect } from 'react';
import { debounce, sendGAEvent } from 'utils/utils';
import { useHistory, useParams } from 'react-router-dom';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { Icon } from 'features/common/components/Icon';
import { IdParamsType } from 'utils/types';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { NoEventsFallback } from 'features/common/components/NoEventsFallback';
import { PunchupOrdersTable } from './components/OrdersTable/PunchupOrdersTable';
import { PunchupSearch } from 'features/common/components/PunchupSearch';
import { QueryResultProps } from './types';
import { getData } from 'services/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useOrdersPage } from './hooks/useOrdersPage';
import { useTranslation } from 'react-i18next';

const RESULTS_PER_PAGE = 30;

export const PunchupOrders: FC = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { id: eventId } = useParams<IdParamsType>();

	const {
		searchText,
		setSearchText,
		selectedPeriod,
		selectedEvents,
		eventFilterApplied,
		isNotFiltered,
		downloadOrdersQuery,
	} = useOrdersPage();

	const searchDebounce = debounce(setSearchText);

	const ordersQueryObject = useInfiniteQuery({
		queryKey: ['orders', selectedPeriod.applied, selectedEvents.applied, searchText],
		queryFn: ({ pageParam = 1 }) => {
			const event_ids = eventId ? eventId : eventFilterApplied;

			const searchParams = new URLSearchParams();
			searchParams.set('page', pageParam.toString());
			searchParams.set('per_page', RESULTS_PER_PAGE.toString());

			searchText && searchParams.set('search', searchText);
			event_ids && searchParams.set('event_ids', event_ids);
			selectedPeriod.applied && searchParams.set('date', selectedPeriod.applied);

			return getData('/orders?' + searchParams.toString());
		},
		getNextPageParam: (lastPage, pages) => (lastPage.data.length >= RESULTS_PER_PAGE ? pages.length + 1 : undefined),
	}) as QueryResultProps;

	const displayFallback =
		!ordersQueryObject.isLoading && isNotFiltered && !ordersQueryObject.data?.pages[0].data && searchText === '';

	const downloadClick = () => {
		downloadOrdersQuery.mutate();
		sendGAEvent({ category: 'Orders', action: 'Orders download' });
	};

	useEffect(() => {
		sendGAEvent({ category: 'Orders', action: 'Orders filter' });
	}, [selectedEvents, selectedPeriod]);

	return (
		<MainLayout>
			{displayFallback ? (
				<NoEventsFallback isFanDatabase={false} />
			) : (
				<Styled.Container>
					<Styled.CustomFlexContainer>
						<FlexContainer alignItems="center" justifyContent="space-between" gap="15px">
							<Styled.IconContainer onClick={goBack}>
								<Icon iconName="arrowLeft" color="brightness(0)" />
							</Styled.IconContainer>

							<PunchupSearch
								width="300px"
								onChange={(e) => searchDebounce(e.target.value)}
								placeholder={t('search-email-or-name')}
							/>
						</FlexContainer>

						<Styled.Buttons>
							<Styled.CsvButton onClick={downloadClick}>{t('ordersPage-download')}</Styled.CsvButton>
						</Styled.Buttons>
					</Styled.CustomFlexContainer>

					<PunchupOrdersTable ordersQueryObject={ordersQueryObject} />
				</Styled.Container>
			)}
		</MainLayout>
	);
};
