import { ContainerActionButtons, ContainerBody, ModalButton, Text } from './RunLotteryModal.styles';
import { FC, useState } from 'react';
import { Input, Label } from 'features/common/components/Form/Form.styles';
import { QUATERNARY, TERTIARY } from 'features/common/components/Button/constants';

import { IdParamsType } from 'utils/types';
import { Modal } from 'features/common/components/Modal/Modal';
import { RunLotteryModalProps } from './types';
import { post } from 'services/api';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { toastSuccess } from 'hooks/useToastNotification/useToastNotification';
import { useEventLotteryEntries } from '../../hooks/useEventLotteryEntries';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const RunLotteryModal: FC<RunLotteryModalProps> = ({ showModal, closeModal }) => {
	const { t } = useTranslation();
	const { id: eventId }: IdParamsType = useParams();

	const [numberOfWinners, setNumberOfWinners] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { totalQuantity, refetch } = useEventLotteryEntries();

	const handleRunLottery = async () => {
		setIsLoading(true);

		try {
			await post(`/events/${eventId}/lottery/run`, {
				number_of_winners: Number(numberOfWinners),
			});

			refetch();
			toastSuccess('Lottery run successfully');
		} catch (error) {
			toastError('Error running lottery');
		} finally {
			closeModal();
			setIsLoading(false);
		}
	};

	const handleChangeNumberOfWinners = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number(e.target.value);
		if (!value) {
			setNumberOfWinners('');
		} else if (value && value <= totalQuantity) {
			setNumberOfWinners(value.toString());
		}
	};

	if (!showModal) return null;

	return (
		<Modal onClose={closeModal}>
			<Modal.Header>Run Lottery</Modal.Header>
			<Modal.Body>
				<ContainerBody>
					<Text element="p">This action cannot be undone.</Text>

					<Label variant="secondary">Number of winners (max {totalQuantity})</Label>
					<Input
						type="number"
						placeholder="Enter number of winners"
						style={{ marginTop: '10px', color: 'black' }}
						value={numberOfWinners}
						onChange={handleChangeNumberOfWinners}
					/>
				</ContainerBody>
			</Modal.Body>
			<Modal.ActionButtons>
				<ContainerActionButtons>
					<ModalButton
						category={QUATERNARY}
						onClick={closeModal}
						text={t('button-cancel')}
						type="button"
						disabled={isLoading}
					/>
					<ModalButton
						category={TERTIARY}
						onClick={handleRunLottery}
						text={t('button-run-lottery')}
						type="button"
						disabled={isLoading}
					/>
				</ContainerActionButtons>
			</Modal.ActionButtons>
		</Modal>
	);
};
