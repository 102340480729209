import { SquareColorProps } from './types';
import styled from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	background: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

export const IconContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

export const RefsContainer = styled.div`
	width: 100%;
`;

export const RefTitle = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 16px;
	text-align: center;
	color: ${({ theme }) => theme.colors.black};
`;

export const Ref = styled.div`
	display: flex;
	align-items: center;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 14px;
	margin-bottom: 8px;
	color: ${({ theme }) => theme.colors.black};
`;

export const SquareColor = styled.div`
	min-width: 16px;
	min-height: 16px;
	background-color: ${({ color }: SquareColorProps) => color};
	margin-right: 8px;
	border-radius: 2px;
`;

export const RefBottom = styled(Ref)`
	margin-top: 10px;
`;
