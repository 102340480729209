import styled from 'styled-components';

export const RecipientInfo = styled.div`
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
`;

export const Info = styled.p`
	display: flex;
	justify-content: space-between;
	color: ${({ theme }) => theme.colors.black};
`;
