import * as Styled from './PunchupCompedTickets.styles';

import { useHistory, useParams } from 'react-router-dom';

import { COMPED_FILTER_OPTIONS } from './constants';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { Icon } from 'features/common/components/Icon';
import { IdParamsType } from 'utils/types';
import { MainLayout } from 'layouts/MainLayout';
import { PunchupCancelInviteModal } from './PunchupCancelInviteModal';
import { PunchupCompedTicketsTable } from './PunchupCompedTicketsTable';
import { PunchupSearch } from 'features/common/components/PunchupSearch';
import { ROUTES } from 'features/routes';
import { debounce } from 'utils/utils';
import { useCompedTicketsPage } from './hooks/useCompedTicketsPage';
import { useTranslation } from 'react-i18next';

export const PunchupCompedTickets = () => {
	const { t } = useTranslation();
	const { goBack, push } = useHistory();
	const { id } = useParams<IdParamsType>();

	const {
		setSearchText,
		setSelectedPeriod,
		compedTicketsQueryObject,
		selectedPeriod,
		showCancelModal,
		closeCancelModal,
		openCancelModal,
		onCancelInvite,
	} = useCompedTicketsPage();

	const searchDebounce = debounce(setSearchText);

	const onSendTicket = () => push(ROUTES.SEND_TICKETS.replace(':id', id));

	return (
		<MainLayout>
			<Styled.Container>
				<Styled.FiltersContainer>
					<FlexContainer alignItems="center" justifyContent="space-between" gap="15px">
						<Styled.IconContainer onClick={goBack}>
							<Icon iconName="arrowLeft" color="brightness(0)" />
						</Styled.IconContainer>

						<PunchupSearch
							width="300px"
							onChange={(e) => searchDebounce(e.target.value)}
							placeholder={t('search-email-or-name')}
						/>
					</FlexContainer>

					<Styled.ButtonsContainer>
						<Styled.Filter
							onChange={(value) => setSelectedPeriod(value)}
							options={COMPED_FILTER_OPTIONS}
							selectedOption={selectedPeriod}
						/>
						<Styled.SendTicketsButton onClick={onSendTicket}>
							{t('comped-tickets-send-tickets')}
						</Styled.SendTicketsButton>
					</Styled.ButtonsContainer>
				</Styled.FiltersContainer>

				<PunchupCompedTicketsTable
					compedTicketsQueryObject={compedTicketsQueryObject}
					openCancelModal={openCancelModal}
				/>
			</Styled.Container>

			{showCancelModal.show && (
				<PunchupCancelInviteModal
					showModal={showCancelModal.show}
					closeModal={closeCancelModal}
					modalInfo={{
						modalTitle: t('comped-ticket-cancel-invite-modal-title'),
						modalMessage: t('comped-ticket-cancel-invite-modal-description'),
						onDelete: onCancelInvite,
					}}
				/>
			)}
		</MainLayout>
	);
};
