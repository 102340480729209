import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { CFSectionProps } from './types';
import { CustomFieldsComponent } from 'features/common/components/CustomFieldsComponent';
import { EVENT_MODE_NORMAL } from 'utils/constants';
import { IdParamsType } from 'utils/types';
import { MultiButtonSectionTitle } from 'features/common/components/MultiButtonSectionTitle';
import { ROUTES } from 'features/routes';
import { useGetCustomFields } from 'features/common/components/CustomFieldsComponent/useGetCustomFields';
import { useTranslation } from 'react-i18next';

export const CustomFieldsSection = ({ eventMode }: CFSectionProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { id } = useParams<IdParamsType>();
	const { customFields } = useGetCustomFields();

	const [hideReorderButton, setHideReorderButton] = useState(false);

	const title = t(`dashboard-${eventMode === EVENT_MODE_NORMAL ? 'custom-fields' : 'registration-form'}`);

	useEffect(() => {
		if (customFields) {
			setHideReorderButton(eventMode === EVENT_MODE_NORMAL || customFields.length === 0);
		}
	}, [customFields]);

	const onClickReorder = () => push(generatePath(ROUTES.DASHBOARD_REORDER_CUSTOM_FIELDS, { id }));
	const onClickAddNew = () => push(generatePath(ROUTES.DASHBOARD_ADD_CUSTOM_FIELDS, { eventId: id }));
	const onClickViewResponses = () => push(generatePath(ROUTES.DASHBOARD_CUSTOM_FIELDS_RESPONSES, { id }));

	const buttons = [
		{
			label: t('dashboard-reorder'),
			onClick: onClickReorder,
			dataCy: 'customFields-reorder-button',
			hidden: hideReorderButton,
		},
		{ label: t('dashboard-viewResponses'), onClick: onClickViewResponses, hidden: customFields?.length === 0 },
		{ label: t('dashboard-add'), onClick: onClickAddNew, disabled: false },
	];

	return (
		<>
			<MultiButtonSectionTitle title={title} buttons={buttons} dataCy="custom-fields-section" />
			<CustomFieldsComponent eventId={id} eventMode={eventMode} />
		</>
	);
};
