import { generatePath, useHistory } from 'react-router-dom';

import { CustomField } from './components/CustomField';
import { CustomField as CustomFieldType } from 'features/CustomFields/types';
import { CustomFieldsComponentProps } from './types';
import { EVENT_MODE_NORMAL } from 'utils/constants';
import { EmptySection } from '../EmptySection';
import { ROUTES } from 'features/routes';
import { Wrapper } from './CustomFieldsComponent.styles';
import { toastError } from 'hooks/useToastNotification/useToastNotification';
import { useGetCustomFields } from './useGetCustomFields';
import useGetEvent from 'features/common/hooks/useGetEvent';
import { useTranslation } from 'react-i18next';

export const CustomFieldsComponent = ({ eventId, eventMode }: CustomFieldsComponentProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { event } = useGetEvent();
	const { customFields } = useGetCustomFields();

	const text = t(`dashboard-${eventMode === EVENT_MODE_NORMAL ? 'custom-fields' : 'registration'}-info`);

	const onClickAddNew = () => {
		if (event?.ticketTypes?.length && event?.ticketTypes?.length > 0) {
			push(generatePath(ROUTES.DASHBOARD_ADD_CUSTOM_FIELDS, { eventId }));
		} else {
			toastError(t('dashboard-custom-fields-no-ticket-types'));
		}
	};

	return customFields && customFields?.length > 0 ? (
		<Wrapper>
			{customFields?.map((field: CustomFieldType) => (
				<CustomField key={field.ID} {...field} />
			))}
		</Wrapper>
	) : (
		<EmptySection onClick={onClickAddNew} text={text} />
	);
};
