import { INVALID_TOKEN, UNAVAILABLE_TOKEN } from 'utils/constants';
import axios, { AxiosRequestConfig } from 'axios';

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE,
	headers: {
		Accept: '*/*',
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(async (request) => {
	if (process.env.REACT_APP_ENV === 'development') {
		if (['login', 'users', 'partners', 'members'].some((url) => request.url?.includes(url))) {
			return { ...request, baseURL: process.env.REACT_APP_API_BASE_USERS };
		}
	}
	return request;
});

api.interceptors.request.use(async (config) => {
	const headers = {
		Accept: '*/*',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
	};
	return { ...config, headers };
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (
			401 === error.response?.status ||
			error.response?.data?.error?.includes(INVALID_TOKEN) ||
			error.response?.data?.error === UNAVAILABLE_TOKEN
		) {
			localStorage.clear();
			window.parent.postMessage({ action: 'logout' }, '*');
			window.location.href = '/login';
		}
		return Promise.reject(error);
	}
);

export const getData = async (endpoint: string, options?: AxiosRequestConfig<any>) => {
	const { data } = await api.get(endpoint, options);
	return data;
};

export const getResponse = async (endpoint: string, options = {}) => {
	return await api.get(endpoint, options);
};

export const post = async (endpoint: string, options = {}) => {
	const response = await api.post(endpoint, options);
	return response?.data;
};

export const put = async (endpoint: string, options = {}) => {
	const response = await api.put(endpoint, options);
	return response?.data;
};

export const deleteData = async (endpoint: string, options = {}) => {
	const { data } = await api.delete(endpoint, options);
	return data;
};

export default api;
