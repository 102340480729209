import { createBody, defaultValues, validationSchema } from './utils';
import { useLocation, useParams } from 'react-router-dom';

import { EventIdParamsType } from 'utils/types';
import { Form } from 'features/common/components/Form';
import { MainLayout } from 'layouts/MainLayout';
import { StepCarousel } from './components/StepCarousel';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTicketsApi } from './hooks';
import { useToastNotification } from 'hooks/useToastNotification';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

export const CreateTicket = () => {
	const { t } = useTranslation();
	const { hash } = useLocation();
	const { showNotification, TYPES } = useToastNotification();
	const { eventId }: EventIdParamsType = useParams();

	const { isPunchup } = useAppSelector((state) => state.auth);

	const ticketFormMethods = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema(t)),
		mode: 'all',
	});

	const { event, mutateCreateTicket, mutateUploadImage } = useTicketsApi(ticketFormMethods);

	useEffect(() => {
		if (hash === '#4' || hash === '#6') ticketFormMethods.setValue('images', []);
	}, [hash]);

	const onSubmitTicketForm = (currentValues: any) => {
		if (isPunchup) {
			ticketFormMethods.setValue('images', [
				'https://imagedelivery.net/7Ze32-hXUdrEDVtqvlbDMQ/ff236a1f-6553-4ee7-4fc5-2aa551f1aa00/public',
			] as unknown as never[]);
			mutateCreateTicket(createBody(ticketFormMethods, Number(eventId), event?.time_zone || ''));
			return;
		}

		const imageUploadPromises = [];

		if (currentValues.images[0]) {
			imageUploadPromises.push(
				mutateUploadImage({
					image: currentValues.images[0],
					prefix: `${eventId}-${currentValues.name}`,
					index: 0,
					type: 'template',
				})
			);
		} else if (currentValues.thumbnail) {
			imageUploadPromises.push(
				mutateUploadImage({
					image: { url: '', base64: currentValues.thumbnail, name: '' },
					prefix: `${eventId}-${currentValues.name}`,
					index: 0,
					type: 'thumbnail',
				})
			);
		}

		Promise.all(imageUploadPromises)
			.then(() => mutateCreateTicket(createBody(ticketFormMethods, Number(eventId), event?.time_zone || '')))
			.catch(() =>
				showNotification({
					message: t('error-uploading-image'),
					type: TYPES.ERROR,
				})
			);
	};

	return (
		<Form formMethods={ticketFormMethods} onSubmit={onSubmitTicketForm}>
			<MainLayout>
				<StepCarousel />
			</MainLayout>
		</Form>
	);
};
