import styled, { css } from 'styled-components';

import { Button } from 'features/common/components/Button';
import { SectionTitleContainerProps } from './types';

export const SectionTitleContainer = styled.div<SectionTitleContainerProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	// TODO: Delete this prop (and the interface if there are not more props) when other screens's responsiveness are done
	${({ paddingVertical, theme }) =>
		paddingVertical
			? `
		padding: 20px 3% 20px 6%;
		@media screen and (max-width: ${theme.breakpoints.sm}) {
			padding: 20px 3%;
		}
		`
			: `
	padding: 20px 0
	`}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 11px;
`;

export const Title = styled.div<{ isPunchup?: boolean }>`
	display: flex;
	font-size: 25px;
	font-weight: 600;
	justify-content: space-between;
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.black : theme.colors.white)};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
	}
`;

export const SButton = styled(Button)<{ isPunchup?: boolean }>`
	width: fit-content;
	margin: 0;
	padding: 8px 41px;

	${({ isPunchup }) =>
		isPunchup &&
		css`
			color: #666666;
			border: 1px solid #666666;
		`}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 5px 11px;
		margin-bottom: 0px;
	}
`;
