import { Minus as MinusSvg, Plus as PlusSvg } from 'assets/customizedIcons';

import { IsThemed } from './types';
import styled from 'styled-components';

export const Container = styled.div<IsThemed>`
	margin: auto;
	box-sizing: border-box;
	align-items: center;
	background-color: ${({ isThemed, theme }) => (isThemed ? theme.colors.black : 'none')};
	border-radius: 4px;
	border: 1px solid ${({ isThemed, theme }) => (isThemed ? 'none' : theme.colors.lightGrey)};
	display: flex;
	width: fit-content;
	height: fit-content;
	padding: 6px 8px;
`;

export const StyledInput = styled.input<IsThemed>`
	all: unset;
	color: ${({ isThemed, theme }) => (isThemed ? theme.colors.black : theme.colors.black)};
	text-align: center;
	font-size: 1.25rem;
	font-family: ${({ theme }) => theme.fonts.generic};
	font-style: normal;
	font-weight: 600;
	width: 50px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&.small {
		font-size: 1rem;
	}
`;

export const PlusIcon = styled(PlusSvg)`
	cursor: pointer;
	height: 20px;
	width: 20px;
	user-select: none;
	fill: ${({ theme }) => theme.colors.black};
	stroke: ${({ theme }) => theme.colors.black};
`;

export const LessIcon = styled(MinusSvg)`
	cursor: pointer;
	height: 20px;
	width: 20px;
	user-select: none;
	fill: ${({ theme }) => theme.colors.black};
`;

export const CounterImage = styled.img`
	cursor: pointer;
`;
