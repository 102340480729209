import { PunchupSendTickets } from 'features/PunchupSendTickets';
import { SendTickets as SendTicketsComponent } from 'features/SendTickets';
import { useAppSelector } from 'store/hooks';

const SendTicketsPage = (): JSX.Element => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	if (isPunchup) {
		return <PunchupSendTickets />;
	}
	return <SendTicketsComponent />;
};
export default SendTicketsPage;
