import { EditEvent as EditEventComponent } from 'features/EditEvent';
import { PunchupEditEvent } from 'features/PunchupEditEvent/PunchupEditEvent';
import { useAppSelector } from 'store/hooks';

const EditEvent = (): JSX.Element => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	if (isPunchup) {
		return <PunchupEditEvent />;
	}
	return <EditEventComponent />;
};
export default EditEvent;
