import { Container, DesktopContainer, Icon, Option, OptionsContainer } from './LocalFilter.styles';

import { DesktopFilterProps } from './types';
import { FC } from 'react';
import { RadioButton } from 'features/common/components/RadioButton/RadioButton.styles';
import { useTranslation } from 'react-i18next';

const DesktopFilter: FC<DesktopFilterProps> = ({
	onClickSelect,
	isOpened,
	isLargeScreen,
	options,
	onClickOption,
	selectedOption,
	secondaryStyle,
	...rest
}: DesktopFilterProps) => {
	const { t } = useTranslation();

	const isCheckedOption = (option: string) => option === selectedOption?.value;

	return (
		<DesktopContainer isOpened={isOpened} onClick={onClickSelect} secondaryStyle={secondaryStyle} {...rest}>
			<Container secondaryStyle={secondaryStyle} isOpened={isOpened}>
				<div>{selectedOption?.label || t('events-filter')}</div>
				{!isOpened && <Icon iconName={secondaryStyle ? 'arrowDownGreen' : 'arrowDown'} />}
			</Container>

			<OptionsContainer isOpened={isOpened} isLargeScreen={isLargeScreen} secondaryStyle={secondaryStyle} {...rest}>
				{options.map((option) => (
					<Option key={option.value} onClick={() => onClickOption(option)}>
						<RadioButton
							type="radio"
							checked={isCheckedOption(option.value)}
							name={option.label}
							value={option.value}
							onClick={() => onClickOption(option)}
						/>
						{option.label}
					</Option>
				))}
			</OptionsContainer>
		</DesktopContainer>
	);
};

export { DesktopFilter };
