export const verifyNumberInput = (e: React.KeyboardEvent<HTMLElement>, isDecimal: boolean) => {
	const charCode = e.code;
	const charStr = e.key;
	if (
		!charStr.match(/^[0-9]+$/) &&
		charCode !== 'Backspace' &&
		charCode !== 'Tab' &&
		(isDecimal ? charCode !== 'Period' : true)
	)
		e.preventDefault();
	return { charCode, charStr };
};

export const allowsOnlyNumbers = (e: React.KeyboardEvent<HTMLElement>) => {
	verifyNumberInput(e, false);
};

export const allowsDecimals = (e: React.KeyboardEvent<HTMLElement>, price: number) => {
	const { charCode } = verifyNumberInput(e, true);
	if ((price * 10) % 1 !== 0 && charCode !== 'Backspace' && charCode !== 'Tab') e.preventDefault();
};
