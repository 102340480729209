import { ArrowIcon, GrayContainer } from './PunchupShiftTimeline.styles';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { GraphDisplayInfo } from '../types';

export const PunchupShiftTimeline = ({ setDisplay, displayWindow }: GraphDisplayInfo) => {
	return (
		<FlexContainer alignItems="center" gap="4px">
			<GrayContainer>
				<ArrowIcon disabled={displayWindow.windowStart === 0} onClick={() => setDisplay({ type: 'move_back' })} />
			</GrayContainer>
			<GrayContainer>
				<ArrowIcon
					disabled={displayWindow.windowStart + displayWindow.windowSize === displayWindow.dataPoints}
					onClick={() => setDisplay({ type: 'move_forward' })}
					isRight
				/>
			</GrayContainer>
		</FlexContainer>
	);
};
