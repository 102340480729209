import { AUTH, DEFAULT } from './constants';
import { Error, InfoInput, Input, InputContainer } from '../Form.styles';

import { FC } from 'react';
import { FormInputProps } from '../types';
import { InputLabel } from '../InputLabel';
import { LoginInput } from './LoginInput';
import { useFormContext } from 'react-hook-form';

const FormInput: FC<FormInputProps> = ({
	id,
	name,
	label,
	info,
	category = DEFAULT,
	promoCodeForm,
	type = 'text',
	isFromRequiredFields,
	errorProp,
	...rest
}: FormInputProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return category === AUTH ? (
		<LoginInput id={id ?? name} name={name} label={label} type={type} {...rest} />
	) : (
		<InputContainer hasError={Boolean(error) || Boolean(errorProp)} promoCodeForm={promoCodeForm} type={type}>
			{label && <InputLabel htmlFor={id ?? name} label={label} required={rest.required} />}
			{info && <InfoInput>{info}</InfoInput>}
			<Input
				id={id ?? name}
				{...register(name)}
				{...rest}
				type={type}
				className={isFromRequiredFields ? 'requiredFields' : ''}
			/>
			<Error>{error?.message || errorProp}</Error>
		</InputContainer>
	);
};

export { FormInput };
