import styled from 'styled-components';

export interface RowProps {
	padding?: string;
	height?: string;
	gridColumns: string;
	textAlign?: string;
}

export const Row = styled.tr<RowProps>`
	box-sizing: border-box;
	display: grid;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	padding: ${({ padding }) => padding || '0'};
	height: ${({ height }) => height || '48px'};
	text-align: ${({ textAlign }) => textAlign || 'left'};
	align-items: center;
`;

export const HeaderRow = styled(Row)``;

export const ColumnHeader = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-weight: 500;
	font-size: 14px;
	border: none;
`;

export const DataRow = styled(Row)`
	background-color: ${({ theme }) => theme.colors.whitePearl};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

	td {
		padding: 12px 16px;
		font-size: 14px;
		border: none;
		color: ${({ theme }) => theme.colors.grey8};
	}
`;

export const TableContainer = styled.div`
	width: 100%;
	border: none;
	border-radius: 4px;
	overflow: hidden;
	margin-top: 16px;

	table {
		border-collapse: collapse;
		width: 100%;
	}
`;

export const SearchContainer = styled.div`
	width: 100%;
	max-width: 300px;
	margin-bottom: 24px;
`;

export const DownloadButton = styled.button`
	background: transparent;
	border: 1.5px solid ${({ theme }) => theme.colors.grey6};
	color: ${({ theme }) => theme.colors.grey8};
	padding: 8px 24px;
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	transition: all 0.2s ease;

	&:hover {
		background: ${({ theme }) => theme.colors.grey15};
		border-color: ${({ theme }) => theme.colors.grey8};
	}
`;
