import * as Styled from './PunchupSendTicketsConfirmation.styles';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { PunchupRecipientInfo } from '../PunchupRecipientInfo';
import { RecipientInfoProps } from '../PunchupRecipientInfo/types';
import { calculateTotalPrice } from './utils';
import { toUsFormat } from 'utils/utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PunchupSendTicketsConfirmation = () => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { watch, setValue } = useFormContext();

	const recipientData = watch('recipients');

	const totalPrice = calculateTotalPrice(recipientData);

	useEffect(() => {
		setValue('totalPrice', totalPrice);
	}, [totalPrice]);

	return (
		<Styled.Root>
			<Styled.ConfirmationCard>
				<Styled.ConfirmationText>Confirmation</Styled.ConfirmationText>
				{recipientData?.map((el: JSX.IntrinsicAttributes & RecipientInfoProps, i: number) => (
					<PunchupRecipientInfo key={i} {...el} recipientId={i} />
				))}
				<Styled.Price>
					{t('send-tickets-price-label')}
					<strong>{toUsFormat(totalPrice) || 0}</strong>
				</Styled.Price>

				<FlexContainer justifyContent="center" gap="8px">
					<PunchupButton text={t('cancel')} type="button" variant="secondary" onClick={goBack} />
					<PunchupButton text="Send Tickets" type="submit" disabled={recipientData.length === 0} />
				</FlexContainer>
			</Styled.ConfirmationCard>
		</Styled.Root>
	);
};
