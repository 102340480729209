import { BROWSER_TZ } from './constants';
import { convertUTCToDateBarFormat } from 'utils/utils';

export const formatDate = (orderDate: string) => {
	const { date, time } = convertUTCToDateBarFormat(orderDate, BROWSER_TZ);
	return `${date} | ${time}`;
};

// Column width at index `responsesIdx` will be used for custom fields responses
export const getCellIndex = (index: number, headings: string[], responsesIdx: number, customHeadings: string[]) => {
	// If the table has no responses or index is less than `responsesIdx`, return same index
	if (headings.length === customHeadings.length || index <= responsesIdx) return index;
	// Last three columns are amount, date and ticket type
	if (index >= headings.length - 3) return index - (headings.length - customHeadings.length - 1);
	// For responses use always index `responsesIdx`
	return responsesIdx;
};
