import { useEffect, useState } from 'react';

import { AppDomainContext } from 'contexts/appDomain';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import { Loader } from 'features/common/animations/Loader';
import NotAuthenticatedRoutes from './NotAuthenticatedRoutes';
import { RootState } from 'store';
import { setIsPunchup } from 'store/slices/AuthSlice';
import { silentLogin } from 'services/auth';
import { useAppDispatch } from 'store/hooks';
import useLoading from 'hooks/useLoading';
import { useSelector } from 'react-redux';
import { useSetApp } from 'hooks/useSetApp';

export const Routes = (): JSX.Element => {
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	const { appDomain } = useSetApp();

	const dispatch = useAppDispatch();
	const isLoading = useLoading();
	const url = new URL(window.location.href);

	const [isPunchupLoading, setIsPunchupLoading] = useState(false);

	const punchupLoginCb = async (error: any) => {
		if (error) {
			dispatch(setIsPunchup(false));
		} else {
			dispatch(setIsPunchup(true));
		}
		setIsPunchupLoading(false);
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(url.search);

		const token = queryParams.get('token') || '';
		const partnerId = queryParams.get('partnerId') || '';
		const isPunchupParam = queryParams.get('isPunchup');
		if (isPunchupParam === 'true' && token && partnerId) {
			setIsPunchupLoading(true);
			localStorage.setItem('accessToken', token);
			dispatch(silentLogin(token, punchupLoginCb));
		} else if (isPunchupParam === 'false') {
			dispatch(setIsPunchup(false));
		}
	}, [url.search]);

	if (appDomain === null || isPunchupLoading) return <Loader />;

	return (
		<AppDomainContext.Provider value={appDomain}>
			{!!isLoading && <Loader />}
			{isAuthenticated ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />}
		</AppDomainContext.Provider>
	);
};
