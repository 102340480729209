import * as CONST from 'features/common/components/HighlightsCollection/constants';
import * as Styled from './PunchupSummaryCardSection.styles';

import { EventMode, IdParamsType } from 'utils/types';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { BoxProps } from 'features/common/components/HighlightsCollection/types';
import { EVENT_MODE_NORMAL } from 'utils/constants';
import { PunchupHighlightsCollection } from 'features/common/components/PunchupHighlightsCollection';
import { ROUTES } from 'features/routes';
import { useHighlights } from 'hooks/useHighlights';

const PunchupSummaryCardSection = ({ eventMode }: { eventMode: EventMode }) => {
	const { id } = useParams<IdParamsType>();
	const { eventHighlights } = useHighlights(id);
	const initialBoxCollection =
		eventMode === EVENT_MODE_NORMAL ? CONST.NORMAL_EVENT_ANALYTICS : CONST.REG_EVENT_ANALYTICS;
	const [boxCollection, setBoxCollection] = useState(initialBoxCollection);
	const { push } = useHistory();

	useEffect(() => {
		if (eventHighlights) {
			const boxCollections: BoxProps[] = [];
			for (let i = 0; i < boxCollection.length; i++) {
				const box = boxCollection[i];
				if (box.property === CONST.TOTAL_ATTENDEES) {
					box.stat = eventHighlights.total_attendees;
					box.onClick = () => push(generatePath(ROUTES.ATTENDEES, { id }));
				} else if (box.property === CONST.CHECK_INS) {
					box.stat = eventHighlights.nums_of_scans;
					box.onClick = () => push(generatePath(ROUTES.COMPED_TICKETS, { id }));
				} else if (box.property === CONST.PAGE_VIEWS) {
					box.stat = eventHighlights.page_views;
					delete box.onClick;
				} else if (box.property === CONST.SCAN_INS) {
					box.stat = eventHighlights.nums_of_scans;
					box.onClick = () => push(generatePath(ROUTES.COMPED_TICKETS, { id }));
				} else if (box.property === CONST.SHOW_RATE) {
					box.stat = eventHighlights.nums_of_scans / eventHighlights.total_attendees || 0;
					box.onClick = () => push(generatePath(ROUTES.COMPED_TICKETS, { id }));
				}
				boxCollections.push(box);
			}
			setBoxCollection(boxCollections);
		}
	}, [eventHighlights]);

	return (
		<Styled.SummaryCardsContainer>
			<PunchupHighlightsCollection boxCollection={boxCollection} />
		</Styled.SummaryCardsContainer>
	);
};

export default PunchupSummaryCardSection;
