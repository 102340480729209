import { ResponsiveContainer as UnstyledResponsiveContainer } from 'recharts';
import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const Text = styled(UnstyledText)`
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 500;
	font-size: 25px;
	line-height: 38px;
	color: ${({ theme }) => theme.colors.black};
`;

export const Amount = styled(Text)`
	color: ${({ theme }) => theme.colors.orange};
`;

export const Container = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding-bottom: 24px;
	padding: 16px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const ResponsiveContainer = styled(UnstyledResponsiveContainer)`
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const ContainerTooltip = styled.div`
	background: #f5f5f5;
	border-radius: 4px;
	padding: 5px 8px 5px 8px;
	border: ${({ theme }) => `0.5px solid ${theme.colors.lightGrey2}`};
	text-align: center;
`;

export const TextTooltip = styled(UnstyledText)`
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	display: flex;
	justify-content: center;
	color: ${({ theme }) => theme.colors.black};
`;

export const BottomInfo = styled(Text)`
	font-size: 16px;
	margin-bottom: 24px;
	line-height: 24px;
	margin-top: 16px;
	margin-left: 24px;
`;

export const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;
