import { ContainerProps, InputProps } from './types';

import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const Container = styled.div<ContainerProps>`
	box-sizing: border-box;
	max-width: ${({ width }) => width ?? '400px'};
	width: 100%;
	height: 38px;
	background: transparent;
	border: 1px solid ${({ theme }) => theme.colors.grey6};
	border-radius: 8px;
	display: flex;
	align-items: center;
	padding: 0 8px;
	transition: border-color 0.2s ease;

	&:focus-within {
		border-color: ${({ theme }) => theme.colors.grey8};
	}
`;

export const Icon = styled(UnstyledIcon)`
	color: ${({ theme }) => theme.colors.grey6};
	margin-right: 10px;
	opacity: 0.7;
`;

export const Input = styled.input<InputProps>`
	width: 100%;
	height: 100%;
	border: none;
	background: transparent;
	color: ${({ theme }) => theme.colors.grey8};
	font-weight: 400;

	&::placeholder {
		color: ${({ theme }) => theme.colors.grey6};
		opacity: 0.7;
	}

	&:focus {
		outline: none;
	}
`;
