import styled from 'styled-components';

export const Root = styled.div`
	min-height: 80vh;
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid ${({ theme }) => theme.colors.lightGrey7};
	padding: 32px 0;
	column-gap: 20px;
	justify-content: center;
	align-items: flex-start;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
		align-items: center;
		flex-direction: column;
	}
`;
