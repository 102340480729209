import { CustomButton, CustomFilterOption, ListContainer } from './EventDropdown.styles';
import { FilterButtonEnum, useFilterButtonClick } from 'features/common/hooks/useFilterButtonClick';
import { OUTLINED, PRIMARY } from 'features/common/components/Button/constants';
import { addShowAllClass, getAllEventsOption, lastIndicator } from '../../../../utils';

import { Event } from 'features/common/components/Filters/types';
import { EventDropdownProps } from '../../types';
import { FC } from 'react';
import { FilterButton } from '../../../../RTKFilter.styles';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OptionsContainer } from '../../DesktopFilters.styles';
import { useTranslation } from 'react-i18next';

export const EventDropdown: FC<EventDropdownProps> = ({ isOpened, closeFilter, eventQueryObj, perPageState, page }) => {
	const { t } = useTranslation();
	const { data } = eventQueryObj;
	const cancelClick = useFilterButtonClick(closeFilter, FilterButtonEnum.eventCancel, page);
	const { perPage, setPerPage } = perPageState;
	const applyClick = useFilterButtonClick(closeFilter, FilterButtonEnum.eventApply, page);

	return (
		<OptionsContainer isOpened={isOpened} onClick={(e) => e.stopPropagation()}>
			<FlexContainer gap="16px" flexDirection="column" margin="16px">
				<ListContainer className={addShowAllClass(perPage)}>
					<CustomFilterOption option={getAllEventsOption(t)} page={page} />
					{data?.map((event: Event, i: number, array: Event[]) => (
						<CustomFilterOption key={event.id} option={event} className={lastIndicator<Event>(i, array)} page={page} />
					))}
				</ListContainer>
				<CustomButton
					text={t('button-view-all')}
					category={OUTLINED}
					onClick={() => setPerPage('-1')}
					disabled={perPage === '-1'}
					type="button"
				/>
				<FlexContainer gap="16px" justifyContent="center">
					<FilterButton text={t('fanDatabase-cancel')} category={OUTLINED} onClick={cancelClick} type="button" />
					<FilterButton text={t('fanDatabase-apply')} category={PRIMARY} onClick={applyClick} type="button" />
				</FlexContainer>
			</FlexContainer>
		</OptionsContainer>
	);
};
