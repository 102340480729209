import { CardButton, Container, CustomIcon, Label, Stat } from './PunchupHighlightCard.styles';

import { HighlightCardProps } from './types';
import { Loader2 } from 'lucide-react';
import { PercentPieChart } from 'features/common/components/PieChart';
import { SHOW_RATE } from '../constants';
import { common } from 'theme';

export const convertToNum = (stat: string | number) => {
	if (typeof stat === 'string') {
		return parseFloat(stat);
	}
	return stat;
};

export const PunchupHighlightCard = ({
	property,
	icon,
	stat,
	cardLabel,
	buttonLabel,
	buttonDisabled,
	isLoading,
	onClick,
	dataCy,
}: HighlightCardProps) => {
	return property === SHOW_RATE ? (
		<Container noGap data-cy={dataCy}>
			<Label>{cardLabel}</Label>
			<PercentPieChart
				color={common.colors.green}
				sizes={{ fontSize: 36, iconSize: 164 }}
				rawPercentage={convertToNum(stat)}
			/>
		</Container>
	) : (
		<Container data-cy={dataCy}>
			<CustomIcon iconName={icon} />
			<Stat>{isLoading ? <Loader2 size={18} className="animate-spin" /> : stat}</Stat>
			<Label>{cardLabel}</Label>
			{buttonLabel && onClick && (
				<CardButton
					text={buttonLabel}
					onClick={onClick}
					category="quaternary"
					data-cy={`${dataCy}-btn`}
					disabled={buttonDisabled}
				/>
			)}
		</Container>
	);
};
