import { CompedTickets as CompedTicketsComponent } from 'features/CompedTickets';
import { PunchupCompedTickets } from 'features/PunchupCompedTickets';
import { useAppSelector } from 'store/hooks';

const CompedTicketsPage = (): JSX.Element => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	if (isPunchup) {
		return <PunchupCompedTickets />;
	}
	return <CompedTicketsComponent />;
};
export default CompedTicketsPage;
