import { ButtonsContainer, CustomFieldsForm, StyledButton } from './CustomFields.styles';
import { CustomFieldFormProps, CustomFieldsProps } from './types';
import { defaultValues, schema } from './utils';

import { CustomFieldsHeader } from './components/CustomFieldsHeader';
import { MainLayout } from 'layouts/MainLayout';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { QuestionForm } from './components/QuestionForm';
import { RightForm } from './components/RightForm';
import { RootState } from 'store';
import { useCustomFieldsApi } from './useCustomFieldsApi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

export const CustomFields = ({ editMode = false }: CustomFieldsProps) => {
	const { t } = useTranslation();
	const { goBack } = useHistory();
	const { isPunchup } = useSelector((state: RootState) => state.auth);

	const formMethods = useForm<CustomFieldFormProps>({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { event, createCustomField, updateCustomField, deleteCustomField } = useCustomFieldsApi(formMethods.reset);

	useEffect(() => {
		if (event) {
			formMethods.setValue('ticket_types_ids', event.ticket_types.map((ticket) => ticket.ID) ?? []);
		}
	}, [event]);

	const handleSubmit = (values: CustomFieldFormProps) => {
		if (editMode) updateCustomField(values);
		else createCustomField(values);
	};

	if (!event) return null;

	return (
		<MainLayout>
			{!isPunchup && <CustomFieldsHeader title={event.name ?? ''} editMode={!!editMode} onDelete={deleteCustomField} />}

			<CustomFieldsForm formMethods={formMethods} onSubmit={handleSubmit} isPunchup={isPunchup}>
				<QuestionForm />

				<RightForm event={event} />

				<ButtonsContainer isPunchup={isPunchup}>
					{!isPunchup ? (
						<>
							<StyledButton text={t('button-cancel')} type="button" onClick={goBack} category="outlined" />
							<StyledButton
								text={t(editMode ? 'save' : 'add')}
								type="submit"
								disabled={formMethods.watch('ticket_types_ids').length == 0}
							/>
						</>
					) : (
						<>
							<PunchupButton text={t('button-cancel')} type="button" onClick={goBack} variant="secondary" />
							<PunchupButton
								text={t(editMode ? 'save' : 'add')}
								type="submit"
								disabled={formMethods.watch('ticket_types_ids').length == 0}
							/>
						</>
					)}
				</ButtonsContainer>
			</CustomFieldsForm>
		</MainLayout>
	);
};
