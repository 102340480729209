import * as Styled from './EventInfo.styles';

import { FC, memo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Button } from 'features/common/components/Button';
/*
Needed if we comment out in person tickets too
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
*/
import { DuplicateEvent } from './services';
import { EventInfoProps } from './types';
import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { SECONDARY } from 'features/common/components/Button/constants';
import { ZERO_TIME } from 'utils/constants';
import { useMutation } from '@tanstack/react-query';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useToastNotification } from 'hooks/useToastNotification';
import { useTranslation } from 'react-i18next';

const EventInfo: FC<EventInfoProps> = ({
	address,
	date,
	endTime,
	eventTitle,
	onSaleDate,
	startTime,
}: EventInfoProps) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	/* Needed if we comment out in person tickets too
	const { ticketsAvailable } = useAppSelector(({ boxOffice }: RootState) => boxOffice);
	*/
	const { id: eventId }: IdParamsType = useParams();
	const isLargeScreen = useResponsiveScreen();

	const { showNotification, TYPES } = useToastNotification();

	const zeroTime = onSaleDate === ZERO_TIME;

	const { mutate: mutateDuplicateEvent } = useMutation(DuplicateEvent, {
		onSuccess: ({ data }) => {
			push(generatePath(ROUTES.DASHBOARD_PATH, { id: data.ID }));
		},
		onError: () => {
			showNotification({
				type: TYPES.ERROR,
				message: t('dashboard-duplicateEvent-error'),
			});
		},
	});

	const onClickEdit = () => push(ROUTES.EDIT_EVENT_PATH.replace(':id', eventId));

	const duplicateEvent = () => {
		mutateDuplicateEvent({ eventId });
	};

	return (
		<Styled.Container>
			<Styled.InnerContainer>
				<Styled.InfoContainer>
					{!isLargeScreen && <Button text={'Edit Details'} category={SECONDARY} onClick={onClickEdit} type="button" />}
					<Styled.Title>
						{eventTitle}
						{isLargeScreen && <Styled.Icon iconName="pencil" onClick={onClickEdit} data-cy="edit-event" />}
					</Styled.Title>
					<Styled.Text>{date}</Styled.Text>
					<Styled.Text>
						{startTime} - {endTime}
					</Styled.Text>
					<Styled.Text>{address}</Styled.Text>
					{!zeroTime && <Styled.Text>{`${t('generic-onSale')} ${onSaleDate}`}</Styled.Text>}
				</Styled.InfoContainer>

				<Styled.OutlinedButtonContainer>
					{/* 
					Ideally this one will be enabled per partner, before that we need to fix this to use static or dynamic
					Today is sending always dynamic tickets.
					{ticketsAvailable && (
						<Styled.OutlinedButton
							text={t('dashboard-inPersonTickets')}
							type="button"
							category="outlined"
							onClick={() => push(generatePath(ROUTES.BOX_OFFICE, { id: eventId }))}
						/>
					)} */}

					<Styled.OutlinedButton
						text={t('dashboard-duplicateEvent')}
						type="button"
						category="outlined"
						onClick={duplicateEvent}
					/>

					<Styled.OutlinedLinkButton
						href={`${process.env.REACT_APP_API_BASE}/public/ticketing/events/${eventId}/qr`}
						download
					>
						{t('dashboard-downloadQR')}
					</Styled.OutlinedLinkButton>
				</Styled.OutlinedButtonContainer>
			</Styled.InnerContainer>
		</Styled.Container>
	);
};

const memorizedEventInfo: FC<EventInfoProps> = memo(EventInfo);

export { memorizedEventInfo as EventInfo };
