import 'react-datepicker/dist/react-datepicker.css';

import * as Styled from './PunchupDateTimePicker.styles';

import DatePicker from 'react-datepicker';
import { FC } from 'react';
import { FlexContainer } from '../../FlexContainer/FlexContainer';
import { useFormContext } from 'react-hook-form';

interface PunchupDateTimePickerProps {
	id: string;
	name: string;
	label?: string;
	required?: boolean;
	onChange?: (date: Date) => void;
	minDate?: Date;
	minTime?: Date;
	maxDate?: Date;
	maxTime?: Date;
}

export const PunchupDateTimePicker: FC<PunchupDateTimePickerProps> = ({
	id,
	name,
	label,
	required,
	onChange,
	minDate,
	minTime,
	maxDate,
	maxTime,
}) => {
	const {
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();

	const value = watch(name);

	const error = errors[name]?.message;

	const handleChange = (date: Date) => {
		setValue(name, date);
		onChange?.(date);
	};

	const parsedValue = value ? new Date(value) : null;

	return (
		<FlexContainer flexDirection="column" boxSizing="border-box" marginBottom="12px" flex="1">
			{label && (
				<Styled.Label htmlFor={id}>
					{label}
					{required === false && <Styled.OptionalSpan>Optional</Styled.OptionalSpan>}
				</Styled.Label>
			)}
			<Styled.InputContainer hasError={Boolean(error)}>
				<DatePicker
					id={id}
					name={name}
					selected={parsedValue}
					onChange={handleChange}
					showTimeSelect
					timeIntervals={30}
					dateFormat="MM/dd/yyyy h:mm aa"
					placeholderText="mm/dd/yyyy h:mm"
					timeCaption="Time"
					autoComplete="off"
					className="punchup-datepicker"
					minDate={minDate}
					minTime={minTime}
					maxDate={maxDate}
					maxTime={maxTime}
				/>
				<Styled.CalendarIcon iconName="calendar" />
			</Styled.InputContainer>
			{error && <Styled.Error>{error}</Styled.Error>}
		</FlexContainer>
	);
};
