import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 15px;
	overflow: hidden;
	width: 100%;
`;

export const CustomFlexContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 40px 3% 40px 6%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 12px 0;
	}
`;

export const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const Buttons = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const CsvButton = styled.button`
	border: 1px solid ${({ theme }) => theme.colors.grey20};
	color: ${({ theme }) => theme.colors.grey20};
	font-family: ${({ theme }) => theme.fonts.primary};
	text-decoration: auto;
	background: transparent;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	height: 38px;
	width: 150px;
	display: flex;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	align-items: center;
	justify-content: center;
`;
