import { ButtonProps, CellProps, RowProps, TextProps } from './types';

import { Button } from 'features/common/components/Button';
import styled from 'styled-components';

export const TableContainer = styled.div`
	overflow-x: auto;
	box-sizing: border-box;
	width: 91%;
	margin: 40px 3% 40px 6%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin: 0;
		width: 100%;
	}
`;

export const HTMLTable = styled.table`
	border-collapse: collapse;
	width: 100%;
`;

export const Row = styled.tr<RowProps>`
	box-sizing: border-box;
	display: grid;
	padding: ${({ padding }) => padding || '0 36px'};
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	height: ${({ height }) => height || '64px'};
	text-align: ${({ textAlign }) => textAlign};
`;

export const HeaderRow = styled(Row)<RowProps>`
	background-color: ${({ theme }) => theme.colors.blackGrey4};
`;

export const DataRow = styled(Row)`
	color: ${({ theme }) => theme.colors.lightGrey10};
	&:nth-child(odd) {
		background-color: ${({ theme }) => theme.colors.blackGrey5};
	}
	&:nth-child(even) {
		background-color: ${({ theme }) => theme.colors.darkGrey4};
	}
	&:hover,
	&.selected {
		background-color: ${({ theme }) => theme.colors.grey12};
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const Cell = styled.td<CellProps>`
	align-self: center;
	box-sizing: border-box;
	text-align: ${({ textAlign }) => textAlign || 'left'};
	padding: ${({ padding }) => padding || '0 12px'};
	font-weight: ${({ fontWeight }) => fontWeight || '400'};
	font-size: ${({ fontSize }) => fontSize || '16px'};
	min-width: ${({ minWidth }) => `${minWidth}px`};
	overflow: ${({ overflow }) => overflow || 'hidden'};
	white-space: nowrap;
	text-overflow: ellipsis;

	${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		&.desktopOnly {
			display: none;
		}
	}

	&.statusCell {
		min-width: ${({ minWidth }) => `${minWidth}px`};
		border-radius: 4px;
		display: flex;
		color: white;
		justify-content: center;
		padding: 6px;
	}
`;

export const ColumnHeader = styled.h3<TextProps>`
	margin: 0;
	transform: capitalize;
	align-self: center;
	padding: ${({ padding }) => padding || '0'};
	font-weight: ${({ fontWeight }) => fontWeight || '600'};
	font-size: ${({ fontSize }) => fontSize || '18px'};
	color: ${({ theme }) => theme.colors.white};
`;

export const CustomButton = styled(Button)<ButtonProps>`
	margin: 0;
	padding: 8px 28px;
	width: ${({ width }) => width};
	font-weight: 600;
	font-size: 16px;
	height: 40px;
	color: ${({ theme }) => theme.colors.lightGrey9};
	border: solid 1px ${({ theme }) => theme.colors.lightGrey9};
	&:disabled {
		display: ${({ displayIfDisabled }) => displayIfDisabled || 'none'};
	}
`;
