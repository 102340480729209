import * as Styled from './LeftForm.styles';

import { allowsDecimals, allowsOnlyNumbers } from 'utils/numberInput';

import { Form } from 'features/common/components/Form';
import { PunchupDateTimePicker } from 'features/common/components/Form/PunchupDateTimePicker/PunchupDateTimePicker';
import { PunchupToggle } from 'features/common/components/Form/PunchupToggle/PunchupToggle';
import { convertUTCToDate } from 'utils/utils';
import moment from 'moment';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useGetEvent from 'features/common/hooks/useGetEvent';
import { useTranslation } from 'react-i18next';

const LeftForm: React.FC = () => {
	const { t } = useTranslation();
	const { event } = useGetEvent();
	const { watch, setValue } = useFormContext();

	const price: number = watch('initialPrice');

	useEffect(() => {
		if (event?.endDate && event?.timeZone && !watch('offSellingTime')) {
			const offSellingTime = moment(convertUTCToDate(`${event.endDate}`, event.timeZone));
			setValue('offSellingTime', offSellingTime);
		}
	}, [event]);

	return (
		<>
			<Form.PunchupInput id="name" name="name" label={t('ticketDetails-ticket-type-name')} />

			<Styled.WrapperInputs>
				<Form.PunchupInput
					type="number"
					id="initialPrice"
					name="initialPrice"
					label={t('ticketDetails-ticket-price')}
					onKeyDown={(e) => allowsDecimals(e, price)}
					min={0}
					step=".01"
				/>
				<Form.PunchupInput
					type="number"
					id="amount"
					name="amount"
					label={t('ticketDetails-quantity')}
					onKeyDown={allowsOnlyNumbers}
					min={0}
					step={1}
				/>
			</Styled.WrapperInputs>

			<Form.PunchupTextArea
				id="description"
				name="description"
				label={t('ticketDetails-ticket-description')}
				required={false}
			/>

			<PunchupDateTimePicker id="sellingTime" name="sellingTime" label={t('createEvent-tickets-onSale')} />

			<PunchupDateTimePicker
				id="offSellingTime"
				name="offSellingTime"
				label={t('createEvent-tickets-offSale')}
				required={false}
			/>

			<PunchupToggle htmlFor="ticketVisibility" name="ticketVisibility" label={t('ticketDetails-ticket-visbility')} />
		</>
	);
};

export { LeftForm };
