import { AddressComponents, FormInputProps } from './types';
import { ChangeEvent, FC } from 'react';
import { Error, Icon, Input, InputContainer, RelativeDiv } from './Form.styles';

import { InputLabel } from './InputLabel';
import { useFormContext } from 'react-hook-form';
import { usePlacesWidget } from 'react-google-autocomplete';

const AddressAutocomplete: FC<FormInputProps> = ({ id, name, label, ...rest }: FormInputProps) => {
	const {
		clearErrors,
		setValue,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];
	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_MAPS_API_KEY,
		onPlaceSelected: (place) => {
			const address = place.address_components?.reduce(
				(acc: AddressComponents, component: any) => {
					if (component.types.includes('locality')) {
						acc.city = component.long_name;
					} else if (component.types.includes('administrative_area_level_1')) {
						acc.state = component.long_name;
					}
					return acc;
				},
				{ city: '', state: '' }
			);
			setValue('location', place.formatted_address);
			setValue('city', address?.city || '');
			setValue('state', address?.state || '');
		},
		options: { types: ['address'] },
		inputAutocompleteValue: 'off',
	});

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		clearErrors(id ?? name);
		setValue(id ?? name, e.target.value);
	};

	return (
		<InputContainer className="relative" hasError={Boolean(error)}>
			<InputLabel htmlFor={id ?? name} label={label} />
			<RelativeDiv>
				<Input className="padding" {...rest} id={id ?? name} ref={ref as any} onChange={onChange} data-cy="location" />
				<Icon iconName="locationPin" />
			</RelativeDiv>
			<Error>{error?.message}</Error>
		</InputContainer>
	);
};

export { AddressAutocomplete };
