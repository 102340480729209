import { Container, DesktopContainer, Icon } from './DropdownContainer.styles';

import { DropdownContainerProps } from './types';
import { EllipsisVertical } from 'lucide-react';
import { FC } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

export const DropdownContainer: FC<DropdownContainerProps> = ({
	isOpened,
	clickHandler,
	filterName,
	secondaryStyle = false,
	iconName = secondaryStyle ? 'arrowDownGrey' : 'arrowDown',
	width,
	refProp,
	children,
}) => {
	const { isPunchup } = useSelector((state: RootState) => state.auth);

	return (
		<DesktopContainer
			isOpened={isOpened}
			onClick={clickHandler}
			ref={refProp}
			width={width}
			secondaryStyle={secondaryStyle}
			isPunchup={isPunchup}
		>
			{!isPunchup ? (
				<Container>
					<span>{filterName}</span>
					{!isOpened && <Icon iconName={iconName} />}
				</Container>
			) : (
				<EllipsisVertical color="black" />
			)}
			{children}
		</DesktopContainer>
	);
};
