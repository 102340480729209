import { CollectionContainer } from './PunchupHighlightsCollection.styles';
import { FC } from 'react';
import { PunchupHighlightCard } from './PunchupHighlightCard';
import { PunchupHighlightsCollectionProps } from './types';

export const PunchupHighlightsCollection: FC<PunchupHighlightsCollectionProps> = ({ boxCollection }) => (
	<CollectionContainer>
		{boxCollection.map((box) => (
			<PunchupHighlightCard
				key={box.property}
				property={box.property}
				icon={box.icon}
				stat={box.stat}
				cardLabel={box.cardLabel}
				buttonLabel={box.buttonLabel}
				onClick={box.onClick}
				dataCy={box.dataCy}
			/>
		))}
	</CollectionContainer>
);
