import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const Container = styled.div<{ noGap?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 12px 12px;
	background-color: #f8f8f8;
	border-radius: 8px;
`;

export const CustomIcon = styled(Icon)`
	&& {
		font-size: 20px;
		margin-bottom: 12px;
		width: 24px;
		height: 24px;
		filter: brightness(0);
		opacity: 0.9;
	}
`;

export const Stat = styled.div`
	font-size: 18px;
	font-weight: 500;
	color: #000;
	margin-bottom: 0;

	.animate-spin {
		animation: spin 1s linear infinite;
	}
`;

export const Label = styled.div`
	font-size: 12px;
	color: #666;
	text-align: center;
	margin-bottom: 8px;
`;

export const CardButton = styled(Button)`
	&& {
		width: calc(100% - 8px);
		min-height: 24px;
		height: 24px;
		max-height: 32px;
		line-height: 24px;
		background-color: #e2e2e2;
		color: #666;
		font-size: 10px;
		border-radius: 10px;
		padding: 0 12px;
		font-weight: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 6px 0 0;

		&:hover:enabled {
			background-color: #d5d5d5;
		}

		&:disabled {
			background-color: #e2e2e2;
			color: #666;
			cursor: not-allowed;
		}
	}
`;
