import { ResponsiveContainer as UnstyledResponsiveContainer } from 'recharts';
import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const Text = styled(UnstyledText)`
	display: flex;
	gap: 10px;
	font-weight: 500;
	font-size: 25px;
	line-height: 38px;
	margin-bottom: 5px;
	color: ${({ theme }) => theme.colors.black};
`;

export const TextTooltip = styled(UnstyledText)`
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
`;

export const Amount = styled(Text)`
	color: ${({ theme }) => theme.colors.orange};
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: auto;
	margin-bottom: 10px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;

export const ContainerTooltip = styled.div`
	background: ${({ theme }) => theme.colors.grey};
	border-radius: 4px;
	padding: 5px 8px 5px 8px;
	border: ${({ theme }) => `0.5px solid ${theme.colors.lightGrey2}`};
	text-align: center;
`;

export const ResponsiveContainer = styled(UnstyledResponsiveContainer)`
	font-family: ${({ theme }) => theme.fonts.primary};

	> div > svg > path {
		fill: transparent;
	}

	> div > div > ul > li > svg > path {
		d: path('M 0 4 h 23 v 23 h -32 Z');
	}
`;

export const BottomInfo = styled(Text)`
	margin-right: 130px;
	font-size: 16px;
	margin-bottom: 37px;
	line-height: 24px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-right: 0px;
	}
`;

export const DescriptionContainer = styled.div`
	display: flex;
	margin-top: 50px;
	margin-left: 50px;
	flex-direction: row;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-top: 0px;
		margin-left: 24px;
		flex-direction: column;
	}
`;
