import * as Styled from './TicketDetails.styles';

import { ButtonContainer, SubmitButton } from '../Steps.styles';
// TODO: Uncomment when backend is ready
// import { SecondarySales } from '../../SecondarySales';
// TODO: Uncomment when Fees are ready
// import { Fees } from '../../Fees';
import { allowsDecimals, allowsOnlyNumbers } from 'utils/numberInput';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { ButtonGroup } from 'features/common/components/Form/PunchupModal/PunchupModal.styles';
import { EventIdParamsType } from 'utils/types';
import { Form } from 'features/common/components/Form';
import { Page } from 'features/common/components/Carousel';
import { PunchupButton } from 'features/common/components/Form/PunchupButton/PunchupButton';
import { PunchupDateTimePicker } from 'features/common/components/Form/PunchupDateTimePicker/PunchupDateTimePicker';
import { PunchupModal } from 'features/common/components/Form/PunchupModal/PunchupModal';
import { PunchupToggle } from 'features/common/components/Form/PunchupToggle/PunchupToggle';
import { ROUTES } from 'features/routes';
import { Text } from 'features/common/components/Text';
import { handleLink } from 'utils/handleLink';
import { useAppSelector } from 'store/hooks';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { useQuery } from '@tanstack/react-query';

// import { EventIdParamsType } from 'utils/types';
// import { getData } from 'services/api';
// import { handleTotalPrice } from './utils';

export const TicketDetails = () => {
	const { t } = useTranslation();
	const { hash } = useLocation();
	const { eventId }: EventIdParamsType = useParams();
	const { isPunchup } = useAppSelector((state) => state.auth);
	const { push } = useHistory();

	const {
		formState: { errors },
		getValues,
		trigger,
		watch,
	} = useFormContext();

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const isTicketDetails = useMemo(() => hash === '#2' || hash === '#3', [hash]);
	const price: number = watch('initialPrice');

	// const { data: taxesData } = useQuery({
	// 	queryKey: ['ticketTaxes'],
	// 	queryFn: () => getData(`/public/ticketing/events/taxes/${eventId}`),
	// 	enabled: hash === '#2',
	// });

	const onClick = () => {
		const { name, initialPrice, amount } = getValues();
		let isMissedValue = false;
		if (name === '') {
			trigger('name');
			isMissedValue = true;
		}
		if (initialPrice === null) {
			trigger('initialPrice');
			isMissedValue = true;
		}
		if (amount === null) {
			trigger('amount');
			isMissedValue = true;
		}

		if (!isMissedValue) handleLink('#3');
	};

	const handleSkip = () => {
		setShowConfirmationModal(false);
		push(generatePath(ROUTES.DASHBOARD_PATH, { id: eventId }));
	};

	const handleAddMore = () => {
		setShowConfirmationModal(false);
	};

	const punchupCancel = () => {
		handleLink('#1');
	};

	if (isPunchup) {
		return (
			<Page id="2">
				<Styled.Column>
					<Styled.FieldSet>
						{showConfirmationModal && (
							<PunchupModal title="Your Ticket has been created">
								<ButtonGroup>
									<PunchupButton
										type="button"
										variant="secondary"
										onClick={handleSkip}
										text="Skip"
										disabled={Object.keys(errors).length > 0}
									/>
									<PunchupButton
										type="button"
										variant="primary"
										onClick={handleAddMore}
										text="Add more tickets"
										disabled={Object.keys(errors).length > 0}
									/>
								</ButtonGroup>
							</PunchupModal>
						)}
						<Form.PunchupInput name="name" id="name" label="Ticket Name" />
						{/* TODO: Uncomment when backend is ready */}
						{/* <SecondarySales /> */}
						<Form.PunchupInput
							type="number"
							inputMode="numeric"
							id="initialPrice"
							name="initialPrice"
							label={t('createEvent-tickets-ticketPrice')}
							onKeyDown={(e) => allowsDecimals(e, price)}
							placeholder="$"
							step=".01"
							min={0}
						/>
						<Form.PunchupInput
							type="number"
							inputMode="numeric"
							id="amount"
							name="amount"
							label={t('createEvent-tickets-amount')}
							onKeyDown={allowsOnlyNumbers}
							min={0}
						/>
						<Form.PunchupTextArea
							id="description"
							name="description"
							label={t('ticketDetails-ticket-description')}
							required={false}
						/>
						<PunchupDateTimePicker id="sellingTime" name="sellingTime" label={t('createEvent-tickets-onSale')} />
						<PunchupDateTimePicker
							id="offSellingTime"
							name="offSellingTime"
							label={t('createEvent-tickets-offSale')}
							required={false}
						/>
						<PunchupToggle
							htmlFor="ticketVisibility"
							name="ticketVisibility"
							label={t('ticketDetails-ticket-visbility')}
						/>
					</Styled.FieldSet>

					<ButtonContainer>
						<PunchupButton
							type="button"
							variant="secondary"
							onClick={punchupCancel}
							text="Cancel"
							disabled={Object.keys(errors).length > 0}
						/>
						<PunchupButton type="submit" variant="primary" text="Create" disabled={Object.keys(errors).length > 0} />
					</ButtonContainer>
				</Styled.Column>
			</Page>
		);
	}

	return (
		<Page id="2" className="no-padding">
			<Styled.Column>
				{isTicketDetails && (
					<Styled.ScreenTitle>
						<Text>{t('createEvent-tickets-title')}</Text>
						<Styled.Subtitle>{t('createEvent-tickets-subtitle')}</Styled.Subtitle>
					</Styled.ScreenTitle>
				)}
				<Styled.FieldSet>
					<Form.Input name="name" id="name" label="Ticket Name" />
					{/* TODO: Uncomment when backend is ready */}
					{/* <SecondarySales /> */}
					<Form.Input
						type="number"
						inputMode="numeric"
						id="initialPrice"
						name="initialPrice"
						label={t('createEvent-tickets-ticketPrice')}
						onKeyDown={(e) => allowsDecimals(e, price)}
						placeholder="$"
						step=".01"
						min={0}
					/>
					<Form.Input
						type="number"
						inputMode="numeric"
						id="amount"
						name="amount"
						label={t('createEvent-tickets-amount')}
						onKeyDown={allowsOnlyNumbers}
						min={0}
					/>
					<Form.InputText id="description" name="description" label={t('ticketDetails-ticket-description')} />
					<Form.InputDateAndTime id="sellingTime" name="sellingTime" label={t('createEvent-tickets-onSale')} />
					{/* TODO: Uncomment when fees are ready */}
					{/* <Fees /> */}
					{/* <Styled.TotalPriceContainer>
						{taxesData && <Styled.TotalPrice>${handleTotalPrice({ watch, taxesData })}</Styled.TotalPrice>}
						<Styled.TotalPriceInfo>{t('ticketDetails-priceBuyerPays')}</Styled.TotalPriceInfo>
					</Styled.TotalPriceContainer> */}
					<Form.InputDateAndTime id="offSellingTime" name="offSellingTime" label={t('createEvent-tickets-offSale')} />
					<Form.InputToggle
						htmlFor="ticketVisibility"
						name="ticketVisibility"
						label={t('ticketDetails-ticket-visbility')}
					/>
				</Styled.FieldSet>
				<ButtonContainer>
					<SubmitButton type="button" onClick={onClick} text="Continue" disabled={Object.keys(errors).length > 0} />
				</ButtonContainer>
			</Styled.Column>
		</Page>
	);
};
