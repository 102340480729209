import * as Styles from './EntriesTable.styles';

import { COLUMN_MAX_WIDTH, COLUMN_MIN_WIDTH, LOTTERY_COLUMN_HEADINGS, RESPONSES_INDEX } from './constants';

import { EntriesProps } from 'features/LotteryEntries/types';
import { FC } from 'react';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { Table } from 'features/common/components/Table';
import { TableBody } from './TableBody';
import { getCellIndex } from './utils';
import { useTranslation } from 'react-i18next';

export const EntriesTable: FC<EntriesProps> = ({ entriesQueryObject }) => {
	const { t, i18n } = useTranslation();
	const { fetchNextPage, hasNextPage } = entriesQueryObject;

	const headings = LOTTERY_COLUMN_HEADINGS;
	const customHeadings = LOTTERY_COLUMN_HEADINGS;

	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Styles.HeaderRow gridColumns={`repeat(${headings.length}, 1fr)`} textAlign="center">
							{headings.map((heading, i) => (
								<Table.Cell
									key={heading}
									minWidth={COLUMN_MIN_WIDTH[getCellIndex(i, headings, RESPONSES_INDEX, customHeadings)]}
									maxWidth={COLUMN_MAX_WIDTH[getCellIndex(i, headings, RESPONSES_INDEX, customHeadings)]}
								>
									<Table.ColumnHeader>{i18n.exists(heading) ? t(heading) : heading}</Table.ColumnHeader>
								</Table.Cell>
							))}
						</Styles.HeaderRow>
					</thead>

					<TableBody entriesQueryObject={entriesQueryObject} columnsN={headings.length} />
				</Table>
			</Table.TableContainer>
			<FlexContainer justifyContent="center" margin="32px 0">
				<Table.Button
					category={OUTLINED}
					text={t('button-load-more')}
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage}
					type="button"
				/>
			</FlexContainer>
		</>
	);
};
