import { Button, FooterContainer } from './Footer.styles';

import { FooterProps } from './types';
import { useAppSelector } from 'store/hooks';

const Footer = ({ children }: FooterProps) => {
	const { isPunchup } = useAppSelector((state) => state.auth);

	return <FooterContainer isPunchup={isPunchup}>{children}</FooterContainer>;
};

Footer.Button = Button;
export { Footer };
