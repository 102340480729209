import { LoaderContainer, StyledLoader } from './Loader.styles';

import { useAppSelector } from 'store/hooks';

const Loader = () => {
	const { isPunchup } = useAppSelector((state) => state.auth);
	return (
		<LoaderContainer>
			<StyledLoader isPunchup={isPunchup} />
		</LoaderContainer>
	);
};

export { Loader };
