import * as Styled from './PunchupRecipientInfo.styles';

import { RecipientInfoProps } from './types';
import { toUsFormat } from 'utils/utils';
import { useFormContext } from 'react-hook-form';

export const PunchupRecipientInfo = ({ ticketTypes, recipientId }: RecipientInfoProps) => {
	const { watch } = useFormContext();
	const email = watch(`recipients[${recipientId}].email`);
	const day = watch('day.label');
	const time = watch('time.label');
	return (
		<Styled.RecipientInfo>
			<Styled.Info>{email ? `${email}:` : 'No email'} </Styled.Info>
			{day && time && (
				<Styled.Info>
					<strong>{day}</strong> {time}
				</Styled.Info>
			)}

			{ticketTypes.map(({ type, initialPrice, quantity }) =>
				quantity > 0 ? (
					<Styled.Info key={type}>
						{quantity} x {type} <strong>{toUsFormat(initialPrice * quantity)}</strong>
					</Styled.Info>
				) : null
			)}
		</Styled.RecipientInfo>
	);
};
