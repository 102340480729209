import { ButtonContainer, Row, SButton, SectionTitleContainer, Title } from './MultiButtonSectionTitle.styles';

import { Divider } from 'features/common/components/Divider';
import { FC } from 'react';
import { TicketsSectionTitleProps } from '../../../Dashboard/components/TicketsSection/types';
import { useAppSelector } from 'store/hooks';
import { useResponsiveScreen } from 'hooks/useWindowSize';

const MultiButtonSectionTitle: FC<TicketsSectionTitleProps> = ({
	title,
	buttons,
	dataCy,
}: TicketsSectionTitleProps) => {
	const isLargeScreen = useResponsiveScreen();
	const { isPunchup } = useAppSelector((state) => state.auth);

	return (
		<SectionTitleContainer data-cy={dataCy}>
			<Row>
				<Title isPunchup={isPunchup}>{title}</Title>
				<ButtonContainer>
					{buttons.map(({ label, onClick, category = 'outlined', hidden = false, dataCy }) => (
						<SButton
							key={label}
							onClick={onClick}
							text={label}
							category={category}
							type="button"
							hidden={hidden}
							data-cy={dataCy}
							isPunchup={isPunchup}
						/>
					))}
				</ButtonContainer>
			</Row>
			{isLargeScreen && <Divider />}
		</SectionTitleContainer>
	);
};

export { MultiButtonSectionTitle };
