import { useMutation, useQuery } from '@tanstack/react-query';

import { EventIdParamsType } from 'utils/types';
import { EventProps } from 'features/Home/components/Events/types';
import { FormValuesProps } from './types';
import { UseFormReturn } from 'react-hook-form';
import { createTicket } from './services';
import { getData } from 'services/api';
import { handleLink } from 'utils/handleLink';
import { sendGAEvent } from 'utils/utils';
import { uploadImage } from 'services/UploadImage/uploadImage';
import { useAppSelector } from 'store/hooks';
import { useParams } from 'react-router-dom';

export const useTicketsApi = (ticketFormMethods: UseFormReturn<FormValuesProps, any>) => {
	const { eventId }: EventIdParamsType = useParams();
	const { isPunchup } = useAppSelector((state) => state.auth);

	const { data: event } = useQuery({
		queryKey: ['event', eventId],
		queryFn: (): Promise<EventProps> => getData(`public/ticketing/events/${eventId}`),
	});

	const { mutateAsync: mutateUploadImage } = useMutation(uploadImage, {
		onSuccess: ({ data: { image_url } }) => {
			ticketFormMethods.setValue('template', image_url);
		},
	});

	const { mutate: mutateCreateTicket } = useMutation(createTicket, {
		onSuccess: () => {
			sendGAEvent({ category: 'Journeys', action: 'Create ticket' });
			window.parent.postMessage({ action: 'ticket-created', eventId }, '*');
			!isPunchup && handleLink('#8');
		},
	});

	return { event, mutateUploadImage, mutateCreateTicket };
};
