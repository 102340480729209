import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Event } from './types';
import { IdParamsType } from 'utils/types';
import { ROUTES } from 'features/routes';
import { getData } from 'services/api';
import { sendGAEvent } from 'utils/utils';
import { updateEvent } from './services';

export const useEditEventApi = () => {
	const { push } = useHistory();
	const { id }: IdParamsType = useParams();

	const { data: event } = useQuery({
		queryKey: ['event', id],
		queryFn: (): Promise<Event> => getData(`public/ticketing/events/${id}`),
		select: (eventData: Event) => {
			return {
				...eventData,
				custom_fees: eventData.custom_fees.map(({ taxable, ...rest }) => ({ ...rest, taxable: taxable ? '1' : '0' })),
			};
		},
	});

	const { mutate: editEvent } = useMutation(updateEvent, {
		onSuccess: () => {
			window.parent.postMessage({ action: 'event-updated', eventId: id }, '*');
			sendGAEvent({ category: 'Journeys', action: 'Edit event' });
			push(ROUTES.DASHBOARD_PATH.replace(':id', id));
		},
	});

	return { event, editEvent };
};
