import { Form } from 'features/common/components/Form';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Title = styled(Text)``;

export const FormContainer = styled(Form)`
	display: flex;
	gap: 24px;
	width: 100%;
	padding: 20px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;
	}
`;
