import { StyledMenuItem, Text } from './MenuItem.styles';

import { Icon } from 'features/common/components/Icon';
import { MenuItemProps } from '../../types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MenuItem: React.FC<MenuItemProps> = ({ isActive, icon, name }: MenuItemProps) => {
	const { t } = useTranslation();
	return (
		<StyledMenuItem isActive={isActive}>
			{icon && <Icon iconName={isActive ? `${icon}-active` : icon} />}
			<Text data-cy={name}>{t(`${name}`)}</Text>
		</StyledMenuItem>
	);
};

export { MenuItem };
