import { Container, Icon, Input } from './PunchupSearch.styles';

import { FC } from 'react';
import { SearchProps } from '../../../Home/components/Events/types';

const PunchupSearch: FC<SearchProps> = ({
	onChange,
	placeholder,
	width,
	fontSize,
	className,
	...rest
}: SearchProps) => (
	<Container width={width} className={className}>
		<Icon iconName="search" />
		<Input type="text" placeholder={placeholder} onChange={onChange} fontSize={fontSize} {...rest} />
	</Container>
);

export { PunchupSearch };
