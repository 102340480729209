import * as Styled from './CustomFieldsHeader.styles';

import { CustomFieldsHeaderProps } from './types';
import { MainHeader } from 'features/common/components/MainHeader';
import { useResponsiveScreen } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

export const CustomFieldsHeader = ({ title, editMode, onDelete }: CustomFieldsHeaderProps) => {
	const { t } = useTranslation();
	const isLargeScreen = useResponsiveScreen();

	return (
		<MainHeader lessPadding>
			<Styled.Wrapper>
				<Styled.InfoContainer>
					<Styled.CalendarIcon />

					<Styled.TextContainer>
						<Styled.Title>{title}</Styled.Title>
						<Styled.Subtitle> {t('custom-fields-subtitle', { mode: editMode ? 'Edit' : 'Add new' })}</Styled.Subtitle>
					</Styled.TextContainer>
				</Styled.InfoContainer>

				{editMode && (
					<>
						{isLargeScreen ? (
							<Styled.DeleteBtn text="Delete" onClick={onDelete} type="button" category="quaternary" />
						) : (
							<Styled.TrashIcon iconName="trash" onClick={onDelete} />
						)}
					</>
				)}
			</Styled.Wrapper>
		</MainHeader>
	);
};
