import * as Styled from './PunchupCancelInviteModal.styles';

import { PUNCHUP_QUATERNARY, PUNCHUP_TERTIARY } from 'features/common/components/Button/constants';

import { CancelInviteModalProps } from '../types';
import { FC } from 'react';
import { Modal } from 'features/common/components/Modal/Modal';
import { useTranslation } from 'react-i18next';

export const PunchupCancelInviteModal: FC<CancelInviteModalProps> = ({ modalInfo, showModal, closeModal }) => {
	const { t } = useTranslation();

	if (!showModal) return null;

	return (
		<Styled.CancelModal onClose={closeModal}>
			<Modal.Header>{modalInfo.modalTitle}</Modal.Header>
			<Styled.ModalBody>
				<Styled.ContainerBody>
					<Styled.Icon iconName="trashGrey" />
					<Styled.Text element="p">{modalInfo.modalMessage}</Styled.Text>
				</Styled.ContainerBody>
			</Styled.ModalBody>
			<Modal.ActionButtons>
				<Styled.ContainerActionButtons>
					<Styled.ModalButton
						category={PUNCHUP_QUATERNARY}
						onClick={closeModal}
						text={t('comped-ticket-cancel-invite-modal-dismiss-btn')}
						type="button"
					/>
					<Styled.ModalButton
						category={PUNCHUP_TERTIARY}
						onClick={modalInfo.onDelete}
						text={t('comped-ticket-cancel-invite-modal-cancel-btn')}
						type="button"
					/>
				</Styled.ContainerActionButtons>
			</Modal.ActionButtons>
		</Styled.CancelModal>
	);
};
