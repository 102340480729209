import { AxiosError, AxiosResponse } from 'axios';
import { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import { Container, HoverImage, Icon, Image, InputFile, Label, Option, Root, WrapperOptions } from './Logo.styles';
import { deletePartnerImage, handlePictureChange } from 'services/OrgImage';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Divider } from 'features/common/components/Divider';
import { Highlights } from 'hooks/useHighlights/types';
import { ImageType } from 'features/common/types';
import { RootState } from 'store';
import cameraLogo from 'assets/images/cameraLogo.svg';
import { queryClient } from 'index';
import { useClickAway } from 'features/common/hooks/useClickAway';
import { useToastNotification } from 'hooks/useToastNotification';
import { useTranslation } from 'react-i18next';

const Logo: FC = () => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const dispatch = useAppDispatch();
	const { showNotification, TYPES } = useToastNotification();
	const { loggedUser } = useAppSelector((state: RootState) => state.auth);
	const partnerImage = loggedUser?.currentPartner.image;

	useEffect(() => setCurrentImage(partnerImage), [loggedUser?.currentPartner]);

	const [currentImage, setCurrentImage] = useState<ImageType>(partnerImage);
	const [showHoverImage, setShowHoverImage] = useState<boolean>(false);
	const [showUploader, setShowUploader] = useState<boolean>(false);

	const onImageClick = () => setShowUploader((prevState) => !prevState);
	const handleOnMouseEnter = () => setShowHoverImage(true);
	const handleOnMouseLeave = () => setShowHoverImage(false);

	useClickAway(ref, () => setShowUploader(false));

	const handlePictureChangeResults = (results: AxiosResponse, error: AxiosError) => {
		if (error)
			showNotification({
				message: t('error-uploading-image'),
				type: TYPES.ERROR,
			});
		if (results) setShowUploader(false);
	};

	const handleInputFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
		dispatch(handlePictureChange(target, handlePictureChangeResults));
		target.value = '';
	};

	const onClickDelete = () => dispatch(deletePartnerImage(handlePictureChangeResults));

	const highlights: Highlights<string> | undefined = queryClient.getQueryData(['highlights']);
	const hasHighlights = highlights !== undefined && !!Object.values(highlights).reduce((t, n) => t + n);

	return (
		<Root>
			<Container
				ref={ref}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
				hasHighlights={hasHighlights}
			>
				<Image src={currentImage?.toString()} data-cy="partner-logo" />
				{showHoverImage && <HoverImage src={cameraLogo} onClick={onImageClick} />}
			</Container>
			{showUploader && (
				<WrapperOptions ref={ref}>
					<Option onClick={onClickDelete}>
						<Icon iconName="trash" />
						{t('companyLogo-delete')}
					</Option>
					<Divider />
					<Option>
						<Label htmlFor="input-file">
							<Icon iconName="image" />
							{t('companyLogo-replace')}
							<InputFile
								id="input-file"
								name="image"
								type="file"
								accept="image/jpeg, image/png, image/webp"
								onChange={handleInputFile}
							/>
						</Label>
					</Option>
				</WrapperOptions>
			)}
		</Root>
	);
};

const memorizedCompanyLogo: FC = memo(Logo);

export { memorizedCompanyLogo as Logo };
