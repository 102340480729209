import { Icon } from '../Icon';
import { StdText } from '../Text/Text.styles';
import styled from 'styled-components';

export const Container = styled.div<{ isCentered?: boolean; isPunchup?: boolean }>`
	background-color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.white : theme.colors.grey)};
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	padding: 32px;
	width: 100%;
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 24px;
	}
`;

export const CustomPlus = styled(Icon)`
	height: 34px;
	width: 34px;
`;

export const Text = styled(StdText)<{ isPunchup?: boolean }>`
	max-width: 320px;
	color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.black : theme.colors.white)};
`;
