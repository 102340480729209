import { BackgroundCardProps, DiscountColorProps, EVENT_KEY, NEW_KEY, TICKET_KEY, UNLIMITED_KEY } from './types';
import styled, { DefaultTheme } from 'styled-components';

import { Text } from 'features/common/components/Text';

export const getDiscountColor = (type: string, { colors }: DefaultTheme) => {
	switch (type) {
		case EVENT_KEY:
			return { background: colors.crimson, circle: colors.crimson2 };
		case NEW_KEY:
			return { background: colors.grey2, circle: colors.grey2 };
		case TICKET_KEY:
			return { background: colors.orange, circle: colors.orange3 };
		case UNLIMITED_KEY:
		default:
			return { background: colors.purple1, circle: colors.purple2 };
	}
};

export const CardContainer = styled.div<{ isPunchup?: boolean }>`
	width: 320px;
	height: 120px;
	display: flex;
	min-width: 320px;
	overflow: hidden;
	min-height: 120px;
	border-radius: 8px;
	position: relative;
	background-color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.punchupBackground : theme.colors.grey2)};

	* {
		cursor: pointer;
	}

	&:hover .hovered {
		background-color: ${({ theme, isPunchup }) =>
			isPunchup ? theme.colors.punchupBackgroundHover : theme.colors.blackGrey};
	}

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 444px;
		display: flex;
		min-width: 444px;
		box-sizing: border-box;
	}
`;

export const UpCircle = styled.div<BackgroundCardProps>`
	top: 0px;
	left: 88px;
	width: 24px;
	height: 12px;
	min-width: 24px;
	min-height: 12px;
	position: absolute;
	border-radius: 0px 0px 500px 500px;
	background-color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.white : theme.colors.blackGrey)};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
		&.new {
			left: 96px;
			height: 5px;
			min-height: 5px;
			max-height: 5px;
			width: 10px;
			max-width: 10px;
			min-width: 10px;
		}
	}
`;

export const DownCircle = styled.div<BackgroundCardProps>`
	left: 88px;
	bottom: 0px;
	width: 24px;
	height: 12px;
	min-width: 24px;
	min-height: 12px;
	position: absolute;
	border-radius: 500px 500px 0px 0px;
	background-color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.white : theme.colors.blackGrey)};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
		&.new {
			left: 96px;
			height: 5px;
			min-height: 5px;
			max-height: 5px;
			width: 10px;
			max-width: 10px;
			min-width: 10px;
		}
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

export const Discount = styled.div<DiscountColorProps>`
	width: 60px;
	height: 60px;
	display: flex;
	font-size: 20px;
	min-width: 60px;
	min-height: 60px;
	font-weight: 600;
	line-height: 30px;
	align-items: center;
	border-radius: 100px;
	justify-content: center;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme, type, isPunchup }) =>
		isPunchup ? theme.colors.punchupBackground : getDiscountColor(type, theme).circle};
	cursor: pointer;
`;

export const Name = styled(Text)`
	opacity: 0.7;
	font-size: 20px;
	font-weight: 500;
	line-height: 36px;
	color: ${({ theme }) => theme.colors.white};
	text-transform: none;
`;

export const CreatePromoCode = styled(Text)<{ isPunchup?: boolean }>`
	font-size: 22px;
	font-weight: 600;
	line-height: 36px;
	color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.black : theme.colors.white)};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	}
`;

export const Event = styled(Text)`
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme }) => theme.colors.white};
`;

export const Uses = styled(Event)`
	color: ${({ theme }) => theme.colors.orange};
`;

export const DiscountContainer = styled.div<DiscountColorProps & { isPunchup?: boolean }>`
	width: 100px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme, type, isPunchup }) =>
		isPunchup ? theme.colors.punchupBackground : getDiscountColor(type, theme).background};
	border-right: ${({ theme, type }) => (type === NEW_KEY ? `1.5px dashed ${theme.colors.white}` : 0)};
`;

export const Column = styled.div`
	flex: 1;
	width: 150px;
	display: flex;
	padding: 20px;
	flex-direction: column;
	justify-content: space-evenly;
	cursor: pointer;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 10px 10px 10px 20px;
	}
`;

export const ActiveContainer = styled.div`
	gap: 4px;
	height: 16px;
	display: flex;
	font-size: 12px;
	font-weight: 400;
	align-items: center;
	letter-spacing: 0.5px;
	font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Chip = styled.div<DiscountColorProps>`
	font-size: 11px;
	padding: 2px 6px;
	font-weight: 400;
	line-height: 16px;
	width: fit-content;
	border-radius: 2px;
	align-self: flex-end;
	letter-spacing: 0.5px;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.primary};
	background: ${({ theme, type }) => getDiscountColor(type, theme).background};
`;
