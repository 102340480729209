import { GraphDisplayInfo } from './types';
import { PunchupSetTimelineZoom } from './PunchupSetTimelineZoom';
import { PunchupShiftTimeline } from './PunhchupShiftTimeline';
import { TimelineContainer } from './PunchupAdjustTimeline.styles';

export const PunchupAdjustTimeline = ({ setDisplay, displayWindow }: GraphDisplayInfo) => {
	return (
		<TimelineContainer>
			<PunchupShiftTimeline setDisplay={setDisplay} displayWindow={displayWindow} />
			<PunchupSetTimelineZoom setDisplay={setDisplay} displayWindow={displayWindow} />
		</TimelineContainer>
	);
};
