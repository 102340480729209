import styled, { css } from 'styled-components';

import { DesktopContainerStylesProps } from './types';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';

export const Icon = styled(UnstyledIcon)`
	cursor: pointer;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 25px;
	top: 2px;
	left: 4px;
	cursor: pointer;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 14px;
		line-height: 20px;
	}
`;

export const DesktopContainer = styled.div<DesktopContainerStylesProps>`
	background-color: transparent;
	color: ${({ theme, secondaryStyle }) => (secondaryStyle ? theme.colors.punchupOrange : theme.colors.white)};
	height: 40px;
	width: ${({ width }) => width || '150px'};

	padding: ${({ secondaryStyle }) => `5px ${secondaryStyle ? '0' : '15'}px`};
	border: 0.5px solid ${({ theme, secondaryStyle }) => (secondaryStyle ? 'none' : theme.colors.white)};
	border-radius: 8px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-weight: 400;
	font-size: 16px;
	box-sizing: border-box;
	cursor: pointer;

	${({ isPunchup }) =>
		isPunchup &&
		css`
			position: relative;
			width: auto;
			padding: 0;
		`}
`;
