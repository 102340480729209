import styled from 'styled-components';

export const LoaderContainer = styled.div`
	align-items: center;
	backdrop-filter: blur(5px);
	display: flex;
	height: 100vh;
	justify-content: center;
	position: fixed;
	width: 100vw;
	z-index: 1000;
`;

export const StyledLoader = styled.div<{ isPunchup?: boolean }>`
	animation: spin 1s linear infinite;
	border: 10px solid ${({ isPunchup, theme }) => (isPunchup ? theme.colors.whitePearl : theme.colors.grey3)};
	border-top: 10px solid ${({ isPunchup, theme }) => (isPunchup ? theme.colors.punchupOrange : theme.colors.green)};
	border-radius: 50%;
	height: 80px;
	width: 80px;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
