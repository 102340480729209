import { EmptyHighlightsContainerProps } from '../types';
import { Button as UnstyledButton } from 'features/common/components/Button';
import { Text as UnstyledText } from 'features/common/components/Text';
import styled from 'styled-components';

export const Button = styled(UnstyledButton)`
	align-items: center;
	display: flex;
	font-size: 18px;
	font-weight: 600;
	justify-content: center;
	text-align: center;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 11px;
		line-height: 15px;
		height: 27px;
		padding: 5px 20px;
		width: 111px;
	}
`;

export const Image = styled.img`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		width: 97vw;
	}
`;

export const EmptyHighlightsContainer = styled.div<EmptyHighlightsContainerProps>`
	background-color: ${({ theme, hasLogo }) => (!hasLogo ? 'transparent' : theme.colors.grey)};
	display: flex;
	flex-direction: row;
	gap: 25px;
	height: 278px;
	border-radius: 8px;
	width: ${({ hasLogo }) => (hasLogo ? 'min-content' : '100%')};

	// animation
	animation: fadeIn 0.5s ease-in-out;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		background-color: transparent;
		flex-direction: column;
		gap: 0px;
		padding: 0px;
	}
`;

export const Title = styled(UnstyledText)`
	line-height: 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
	}
`;

export const SubTitle = styled(UnstyledText)`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 10px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
	}
`;
