import { Form } from 'features/common/components/Form';
import { SendTicketsSection } from 'features/SendTickets/SendTickets.styles';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Root = styled(SendTicketsSection)`
	gap: 16px;
	width: 100%;
`;

export const Subtitle = styled(Text)`
	font-size: 32px;
	color: ${({ theme }) => theme.colors.black};
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
	}
`;

export const Select = styled(Form.Select)`
	margin-bottom: 20px;
	width: 90% !important;
`;

export const AddRecipientBtn = styled.div`
	cursor: pointer;
	background-color: ${({ theme }) => theme.colors.whitePearl};
	border: 1px solid ${({ theme }) => theme.colors.punchupOrange};
	color: ${({ theme }) => theme.colors.punchupOrange};
	border-radius: 8px;
	padding: 8px 0px;
	font-family: ${({ theme }) => theme.fonts.primary};
	display: flex;
	align-items: center;
	transition: all 0.2s ease-in-out;

	&:hover {
		background-color: ${({ theme }) => theme.colors.punchupOrange};
		color: ${({ theme }) => theme.colors.whitePearl};
	}
`;
