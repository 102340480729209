import {
	AddTickets,
	Carousel,
	ConfirmUpload,
	SelectTemplate,
	TicketCreated,
	TicketDetails,
	TicketImageUpload,
	TicketTemplateImage,
	TicketTypeSelector,
} from '..';

import { Number1To9 } from 'features/CreateTicket/types';
import { Success } from '../Steps/Success';
import { handleGAStep } from 'features/CreateTicket/utils';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const StepCarousel = () => {
	const { hash } = useLocation();
	const { isPunchup } = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (hash) handleGAStep(Number(hash.charAt(1)) as Number1To9);
	}, [hash]);

	return (
		<Carousel>
			{hash === '#1' && isPunchup ? <TicketDetails /> : <AddTickets />}
			{hash === '#2' && <TicketDetails />}
			{hash === '#3' && <TicketTypeSelector />}
			{hash === '#4' && <TicketTemplateImage />}
			{hash === '#5' && <SelectTemplate />}
			{hash === '#6' && <TicketImageUpload />}
			{hash === '#7' && <ConfirmUpload />}
			{hash === '#8' && <TicketCreated />}
			{hash === '#9' && <Success />}
			{hash === '#19' && <Success />}
		</Carousel>
	);
};
