import { Divider } from 'features/common/components/Divider';
import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const Title = styled(Text)`
	line-height: 27px;
	margin-left: 3%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 18px;
	}
`;

export const Container = styled.div`
	display: flex;
	padding: 0 3% 24px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const EventsContainer = styled.div`
	min-height: 80vh;
	margin-top: 17px;
	display: grid;
	grid-gap: 37px;
	grid-template-columns: repeat(auto-fit, minmax(423px, 423px));
	justify-content: center;
	margin-bottom: 50px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const Wrapper = styled.div`
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		display: flex;
		padding: 0px 10px;
		flex-direction: column;
	}
`;

export const EventsDivider = styled(Divider)``;
