import styled from 'styled-components';

export const Container = styled.div`
	background-color: white;
	width: 100%;
	height: 100%;
`;

export const ContentWrapper = styled.div`
	padding: 0 6% 6% 6%;
	width: 100vw;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0;
	}
`;

export const Padding = styled.div`
	padding-left: 6%;
	padding-right: 3%;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 3%;
	}
`;

export const ContainerTabs = styled(Padding)`
	background: ${({ theme }) => theme.colors.whitePearl};
`;

export const AnalyticsContainer = styled(Padding)`
	background: #f5f5f5;
	margin-bottom: 32px;
`;

export const Root = styled(ContainerTabs)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 0px;
`;

export const ContainerTabsPieCharts = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 24px;
	padding: 24px;
	background-color: ${({ theme }) => theme.colors.whitePearl};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		grid-template-columns: 1fr;
	}
`;

export const Divider = styled('div')`
	border: 1px solid ${({ theme }) => theme.colors.lightGrey5};
	width: 100%;
	margin-top: 32px;
	margin-bottom: 22px;
`;

export const SummaryCardsContainer = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;
	justify-content: space-around;
	width: 100%;
	padding: 15px;
	padding-left: 85px;
	padding-right: 25px;
	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		justify-content: flex-start;
	}
`;

export const SmallTicketsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
	background-color: white;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		grid-template-columns: 1fr;
	}
`;

export const Title = styled.div`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
	padding: 24px;
	color: ${({ theme }) => theme.colors.black};

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 0 24px;
	}
`;
