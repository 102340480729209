import { FormProps, LoginErrors } from './types';
import { LOGIN_ERRORS, defaultErrors } from '../utils';
import { setEmail, setIsAlreadyCreated } from 'store/slices/LoginSlice';
import { useEffect, useState } from 'react';

import { ROUTES } from 'features/routes';
import { logIn } from 'services/auth';
import { useAppDispatch } from 'store/hooks';
import { useHistory } from 'react-router-dom';

export const useLogin = () => {
	const dispatch = useAppDispatch();
	const { push } = useHistory();
	const [errors, setErrors] = useState<LoginErrors>(defaultErrors);

	useEffect(() => {
		dispatch(setIsAlreadyCreated(false));
	}, []);

	const logInResults = (token?: string, error?: any, hasMultiplePartners?: boolean) => {
		if (token && !error) {
			setErrors(defaultErrors);
			hasMultiplePartners ? push(ROUTES.SWITCH_TEAM_PATH, { fromLogin: true }) : push(ROUTES.HOME_PATH);
		}
		if (error) {
			if (error.code === LOGIN_ERRORS.WRONG_PASSWORD) {
				setErrors({ wrongEmail: false, wrongPassword: true });
			} else if (error.code === LOGIN_ERRORS.EMAIL_NOT_FOUND) {
				setErrors({ wrongEmail: true, wrongPassword: false });
			} else if (error.code === LOGIN_ERRORS.SCANNER_ROLE) {
				push(ROUTES.SCANNER_ACCOUNT);
			} else if (error.request?.response?.includes('user not found')) {
				setErrors({ wrongEmail: false, wrongPassword: false });
				dispatch(setIsAlreadyCreated(true));
				push(ROUTES.CREATE_ACCOUNT_PATH);
			} else {
				setErrors({ wrongEmail: false, wrongPassword: false, unknownError: true });
				throw error;
			}
		}
	};

	const onSubmit = ({ email, password }: FormProps) => {
		dispatch(setEmail(email));
		dispatch(logIn(email, password, logInResults));
	};

	return { errors, onSubmit };
};
