import { BROWSER_TZ } from './constants';
import { common } from 'theme/common';
import { convertUTCToDateBarFormat } from 'utils/utils';

export const formatDate = (orderDate: string) => {
	const { date, time } = convertUTCToDateBarFormat(orderDate, BROWSER_TZ);
	return `${date} | ${time}`;
};

export function getStatusColor(status: string) {
	switch (status) {
		case 'Completed':
			return common.colors.green10;
		case 'Registered':
			return common.colors.green10;
		case 'Refunded':
			return common.colors.orange;
		case 'Voided':
			return common.colors.orange;
		case 'Rejected':
			return common.colors.red;
		case 'Pending':
			return common.colors.orange;
		default:
			return common.colors.white;
	}
}
