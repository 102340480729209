import styled, { css } from 'styled-components';

import { Button } from 'features/common/components/Button';
import { Form } from 'features/common/components/Form';

export const CustomFieldsForm = styled(Form)<{ isPunchup: boolean }>`
	display: flex;
	gap: 12px;
	width: 100%;

	${({ isPunchup }) =>
		!isPunchup &&
		css`
			height: calc(100vh - 168px - 100px);
		`}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		flex-direction: column;

		${({ isPunchup }) =>
			!isPunchup &&
			css`
				overflow: scroll;
				height: calc(100vh - 100px - 128px);
			`}
	}
`;

export const FormSide = styled.div`
	width: 50%;
	display: flex;
	height: auto;
	flex-direction: column;
	width: 90vw;
`;

export const ButtonsContainer = styled.div<{ isPunchup: boolean }>`
	display: flex;
	width: 100%;
	border-top: 0.5px solid ${({ theme }) => theme.colors.white2};
	justify-content: center;
	gap: 32px;
	align-items: center;
	padding-bottom: 10px;

	${({ isPunchup }) =>
		!isPunchup &&
		css`
			background: ${({ theme }) => theme.colors.blackGrey};
			position: absolute;
			height: 100px;
			bottom: 0;
		`}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 8px;
		bottom: 0;
		border: none;

		${({ isPunchup }) =>
			!isPunchup &&
			css`
				position: fixed;
			`}
	}
`;

export const StyledButton = styled(Button)`
	width: fit-content;
	min-width: 300px;
	position: relative;
	bottom: 0;
	margin: 0;
	height: 56px;
	font-size: 18px;
	padding: unset;

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		min-width: 150px;
	}
`;
