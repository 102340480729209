import { Button } from 'features/common/components/Button';
import { Icon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const Container = styled.div<{ noGap?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 24px;
	background-color: ${({ theme }) => theme.colors.whitePearl};
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	width: 200px;
	height: 200px;
	position: relative;
	gap: 8px;
`;

export const CustomIcon = styled(Icon)`
	&& {
		width: 32px;
		height: 32px;
		filter: brightness(0);
		opacity: 0.8;
	}
`;

export const Stat = styled.div`
	font-size: 32px;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.black};
	text-align: center;
`;

export const Label = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.black};
	text-align: center;
`;

export const CardButton = styled(Button)`
	&& {
		background: #29333c;
		background-image: none;
		color: white;
		font-size: 12px;
		padding: 8px 24px;
		border-radius: 20px;
		font-weight: normal;
		border: none;
		white-space: nowrap;
		box-shadow: none;
		margin: 0;

		&:hover {
			background: #fe5e25;
			background-image: none;
		}
	}
`;

export const ShowRateContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ShowRateLabel = styled.div`
	position: absolute;
	top: 24px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 14px;
	color: ${({ theme }) => theme.colors.black};
	white-space: nowrap;
`;
