import * as Styled from './AnalyticByEvent.styles';

import { useHistory, useParams } from 'react-router-dom';

import { AnalyticsTitle } from '../AnalyticsTitle/AnalyticsTitle';
import { EVENT_MODE_NORMAL } from 'utils/constants';
import { Event } from 'features/common/types';
import { FC } from 'react';
import { Icon } from 'features/common/components/Icon';
import { IdParamsType } from 'utils/types';
import { MainLayout } from 'layouts/MainLayout';
import NetSalesSection from './NetSalesSection/NetSalesSection';
import PunchupNetSalesSection from './PunchupNetSalesSection/PunchupNetSalesSection';
import PunchupSummaryCardSection from './PunchupSummaryCardSection/PunchupSummaryCardSection';
import PunchupTicketInventory from './PunchupTicketInventorySection/PunchupTicketInventory';
import PunchupTicketsSoldSection from './PunchupTicketsSoldSection/PunchupTicketsSoldSection';
import { RootState } from 'store';
import SummaryCardSection from './SummaryCardSection/SummaryCardSection';
import TicketInventory from './TicketInventorySection/TicketInventory';
import TicketsSoldSection from './TicketsSoldSection/TicketsSoldSection';
import { getData } from 'services/api';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

export const AnalyticByEvent: FC = () => {
	const { id } = useParams<IdParamsType>();
	const { goBack } = useHistory();
	const { isPunchup } = useSelector((state: RootState) => state.auth);

	const { data: event } = useQuery({
		queryKey: ['event', id],
		queryFn: (): Promise<Event> => getData(`public/ticketing/events/${id}`),
	});

	if (!event) return null;

	if (isPunchup) {
		return (
			<MainLayout>
				<Styled.BackButtonContainer onClick={goBack}>
					<Icon iconName="arrowLeft" color="brightness(0)" />
				</Styled.BackButtonContainer>

				<PunchupSummaryCardSection eventMode={event.mode} />

				<Styled.ContentWrapper>
					<PunchupNetSalesSection />
					<PunchupTicketsSoldSection />
					<PunchupTicketInventory />
				</Styled.ContentWrapper>
			</MainLayout>
		);
	}

	return (
		<MainLayout>
			<AnalyticsTitle subtitle={event.name} />
			<SummaryCardSection eventMode={event.mode} />
			<Styled.ContentWrapper>
				{event.mode === EVENT_MODE_NORMAL && <NetSalesSection />}
				<TicketsSoldSection />
				{event.mode === EVENT_MODE_NORMAL && <TicketInventory />}
			</Styled.ContentWrapper>
		</MainLayout>
	);
};
