import * as Styled from './PunchupCompedTicketsTable.styles';

import { COLUMN_HEADINGS, COLUMN_MIN_WIDTH, GRID_COLUMNS } from './constants';

import { CompedTicketsTableProps } from '../types';
import { FC } from 'react';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OUTLINED } from 'features/common/components/Button/constants';
import { PunchupCompedTicketsTableBody } from './PunchupCompedTicketsTableBody';
import { Table } from 'features/common/components/Table';
import { useTranslation } from 'react-i18next';

export const PunchupCompedTicketsTable: FC<CompedTicketsTableProps> = ({
	compedTicketsQueryObject,
	openCancelModal,
}) => {
	const { t } = useTranslation();
	const { fetchNextPage, hasNextPage } = compedTicketsQueryObject;

	return (
		<>
			<Styled.Root>
				<table>
					<thead>
						<Styled.Row gridColumns={GRID_COLUMNS}>
							{COLUMN_HEADINGS.map((heading, i) => (
								<Table.Cell key={heading} minWidth={COLUMN_MIN_WIDTH[i]} textAlign={i === 5 ? 'center' : 'left'}>
									<Styled.ColumnHeader>{heading}</Styled.ColumnHeader>
								</Table.Cell>
							))}
						</Styled.Row>
					</thead>

					<PunchupCompedTicketsTableBody
						compedTicketsQueryObject={compedTicketsQueryObject}
						openCancelModal={openCancelModal}
					/>
				</table>
			</Styled.Root>

			<FlexContainer justifyContent="center" margin="32px 0">
				<Styled.Button
					category={OUTLINED}
					text={t('button-load-more')}
					onClick={() => fetchNextPage()}
					disabled={!hasNextPage}
					type="button"
				/>
			</FlexContainer>
		</>
	);
};
