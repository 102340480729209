import { Row, SButton, SectionTitleContainer, Title } from './SectionTitle.styles';

import { Divider } from 'features/common/components/Divider';
import { FC } from 'react';
import { FlexContainer } from '../FlexContainer';
import { SectionTitleProps } from './types';
import { Toggle } from '../Toggle';
import { useAppSelector } from 'store/hooks';
import { useResponsiveScreen } from 'hooks/useWindowSize';

const SectionTitle: FC<SectionTitleProps> = ({
	title,
	buttonLabel,
	onClick,
	paddingVertical,
	toggleProps,
}: SectionTitleProps) => {
	const isLargeScreen = useResponsiveScreen();
	const { isPunchup } = useAppSelector((state) => state.auth);

	return (
		<SectionTitleContainer paddingVertical={paddingVertical}>
			<Row>
				<FlexContainer gap="16px" alignItems="center" marginRight="auto">
					<Title isPunchup={isPunchup}>{title}</Title>
					{toggleProps && <Toggle {...toggleProps} />}
				</FlexContainer>

				{buttonLabel && (
					<SButton
						key={buttonLabel}
						onClick={onClick}
						text={buttonLabel}
						category="outlined"
						data-cy={`${title}-button`}
						isPunchup={isPunchup}
					/>
				)}
			</Row>
			{isLargeScreen && <Divider />}
		</SectionTitleContainer>
	);
};

export { SectionTitle };
