import { Text } from 'features/common/components/Text';
import { Icon as UnstyledIcon } from 'features/common/components/Icon';
import styled from 'styled-components';

export const CardContainer = styled.div`
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.whitePearl};
	border: 1px solid ${({ theme }) => theme.colors.punchupOrange};

	height: 100%;
	padding: 10px 24px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: 16px 18px;
	}
`;

export const WrapperInputs = styled.div`
	display: flex;
	gap: 12px;
	margin-bottom: 10px;
`;

export const RecipientName = styled(Text)`
	font-weight: 500;
	font-size: 16px;
	line-height: 46px;
	margin: 0;
	color: ${({ theme }) => theme.colors.black};
`;

export const Icon = styled(UnstyledIcon)`
	cursor: pointer;
`;
