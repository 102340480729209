import * as Styled from './LeftForm.styles';

import { allowsDecimals, allowsOnlyNumbers } from 'utils/numberInput';

import { Form } from 'features/common/components/Form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// TODO: Uncomment when backend is ready
// import { Text } from 'features/common/components/Text';
// import { Toggle } from 'features/common/components/Toggle';
// import { ToolTip } from 'features/common/components/InfoTooltip';
// import { Icon } from 'features/common/components/Icon';

const LeftForm: React.FC = () => {
	const { t } = useTranslation();
	const {
		// TODO: Uncomment when backend is ready
		// setValue,
		watch,
	} = useFormContext();
	const sellingTime: string = watch('sellingTime');
	const offSellingTime: string | null = watch('offSellingTime');
	const price: number = watch('initialPrice');
	// TODO: Uncomment when backend is ready
	// const allowSecondarySales = watch('allowSecondarySales');
	// const onChangeSecondarySales = () => setValue('allowSecondarySales', !allowSecondarySales);

	return (
		<>
			<Form.Input id="name" name="name" label={t('ticketDetails-ticket-type-name')} />
			<Styled.WrapperInputs>
				<Form.Input
					type="number"
					id="initialPrice"
					name="initialPrice"
					label={t('ticketDetails-ticket-price')}
					onKeyDown={(e) => allowsDecimals(e, price)}
					step=".01"
				/>
				<Form.Input
					type="number"
					id="amount"
					name="amount"
					label={t('ticketDetails-quantity')}
					onKeyDown={allowsOnlyNumbers}
				/>
			</Styled.WrapperInputs>
			<Form.InputText
				id="description"
				name="description"
				label={t('ticketDetails-ticket-description')}
				required={false}
			/>
			{/* TODO: Uncomment when backend is ready */}
			{/*
			<Styled.ToggleContainer>
				<Text>{t('ticketDetails-secondary-sales')}</Text>
				<ToolTip
					title={t('ticketDetails-secondary-sales-tooltip-title')}
					text={t('ticketDetails-secondary-sales-tooltip')}
				>
					<Icon iconName="help" />
				</ToolTip>
				<Toggle
					isChecked={allowSecondarySales}
					name="allowSecondarySales"
					id="allowSecondarySales"
					handleChange={onChangeSecondarySales}
				/>
			</Styled.ToggleContainer>
			<Form.Input
				type="number"
				inputMode="numeric"
				id="secondaryRoyalty"
				name="secondaryRoyalty"
				label={t('ticketDetails-ticket-price-fee')}
				info={t('ticketDetails-ticket-price-info')}
				max="100"
				onKeyDown={(e) => allowsOnlyNumbers(e)}
			/> */}

			<Form.InputDateAndTime
				id="sellingTime"
				name="sellingTime"
				label={t('createEvent-tickets-onSale')}
				value={new Date(sellingTime)}
			/>
			<Form.InputDateAndTime
				id="offSellingTime"
				name="offSellingTime"
				label={t('createEvent-tickets-offSale')}
				value={offSellingTime ? new Date(offSellingTime) : null}
				required={false}
			/>
			<Form.InputToggle
				htmlFor="ticketVisibility"
				name="ticketVisibility"
				label={t('ticketDetails-ticket-visbility')}
			/>
			<Form.InputToggle htmlFor="isLottery" name="isLottery" label={t('createEvent-lottery-mode')} />
		</>
	);
};

export { LeftForm };
