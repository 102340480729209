import { Button as UnstyledButton } from 'features/common/components/Button';
import styled from 'styled-components';

export const FooterContainer = styled.div<{ isPunchup: boolean }>`
	left: 0;
	right: 0;
	bottom: 0;
	gap: 20px;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap-reverse;
	background-color: ${({ theme, isPunchup }) => (isPunchup ? theme.colors.whitePearl : theme.colors.grey4)};
	border-top: ${({ theme }) => `0.5px solid ${theme.colors.white2}`};
	padding: 32px 24px;
	z-index: 2;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		gap: 10px;
		padding: 24px;
	}
`;

export const Button = styled(UnstyledButton)`
	margin: 0;
	display: flex;
	height: 45px;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 16px;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	max-width: 225px;
`;
