import { Text } from 'features/common/components/Text';
import styled from 'styled-components';

export const SwitchTeamRoot = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	padding: 0 15px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		margin-bottom: 100px;
	}
`;

export const SearchLabel = styled(Text)`
	font-size: 24px;
	text-transform: none;
	margin-bottom: 21px;
	font-weight: 600px;

	@media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
		font-size: 20px;
	}
`;

export const OrganizationsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(100%, max(180px, 100%/7)), 1fr));
	width: 100%;
	margin-top: 40px;
	row-gap: 24px;
`;
