import { COLUMN_HEADINGS, COLUMN_MIN_WIDTH } from './constants';

import { ConfirmIdModal1 } from './components/ConfirmIdModal1';
import { ConfirmIdModal2 } from './components/ConfirmIdModal2';
import { FC } from 'react';
import { FlexContainer } from 'features/common/components/FlexContainer';
import { OptionsModal } from './components/OptionsModal';
import { ResetModal } from './components/ResetModal';
import { Table } from 'features/common/components/Table';
import { TableBody } from './components/TableBody';
import { TableProps } from './types';
import { useTranslation } from 'react-i18next';

export const FansTable: FC<TableProps> = ({ fanQueryObject }) => {
	const { t } = useTranslation();
	const { fetchNextPage, hasNextPage } = fanQueryObject;
	return (
		<>
			<Table.TableContainer>
				<Table>
					<thead>
						<Table.HeaderRow gridColumns="repeat(3, 1fr)">
							{COLUMN_HEADINGS.map((heading, i) => {
								return (
									<Table.Cell
										key={heading}
										minWidth={COLUMN_MIN_WIDTH[i]}
										className={i === COLUMN_HEADINGS.length - 1 ? 'desktopOnly' : ''}
									>
										<Table.ColumnHeader>{t(heading)}</Table.ColumnHeader>
									</Table.Cell>
								);
							})}
						</Table.HeaderRow>
					</thead>
					<TableBody fanQueryObject={fanQueryObject} />
				</Table>

				<FlexContainer justifyContent="center">
					<Table.LoadMore handleClick={() => fetchNextPage()} isDisabled={!hasNextPage} />
				</FlexContainer>
			</Table.TableContainer>

			<OptionsModal />
			<ConfirmIdModal1 />
			<ConfirmIdModal2 />
			<ResetModal />
		</>
	);
};
