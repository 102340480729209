import * as Styled from './PunchupModal.styles';

import { FC, ReactNode } from 'react';

interface ModalProps {
	children: ReactNode;
	title: string;
}

export const PunchupModal: FC<ModalProps> = ({ children, title }) => {
	return (
		<Styled.Overlay>
			<Styled.ModalContainer>
				<Styled.CheckIcon>
					<Styled.StyledCheck />
				</Styled.CheckIcon>
				<Styled.Title>{title}</Styled.Title>
				{children}
			</Styled.ModalContainer>
		</Styled.Overlay>
	);
};
