import * as Styled from './PunchupRecipientInfoForm.styles';

import { FlexContainer } from 'features/common/components/FlexContainer';
import { PunchupInput } from 'features/common/components/Form/PunchupInput';
import { PunchupQuantityCard } from '../PunchupQuantityCard/PunchupQuantityCard';
import { RecipientInfoFormProps } from './types';

export const PunchupRecipientInfoForm = ({ id, ticketTypes, onRemove }: RecipientInfoFormProps) => {
	const currentRecipient = `recipients[${id}]`;
	const lastnameId = `${currentRecipient}.lastName`;
	const firstnameId = `${currentRecipient}.firstName`;
	const emailId = `${currentRecipient}.email`;

	return (
		<Styled.CardContainer>
			<FlexContainer justifyContent="space-between" alignItems="center">
				<Styled.RecipientName>Recipient {id + 1}</Styled.RecipientName>
				<Styled.Icon iconName="close" onClick={() => onRemove(id)} />
			</FlexContainer>

			<Styled.WrapperInputs>
				<PunchupInput label="First Name" name={firstnameId} id={firstnameId} />
				<PunchupInput label="Last Name" name={lastnameId} id={lastnameId} />
			</Styled.WrapperInputs>

			<PunchupInput label="Attendee Email" name={emailId} id={emailId} />

			<PunchupQuantityCard recipientId={id} ticketTypes={ticketTypes} />
		</Styled.CardContainer>
	);
};
