import { Error, PunchupInputContainer, PunchupLabel, PunchupSelect } from '../Form.styles';

import { FormSelectProps } from '../types';
import { useFormContext } from 'react-hook-form';

const PunchupFormSelect = ({ id, name, options, label, withDefaultValue, handleClick, ...rest }: FormSelectProps) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const error = errors[name];

	return (
		<PunchupInputContainer hasError={Boolean(error)} onClick={(e) => handleClick && handleClick(e)}>
			<PunchupLabel htmlFor={id ?? name}>{label}</PunchupLabel>
			<PunchupSelect {...rest} id={id ?? name} aria-label={name} {...register(name)}>
				{withDefaultValue && <option value="">Select One...</option>}
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</PunchupSelect>
			<Error>{error?.message}</Error>
		</PunchupInputContainer>
	);
};

export { PunchupFormSelect };
