import * as Styled from './PunchupButton.styles';

import { ButtonHTMLAttributes, FC } from 'react';

interface PunchupButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary';
	text: string;
}

export const PunchupButton: FC<PunchupButtonProps> = ({ variant = 'primary', text, ...props }) => {
	return (
		<Styled.Button variant={variant} {...props}>
			{text}
		</Styled.Button>
	);
};
